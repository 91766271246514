import React, { useMemo } from "react";
import LoadingIndicator, { ErrorIndicator } from "../indexer/analytics/LoadingIndicator";
import { ceil, isEmpty, max } from "lodash";
import { QueryResponse } from "../indexer/dataExplorer/DataExplorerClient";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

interface LineGraphProps {
  isLoading: boolean;
  hasError: boolean;
  data: QueryResponse;
  xAxisLabelFormatter?: (value: any, format: any) => any;
}

const labelColor = "rgba(255,255,255,0.8)";

const LineGraph = ({ data, isLoading, hasError, xAxisLabelFormatter }: LineGraphProps) => {
  return useMemo(() => {
    if (isLoading) return <LoadingIndicator />;
    if (hasError || isEmpty(data)) return <ErrorIndicator />;

    const xAxisData = data.data.rows || [];
    const series = (data.data.cols.slice(1) || []).map((value, idx) => {
      return {
        color: "#4e1dff",
        showInLegend: true,
        name: value.display_name,
        data: xAxisData.map((rowValue) => {
          return rowValue[idx + 1];
        }),
      };
    });
    const options = {
      chart: {
        type: "spline",
        backgroundColor: "rgba(27, 32, 35, 0.9)",
        height: 300,
      },
      title: {
        text: "",
      },
      yAxis: {
        gridLineColor: "rgba(255, 255, 255, 0.03)",
        labels: {
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 0,
        },
        title: null,
      },
      xAxis: {
        lineColor: "rgba(255, 255, 255, 0.06)",
        categories: xAxisData.map((value) => {
          const x = value[0];
          const colType = data.data.cols[0];
          return !!xAxisLabelFormatter
            ? colType.base_type.includes("DateTime")
              ? xAxisLabelFormatter(x, "dd-MM-yyyy")
              : x
            : x;
        }),
        accessibility: {
          description: "",
        },
        labels: {
          step: max([ceil(xAxisData.length / 10), 1]),
          style: {
            color: labelColor,
            fontSize: 14,
          },
          x: 30,
        },
      },
      series: series,
    };

    console.log("HighchartsReact: ", options);
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }, [data, isLoading, hasError]);
};

export default LineGraph;
