import React, { useState } from "react";
import { Collapse, InputBase, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Background from "../../assets/images/background.png";
import expand from "../../assets/icons/expand.svg";
import QAndA from "./Q&A.json";

function filterFAQ(faq: any, searchText: string) {
  const filteredFaq: any[] = [];
  const searchTextArray = searchText.split(" ");
  for (let i = 0; i < faq.length; i++) {
    let match = false;
    for (let j = 0; j < searchTextArray.length; j++) {
      const re = new RegExp(searchTextArray[j].toLowerCase());
      const matches = faq[i].question.toLowerCase().match(re);
      if (matches?.length > 0) {
        match = true;
      }
    }
    if (match) {
      filteredFaq.push(faq[i]);
    }
  }
  return filteredFaq;
}

function Faq() {
  const [expandedQuestion, updateExpandedQuestion] = useState<number>(-1);
  const [searchText, updateSearchText] = useState("");
  const [filteredFaq, updateFilteredFaq] = useState(QAndA);

  function handleExpand(index: number) {
    if (expandedQuestion != -1) {
      document.getElementById(expandedQuestion.toString())!.style.transform = "rotate(0deg)";
    }
    if (expandedQuestion != index) {
      document.getElementById(index.toString())!.style.transform = "rotate(45deg)";
    }
    updateExpandedQuestion(index === expandedQuestion ? -1 : index);
  }

  function handleSearchTextChange(e: any) {
    updateSearchText(e?.target?.value);
    if (e?.target?.value === "") {
      updateFilteredFaq(QAndA);
    }
  }

  function handleSubmit() {
    updateFilteredFaq(filterFAQ(QAndA, searchText));
  }

  return (
    <main>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          width: "100%",
        }}
      >
        <br />
        <Typography variant="h3" fontWeight={600} display="flex" justifyContent="center">
          Hi, how can we help?
        </Typography>
        <br />
        <Stack
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "468px",
            height: "56px",
            borderRadius: "8px",
          }}
        >
          <InputBase
            sx={{ ml: 2, flex: 1, width: "85%" }}
            onChange={handleSearchTextChange}
            value={searchText}
            placeholder="Please enter your question to find answers"
          />
          <Button variant="contained" sx={{ mr: 1 }} onClick={handleSubmit}>
            <Typography fontWeight={600}>Search</Typography>
          </Button>
        </Stack>
        <br />
        <Typography variant="body1" fontWeight="400">
          You can also browse from FAQs listed below.
        </Typography>
        <br />
        <br />
      </Stack>
      <br />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Typography variant="h4" fontWeight={600} sx={{ mb: 4 }}>
          Frequently asked questions
        </Typography>
        {filteredFaq.map((question, index) => (
          <Card
            sx={{
              width: 640,
              mb: 1,
              background: "rgba(255, 255, 255, 0.06)",
              backdropFilter: "blur(110px)",
              border: "1px solid rgba(255, 255, 255, 0.22)",
              borderRadius: "12px",
            }}
            key={index}
          >
            <CardContent sx={{ p: 2, ":last-child": { pb: 2 } }}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography>{question.question}</Typography>
                <img
                  src={expand}
                  alt="more"
                  onClick={() => handleExpand(index)}
                  aria-expanded={expandedQuestion === index}
                  id={index.toString()}
                  style={{ cursor: "pointer" }}
                />
              </Stack>
              <Collapse in={expandedQuestion === index} timeout="auto" unmountOnExit>
                <CardContent
                  sx={{
                    p: 2,
                    ":last-child": { pb: 0, pl: 0 },
                    color: "rgba(255, 255, 255, 0.6)",
                  }}
                >
                  {question.answer.map((ans) => (
                    <Typography key={ans}>{ans}</Typography>
                  ))}
                </CardContent>
              </Collapse>
            </CardContent>
          </Card>
        ))}
      </Stack>
    </main>
  );
}

export default Faq;
