import { useAuth } from "@aws-amplify/ui-react/internal";
import { Auth } from "aws-amplify";
import plans from "../payments/Plans";
import { toast } from "react-toastify";
import { atom, useRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import { UserDetails } from "./types";
import useGetRequest from "../common/rest-client/get";
import { toLower } from "lodash";
import { doNothing } from "../common/types";
import { clearLocalStorage } from "../common/clearLocalStorage";
import { VideoCallCampaign } from "../../config";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";

export const userDetailsState = atom<APIData<UserDetails>>({
  key: "USER_DETAILS",
  default: DefaultAPIDataValues,
});

const useUser = () => {
  const [userDetails, updateUserDetails] = useRecoilState(userDetailsState);
  const get = useGetRequest();
  const auth = useAuth();
  const userName = auth.user?.username || "";
  const userPlan = toLower(userDetails.data?.current_plan?.name) || "";
  const apiKey = userDetails.data?.api_key || "";
  const userAttributes = auth.user?.attributes || {};
  const pendingCampaigns = userAttributes["custom:pendingCampaigns"];
  const email = userAttributes["email"];
  const role = userAttributes["custom:role"];
  const useCase = userAttributes["custom:useCase"];
  const isVideoCallCampaignIsComplete = `${pendingCampaigns}` !== VideoCallCampaign;
  const isOnFreePlan = userPlan === "free";
  const isLoggedIn = !!auth.user;
  const isAuthLoading = !!auth.isLoading;

  const getUserDetails = () => get<UserDetails>(updateUserDetails, `/users-service/user`);

  const getUserPlan = () => plans.get(userPlan?.toUpperCase() as string);

  const refreshUserSession = (onComplete: () => void = doNothing) => {
    console.log("Refreshing Token");
    if (!auth.isLoading)
      auth.user?.refreshSession(
        auth.user?.getSignInUserSession()?.getRefreshToken() as any,
        (err, res) => {
          auth.user?.setSignInUserSession(res);
          onComplete();
          console.log("Refreshed Token");
        }
      );
  };

  const removePendingCampaigns = (onComplete: () => void = doNothing) => {
    if (!auth.isLoading) {
      const cognitoUserAttributes = [
        new CognitoUserAttribute({
          Name: "custom:pendingCampaigns",
          Value: "",
        }),
      ];
      auth.user?.updateAttributes(cognitoUserAttributes, (err, data) => {
        if (err) {
          toast.error("Failed to update user attributes");
        }
        onComplete();
      });
    }
  };

  const logout = () => {
    Auth.signOut()
      .then(() => {
        toast.info("Successfully logged out!");
        clearLocalStorage();
      })
      .catch(() => toast.info("Failed to logout!"));
  };

  return {
    refreshUserSession,
    getUserPlan,
    isOnFreePlan,
    apiKey,
    logout,
    getUserDetails,
    userDetails,
    userName,
    isLoggedIn,
    isVideoCallCampaignIsComplete,
    useCase,
    role,
    email,
    removePendingCampaigns,
    isAuthLoading,
  };
};

export default useUser;
