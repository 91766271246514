import React, { useEffect } from "react";
import { CardMedia, Container, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { Link, useNavigate } from "react-router-dom";
import { toSakhiDashboardOverview, toSakhiQuestions } from "../common/Routes";
import useDashboardDetails, { Dashboard } from "./SakhiDashboardClient";
import notFoundImg from "../../assets/images/NotFound.png";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import Spacing from "../common/Spacing";
import { formatDateToString } from "../common/date";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { wrapMiddle } from "../common/string/format";
import dashboardImage from "../../assets/images/sakhi/dashboard.svg";
import { capitalize } from "lodash";

const DashboardListPreview = ({ dashboard }: { dashboard: Dashboard | undefined }) => {
  const theme = useTheme();
  const media = useMediaQuery(theme.breakpoints.down("md"));
  if (dashboard !== undefined)
    return (
      <Card
        key={dashboard?.dashboard_id}
        sx={{
          borderRadius: "8px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.07)",
          "& :hover": {
            cursor: "pointer",
          },
        }}
        onClick={() => {
          Mixpanel.track(MixpanelEvents.SakhiDashboardOverview, {
            object: "Sakhi",
            dashboard: dashboard?.name,
          });
        }}
      >
        <Link
          to={toSakhiDashboardOverview(dashboard.dashboard_id)}
          style={{
            width: "100%",
            textDecoration: "none",
            color: "white",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              textDecoration: "none",
              color: "white",
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-evenly",
              position: "static",
            }}
          >
            <CardMedia
              component="img"
              sx={{ padding: 4, backgroundColor: "#FBEFD5" }}
              image={dashboardImage}
            />
            <CardContent>
              <Tooltip title={dashboard.name} placement={"top"}>
                <Typography variant="h6" component="div">
                  {media
                    ? wrapMiddle(capitalize(dashboard.name), 9, 0)
                    : wrapMiddle(capitalize(dashboard.name), 14, 0)}
                </Typography>
              </Tooltip>
              <Typography variant="body2" align={"center"}>
                {formatDateToString(dashboard?.created_at)}
              </Typography>
            </CardContent>
          </div>
        </Link>
      </Card>
    );
  return <></>;
};

function Dashboards() {
  const { dashboardsList, getAllDashboardsByUserId, reset } = useDashboardDetails();
  const navigate = useNavigate();

  useEffect(() => {
    getAllDashboardsByUserId();
    const interval = setInterval(() => {
      getAllDashboardsByUserId();
    }, 10000);
    return () => {
      reset();
      clearInterval(interval);
    };
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth={"xl"}>
        <Stack
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          maxWidth={"lg"}
          sx={{ mx: "auto" }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"column"} justifyContent={"flex-start"} sx={{ my: 2 }}>
              <Typography variant={"h3"} fontWeight={600}>
                Dashboards
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  height: "50px",
                  borderRadius: "4px",
                  "&:hover": { transform: "scale(1.1)" },
                }}
                onClick={() => {
                  Mixpanel.track(MixpanelEvents.SakhiQuestions, { source: "Header" });
                  navigate(toSakhiQuestions());
                }}
              >
                <Typography variant={"h6"} fontWeight={600}>
                  New Dashboard
                </Typography>
              </Button>
            </Stack>
          </Stack>
          <Spacing spacing={3} />
          {dashboardsList?.error || dashboardsList?.data?.length === 0 ? (
            <>
              <img src={notFoundImg} alt={"Not Found"} height={400} />
              <Typography alignItems={"center"} variant={"h6"} fontWeight={600}>
                You haven’t created any Dashboards yet :(
              </Typography>
            </>
          ) : (
            <APIDataLoader
              hasError={dashboardsList.hasError}
              isLoading={dashboardsList.isLoading && !dashboardsList.hasError}
              isSuccess={dashboardsList.isSuccess}
            >
              <Box
                sx={{
                  mt: { md: 3, lg: 5 },
                  width: "100%",
                  typography: "body1",
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "repeat(auto-fill, 120px)",
                    md: "repeat(auto-fill, 270px)",
                  },
                  gap: { xs: "16px", md: "30px" },
                  flexWrap: "wrap",
                  flexGrow: 0,
                  justifyContent: "center",
                }}
              >
                {dashboardsList?.data?.map((dashboard) => {
                  return (
                    <>
                      <DashboardListPreview dashboard={dashboard} />
                    </>
                  );
                })}
              </Box>
            </APIDataLoader>
          )}
        </Stack>
      </Container>
    </React.Fragment>
  );
}

export default Dashboards;
