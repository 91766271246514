import React, { useEffect } from "react";
import { useTableNames } from "./DataExplorerClient";
import { Link, useNavigate, useParams } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { toParserTableDataTypes } from "../../common/Routes";
import ListItemIcon from "@mui/material/ListItemIcon";
import TableViewIcon from "@mui/icons-material/TableView";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import Copy from "../../common/clipboard/copy";
import { first } from "lodash";

const LoadingIndicator = () => (
  <>
    {Array.from(Array(5).keys()).map((key) => (
      <ListItemButton key={key}>
        <ListItemIcon>
          <TableViewIcon />
        </ListItemIcon>
        <ListItemText primary={<Skeleton animation="wave" height={30} />} />
      </ListItemButton>
    ))}
  </>
);

const TableNames = () => {
  const { tableNames, getTableNames } = useTableNames();
  const { id, tableName: selectedTableName } = useParams();
  const navigate = useNavigate();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useIndexerDetailsByID();

  useEffect(() => {
    getTableNames((data: Array<string>) => {
      const tableName = first(data);
      if (tableName) {
        navigate(toParserTableDataTypes(indexerID, tableName));
      }
    });
    getIndexerById(indexerID);
  }, [indexerID]);

  useEffect(() => {
    const tableName = first(tableNames.data);
    if (tableName && !selectedTableName) {
      navigate(toParserTableDataTypes(indexerID, tableName));
    }
  }, [tableNames.data, selectedTableName]);

  return (
    <>
      <Box sx={{ paddingLeft: 1, background: "rgb(45,54,58)" }}>
        <Typography fontWeight={600} sx={{ mb: 1 }}>
          Schema &nbsp;
          <Copy
            content={indexerDetails?.data?.additional_details?.db_name}
            contentType={"schema name"}
            source={"data analysis"}
          >
            <span style={{ fontWeight: 600, color: "#197273" }}>
              {indexerDetails?.data?.additional_details?.db_name}
            </span>
          </Copy>
        </Typography>
        <Divider />
        <Typography color={"primary"} variant={"h6"} fontWeight={"bold"}>
          Tables
        </Typography>
      </Box>
      <Divider />
      <APIDataLoader {...tableNames} loadingIndicator={<LoadingIndicator />}>
        <List component="nav">
          {(tableNames.data || []).map((tableName) => (
            <>
              <Link to={toParserTableDataTypes(indexerID, tableName)}>
                <ListItemButton key={tableName} selected={tableName === selectedTableName}>
                  <ListItemIcon>
                    <TableViewIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Copy content={tableName} contentType={"table name"} source={"data analysis"}>
                        {tableName}
                      </Copy>
                    }
                  />
                </ListItemButton>
              </Link>
              <Divider />
            </>
          ))}
        </List>
      </APIDataLoader>
    </>
  );
};

export default TableNames;
