import { Contract, ethers } from "ethers";
import ERC20ABI from "./ERC20.abi.json";
import { useWalletConnect } from "../../Wallet/Connections";

const useERC20Contract = () => {
  const { getSigner, getProvider, userAddress } = useWalletConnect();
  const transfer = (to: string, amount: number, token: string, tokenDecimals: number) => {
    const bigNumber = ethers.utils.parseUnits(amount.toString(), tokenDecimals);

    const contract = new Contract(token, ERC20ABI, getSigner());

    return contract.transfer(to, bigNumber);
  };

  const getBalance = (token: string) => {
    const contract = new Contract(token, ERC20ABI, getProvider());

    return contract.balanceOf(userAddress);
  };
  return { transfer, getBalance };
};

export default useERC20Contract;
