export const signIn = () => "/auth/sign-in";

export const myPlan = () => "/plan";
export const upgradePlan = (plan: number, tenureId: number) =>
  `/plans/${plan}/tenures/${tenureId}/upgrade`;

// Parser
export const toIndexer = (status = "Running") => `/parser?status=${status}`;
export const toNewIndexer = () => `/parser/new`;

export const toDailyUsage = (task: string) => `/statistics/${task}`;
export const toUsageStatistics = () => "/statistics";
export const toParserOverview = (id: string) => `/parser/${id}/details`;
export const toContractTransactions = (id: string) => `/parser/${id}/transactions`;
export const toBlockChainConfig = (id: string) => `/parser/${id}/block-chain-config`;
export const toMyNodes = (id: string) => `/parser/${id}/my-nodes`;
export const toMyWallets = (id: string) => `/parser/${id}/my-wallets`;
export const toNodeLogs = (id: string, nodeId: string) => `/parser/${id}/my-nodes/${nodeId}/logs`;
export const toFaucet = (id: string) => `/parser/${id}/faucet`;
export const toParserDataExplorer = (id: string) => `/parser/${id}/data-explorer`;
export const toParserDynamicAPI = (id: string) => `/parser/${id}/execute`;
export const toParserCredentials = (id: string) => `/parser/${id}/credentials`;
export const toParserStatistics = (id: string) => `/parser/${id}/statistics`;
export const toCustomDashboards = (id: string) => `/parser/${id}/custom-dashboards`;
export const toParserEventOrFunctionDashboard = (id: string) =>
  `/parser/${id}/statistics/analytics`;
export const toParserGraphQLPlayground = (id: string) => `/parser/${id}/graphql`;
export const toParserNotifications = (id: string) => `/parser/${id}/notifications`;
export const toParserMonitor = (id: string) => `/parser/${id}/monitor`;

export const toParserTableDataTypes = (id: string, tableName: string) =>
  `/parser/${id}/data-explorer/table/${tableName}/data-types`;
export const toParserDataExplorerExecuteQuery = (id: string) =>
  `/parser/${id}/data-explorer/execute`;

// Sakhi
export const toSakhiQuestions = (questionID: string = "default") => `/parser/${questionID}/sakhi`;
export const toSakhiDashboards = () => "/parser/dashboards";
export const toSakhiDashboardOverview = (dashboardID: number) =>
  `/parser/dashboards/${dashboardID}/overview`;

export const toTopAPI = () => `/top-api`;
export const toSDK = (language: string) => `/sdk/${language}`;

export const toNewPricingPage = () => `/new-pricing`;
