import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Button from "@mui/material/Button";
import { Stack, MenuItem } from "@mui/material";
import Spacing from "../../common/Spacing";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useGetRequest from "../../common/rest-client/get";
import { toast } from "react-toastify";
import useRestClient from "../../common/rest-client/RestClient";
import { AxiosResponse } from "axios";

export interface Root {
  costest1efd63aw40lxf3n4mhf7dzhjkr453axur4n75a6: Costest1efd63aw40lxf3n4mhf7dzhjkr453axur4n75a6;
  costest1hj5fveer5cjtn4wd6wstzugjfdxzl0xpf8ds5n: Costest1hj5fveer5cjtn4wd6wstzugjfdxzl0xpf8ds5n;
}

export interface Costest1efd63aw40lxf3n4mhf7dzhjkr453axur4n75a6 {
  balances: Balance[];
  pagination: Pagination;
}

export interface Balance {
  amount: string;
  denom: string;
}

export interface Pagination {
  next_key: any;
  total: string;
}

export interface Costest1hj5fveer5cjtn4wd6wstzugjfdxzl0xpf8ds5n {
  balances: Balance2[];
  pagination: Pagination2;
}

export interface Balance2 {
  amount: string;
  denom: string;
}

export interface Pagination2 {
  next_key: any;
  total: string;
}

const stateAtom = atom<APIData<Root>>({
  key: "my-wallets",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string) => {
    indexerFetcher<Root>(updatePageData, `/cosmos/network/${id}/selfAddressBalances`);
  };

  return { pageData, getPageData, reset };
};



const MyWallets = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useCosmosNetworkByID();
  const { pageData, getPageData, reset } = usePageData();
  const [open, setOpen] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [createAccountResponse, setCreateAccountResponse] = useState('');
  const { post, get } = useRestClient();
  const [isStakingDialogOpen, setIsStakingDialogOpen] = useState(false);
  const [addressForStaking, setAddressForStaking] = useState<string>('');;
  const [addresses, setAddresses] = useState<string[]>([]);
  const [stakingAmount, setStakingAmount] = useState('');
  const [selectedValidatorForStaking, setSelectedValidatorForStaking] = useState<string>('');
  const [isStakedInfoDialogOpen, setIsStakedInfoDialogOpen] = useState(false);
  const [stakingDetail, setstakingDetail] = useState('');

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "Address",
      headerName: "Address",
      flex: 2,
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "Balance",
      headerName: "Balance",
      flex: 1,
    },
    {
      field: "Staked",
      headerName: "Staked",
      flex: 1,
      renderCell: (cellValues) => (
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation(); // Prevent the row click event
            setAddressForStaking(cellValues.row.Address);
            setIsStakedInfoDialogOpen(true);
            get(`/cosmos/network/${indexerID}/address/${cellValues.row.Address}/stakingDetails`, {
              data: { name: accountName },
              onSuccess: (resp: AxiosResponse) => {
                setstakingDetail(JSON.stringify(resp.data, null, 2));
              },
            });
          }}
        >
          View Details
        </Button>
      ),
    },
  ];


  function Data({ rows }: { rows: Root }) {
    console.log("pageData.data", rows);
    console.log("workings");
    const parserRows = Object.entries(rows).map(([address, { amount, denom, name }], index) => ({
      id: index + 1,
      Address: address,
      Balance: `${amount} ${denom}`,
      Name: name,
      Staked: 'Staked'
    }));

    return (
      <Box sx={{ height: "auto", width: "100%" }}>
        <DataGrid
          sx={{ backgroundColor: "#0d172c" }}
          rows={parserRows}
          columns={columns}
          autoHeight={true}
          onRowClick={(params) => {
            const [amount, denom] = params.row.Balance.split(" ");
            setStakingAmount(amount)
            setAddressForStaking(params.row.Address);
            setIsStakingDialogOpen(true);
          }}
        />
      </Box>
    );
  }


  useEffect(() => {
    getIndexerById(indexerID);
    getPageData(indexerID);
    const interval = setInterval(() => {
      getPageData(indexerID);
    }, 5000);
    if (addresses.length == 0) {
      get(`/cosmos/network/${indexerID}/validators`, {
        data: { name: accountName },
        onSuccess: (resp: AxiosResponse) => {
          setAddresses(resp.data);
        },
      });
    }
    if (addresses.length > 0) {
      setSelectedValidatorForStaking(addresses[0]);
    }
    return () => {
      clearInterval(interval);
    };
  }, [indexerID]);

  function createAccount(indexerID: string, accountName: string) {
    toast.success("Sending request");
    post(`/cosmos/network/${indexerID}/createAccount`, {
      data: { name: accountName },
      onSuccess: (resp: AxiosResponse) => {
        setAccountName('')
        setCreateAccountResponse(JSON.stringify(resp.data, null, 2));
        toast.success("Successfully sent request");
        setOpen(false);
      },
      onError: () => {
        setAccountName('')
        toast.error("Failed to sent request");
        setOpen(false);
      },
    });
  }

  function stakeTokens() {
    // Example API call structure
    const data = {
      address: selectedValidatorForStaking,
      // Add other data as needed for staking
    };

    console.log("Staking validator address:", selectedValidatorForStaking);
    console.log("Staking for address:", addressForStaking);
    console.log("Staking for address:", stakingAmount);

    post(`/cosmos/network/${indexerID}/stake`, {
      data: {
        address: addressForStaking,
        validator: selectedValidatorForStaking,
        amount: stakingAmount
      },
      onSuccess: (resp: AxiosResponse) => {
        const str = resp.data
        const regex = /txhash: ([A-Z0-9]+)\\n/g;
        const match = regex.exec(str);

        let txhash = '';
        if (match) {
          txhash = match[1];
        }

        toast.success("Successfully staked request, txHash - " + txhash);
        setAddressForStaking('')
        setStakingAmount('');
        setSelectedValidatorForStaking('')
        setIsStakingDialogOpen(false);
      },
      onError: () => {
        toast.error("Failed to stake");
        setAddressForStaking('')
        setStakingAmount('');
        setSelectedValidatorForStaking('')
        setIsStakingDialogOpen(false);
      },
    });
  }

  // Use your API client to send `data` to your staking endpoint
  // Handle response or errors accordingly


  function formatResponse(response: string) {
    return response.replace(/\\n/g, '\n');
  }

  function getStakedInformation(accountAddress: string) {
    toast.success("Sending request");
    post(`/cosmos/network/${indexerID}/createAccount`, {
      data: { name: accountName },
      onSuccess: (resp: AxiosResponse) => {
        setAccountName('')
        setCreateAccountResponse(JSON.stringify(resp.data, null, 2));
        toast.success("Successfully sent request");
        setOpen(false);
      },
      onError: () => {
        setAccountName('')
        toast.error("Failed to sent request");
        setOpen(false);
      },
    });
  }

  return (
    <APIDataLoader {...indexerDetails}>
      <APIDataLoader {...pageData}>
        <Typography variant="h3">{indexerDetails.data?.name}</Typography>
        <Spacing spacing={3} />
        {pageData.data && <Data rows={pageData.data} />}
        <Spacing spacing={3} />
        <Stack direction={"row"}>
          <Button
            variant="contained"
            sx={{
              mr: 1,
              height: "50px",
              borderRadius: "4px",
              "&:hover": { transform: "scale(1.1)" },
            }}
            onClick={(e) => {
              setOpen(true);
            }}
          >
            <Typography variant={"h6"} fontWeight={600}>
              Add New Address
            </Typography>
          </Button>
        </Stack>
        <Dialog open={open} onClose={() => { setOpen(false); }}>
          <DialogTitle>Add New Address</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Account Name"
              type="text"
              fullWidth
              variant="standard"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setOpen(false); }}>Cancel</Button>
            <Button onClick={() => { createAccount(indexerID, accountName) }}>Submit</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={!!createAccountResponse}
          onClose={() => setCreateAccountResponse('')}
          fullWidth={true}
          maxWidth="md" // or "lg" for larger sizes
        >
          <DialogTitle>Account Creation Response</DialogTitle>
          <DialogContent style={{ width: '800px', height: 'auto' }}>
            {/* Apply inline style for preformatted text */}
            <Typography style={{ whiteSpace: 'pre-wrap' }}>
              <pre style={{ whiteSpace: "pre-wrap", overflowX: "auto" }}>{formatResponse(createAccountResponse)}</pre>


            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCreateAccountResponse('')}>Close</Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isStakingDialogOpen}
          onClose={() => setIsStakingDialogOpen(false)}
          fullWidth={true}
          maxWidth="md" // Adjust this for different sizes
        >
          <DialogTitle>Stake Tokens</DialogTitle>
          <DialogContent dividers style={{ minWidth: '500px' }}>
            <TextField
              select
              label="Select Address"
              value={selectedValidatorForStaking}
              onChange={(e) => setSelectedValidatorForStaking(e.target.value)}
              fullWidth
              variant="outlined"
              margin="normal"
            >
              {addresses.map((address) => (
                <MenuItem key={address} value={address}>
                  {address}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Staking Amount"
              type="number"
              value={stakingAmount}
              onChange={(e) => setStakingAmount(e.target.value)}
              fullWidth
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => {
              setIsStakingDialogOpen(false)
            }
            }>Cancel</Button>
            <Button onClick={() => stakeTokens()}>Stake</Button> {/* Implement stakeTokens */}
          </DialogActions>
        </Dialog>
        <Dialog
          open={isStakedInfoDialogOpen}
          onClose={() => setIsStakedInfoDialogOpen(false)}
          fullWidth={true}
          maxWidth="sm"
        >
          <DialogTitle>Staked Information</DialogTitle>
          <DialogContent>
            <Typography>Address: {addressForStaking}</Typography>
            <Typography> <pre style={{ whiteSpace: "pre-wrap", overflowX: "auto" }}>{stakingDetail}</pre></Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsStakedInfoDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

      </APIDataLoader>
    </APIDataLoader >
  );
};

export default MyWallets;
