import React, { useEffect } from "react";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import { CosmosNetwork } from "../IndexerClient";
import { Divider, Link, Paper, Stack, Tooltip, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Unstable_Grid2";
import Spacing from "../../common/Spacing";
import Copy from "../../common/clipboard/copy";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import useGetRequest from "../../common/rest-client/get";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import { useParams } from "react-router-dom";

const stateAtom = atom<APIData<any>>({
  key: "wallets",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string) => {
    indexerFetcher<any>(updatePageData, `/cosmos/network/${id}/genesis`);
  };

  return { pageData, getPageData, reset };
};

export function getElapsedTimeMini(pastDate: Date): string {
  const now = new Date();
  const diffInMs = now.getTime() - pastDate.getTime();

  // Separate time into components (seconds, minutes, hours, days)
  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Handle pluralization and unit formatting
  const formatUnit = (value: number, unit: string) =>
    value > 0 ? `${value} ${unit}${value > 1 ? "s" : ""}` : "";

  const formattedTime = [
    formatUnit(days, "Day"),
    formatUnit(hours % 24, "Hr"),
    formatUnit(minutes % 60, "Min"),
  ]
    .filter((part) => part.length > 0) // Remove empty parts
    .join(", "); // Join with commas

  // Construct the final human-readable string
  return formattedTime.length > 0 ? `${formattedTime}` : "just now";
}

export function getElapsedTime(pastDate: Date): string {
  const now = new Date();
  const diffInMs = now.getTime() - pastDate.getTime();

  // Separate time into components (seconds, minutes, hours, days)
  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Handle pluralization and unit formatting
  const formatUnit = (value: number, unit: string) =>
    value > 0 ? `${value} ${unit}${value > 1 ? "s" : ""}` : "";

  const formattedTime = [
    formatUnit(days, "Day"),
    formatUnit(hours % 24, "Hour"),
    formatUnit(minutes % 60, "Min"),
  ]
    .filter((part) => part.length > 0) // Remove empty parts
    .join(", "); // Join with commas

  // Construct the final human-readable string
  return formattedTime.length > 0 ? `${formattedTime}` : "just now";
}

export const KeyValue = ({ label, value }: any) => {
  return (
    <Grid xs={4}>
      <Stack direction="column" spacing={1}>
        <Typography sx={{ opacity: "0.5" }}>{label}</Typography>
        <Typography fontWeight="bold" overflow="scroll">
          {value}
        </Typography>
        <Divider />
      </Stack>
    </Grid>
  );
};

export function TruncateText({ text, maxLength }: any) {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
}

const IndexerOverview = ({ indexerDetails }: { indexerDetails: APIData<CosmosNetwork> }) => {
  const createdOn = new Date(indexerDetails.data?.createdOn);
  const [open, setOpen] = React.useState(false);
  const { pageData, getPageData, reset } = usePageData();
  const { id } = useParams();
  const indexerID = id || "";

  useEffect(() => {
    getPageData(indexerID);
    const interval = setInterval(() => {
      getPageData(indexerID);
    }, 5000);
    return () => {
      clearInterval(interval);
      reset();
    };
  }, [indexerID]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3">Network - {indexerDetails.data?.name} - 
          <Button variant="text" color="primary" onClick={handleClickOpen} sx={{ fontSize: "25px" }}>
            Genesis Details
          </Button>
        </Typography>
      </Stack>
      <Spacing spacing={2} />
      <Grid container spacing={2}>
        <Grid xs={9}>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Grid container spacing={2}>
                <KeyValue label="Name" value={indexerDetails.data?.name} />
                <KeyValue label="Chain ID" value={indexerDetails.data?.chainId} />
                <KeyValue label="Network Type" value={indexerDetails.data?.networkType} />
              </Grid>
              <Grid container spacing={2}>
                <KeyValue label="Status" value={indexerDetails.data?.status} />
                <KeyValue label="Duration" value={getElapsedTime(createdOn)} />
                <KeyValue label="Created On" value={createdOn.toLocaleDateString()} />
                <KeyValue
                  label="Number of Validators"
                  value={indexerDetails.data?.numberOfValidators}
                />
                <KeyValue label="No of Nodes" value={indexerDetails.data?.numberOfNodes} />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={3}>
          <Stack spacing={2} justifyContent="space-between" height="100%">
            <Paper variant="outlined" sx={{ bgcolor: "#4e1dff" }}>
              <Stack spacing={3}>
                <Typography fontWeight="bold" textAlign="center">
                  {indexerDetails.data?.topBlock}
                </Typography>
                <Typography sx={{ opacity: "0.5" }} textAlign="center">
                  Block Height
                </Typography>
              </Stack>
            </Paper>
            <Paper variant="outlined" sx={{ bgcolor: "#4e1dff" }}>
              <Stack spacing={3}>
                <Typography fontWeight="bold" textAlign="center">
                  {2342}
                </Typography>
                <Typography sx={{ opacity: "0.5" }} textAlign="center">
                  Total Transaction
                </Typography>
              </Stack>
            </Paper>
            <Paper variant="outlined" sx={{ bgcolor: "#4e1dff" }}>
              <Stack spacing={3}>
                <Typography fontWeight="bold" textAlign="center">
                  {indexerDetails.data?.status}
                </Typography>
                <Typography sx={{ opacity: "0.5" }} textAlign="center">
                  Health
                </Typography>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
        
      </Grid>
      <Spacing spacing={6} />
      <Grid container spacing={2} height="100%">
        <Grid xs={9}>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Grid container spacing={2}>
                <KeyValue
                  label="Explorer URL"
                  value={
                    <Stack direction="row">
                      <Copy content={indexerDetails.data?.explorerURL} contentType={""} source={""}>
                        <Tooltip title={indexerDetails.data?.explorerURL} placement={"top-start"}>
                          <Link
                            href={indexerDetails.data?.explorerURL}
                            target="_blank"
                            overflow={"scroll"}
                          >
                            <Typography overflow={"scroll"}>
                              {indexerDetails.data?.explorerURL}
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Copy>
                    </Stack>
                  }
                />
                <KeyValue
                  label="Node Url"
                  value={
                    <Stack direction="row">
                      <Copy content={indexerDetails.data?.nodeUrl} contentType={""} source={""}>
                        <Tooltip title={indexerDetails.data?.nodeUrl} placement={"top-start"}>
                          <Link
                            href={indexerDetails.data?.nodeUrl}
                            target="_blank"
                            overflow={"scroll"}
                          >
                            <Typography overflow={"scroll"}>
                              {indexerDetails.data?.nodeUrl}
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Copy>
                    </Stack>
                  }
                />
              </Grid>
              <Spacing spacing={1} />
              <Grid container spacing={2}>
                <KeyValue
                  label="Github URL"
                  value={
                    <Stack direction="row">
                      <Copy content={indexerDetails.data?.githubUrl} contentType={""} source={""}>
                        <Tooltip title={indexerDetails.data?.githubUrl} placement={"top-start"}>
                          <Link
                            href={indexerDetails.data?.githubUrl}
                            target="_blank"
                            overflow={"scroll"}
                          >
                            <Typography overflow={"scroll"}>
                              <TruncateText text={indexerDetails.data?.githubUrl || ''} maxLength={28} />
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Copy>
                    </Stack>
                  }
                />
                <KeyValue
                  label="Network CLI URL"
                  value={
                    <Stack direction="row">
                      <Copy content={indexerDetails.data?.networkCliUrl} contentType={""} source={""}>
                        <Tooltip title={indexerDetails.data?.networkCliUrl} placement={"top-start"}>
                          <Link
                            href={indexerDetails.data?.networkCliUrl}
                            target="_blank"
                            overflow={"scroll"}
                          >
                            <Typography overflow={"scroll"}>
                              <TruncateText text={indexerDetails.data?.networkCliUrl || ''} maxLength={28} />
                            </Typography>
                          </Link>
                        </Tooltip>
                      </Copy>
                    </Stack>
                  }
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
       
      </Grid>

      <Spacing spacing={3} />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h6">Additional Network Details</Typography>
      </Stack>
      
      <Grid container spacing={1} height="100%">
        <Grid xs={9}> {/* Make sure to use item prop for Grid items */}
          <Card variant="outlined" sx={{ width: "100%", height: "100%" }}>
            {/* Use pre tags and JSON.stringify to format the JSON data */}
            <Typography component="div" style={{ overflow: "auto", height: "100%" }}>
              <pre>{JSON.stringify(indexerDetails.data?.NodeDetails, null, 2)}</pre>
            </Typography>
          </Card>
        </Grid>
      </Grid>

      <React.Fragment>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Genesis Details</DialogTitle>
          <DialogContent>
            <Card variant="outlined" sx={{ overflow: "auto" }}>
              <APIDataLoader {...pageData}>
                <CardContent>
                  <pre>{JSON.stringify(pageData.data, null, 2)}</pre>
                </CardContent>
              </APIDataLoader>
            </Card>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    </>
  );
};

export default IndexerOverview;
