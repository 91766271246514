import React, { useState } from "react";
import { Link, Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import Mixpanel, { MixpanelEvents, MixpanelCustomUserProperties } from "../../MixpanelConfig";
import { clearLocalStorage } from "../common/clearLocalStorage";
import VerifyEmail from "./VerifyEmail";

function SignIn() {
  const [isRequestingForEmailConfirmation, setRequestingForEmailConfirmation] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm();

  const onSubmit = ({ userName, password }: any) => {
    clearLocalStorage();
    return Auth.signIn(userName, password)
      .then((res) => {
        Mixpanel.identify(res.username);
        Mixpanel.track(MixpanelEvents.SignIn);
        Mixpanel.people.increment(MixpanelCustomUserProperties.Sessions);
      })
      .catch((error) => {
        if (error?.message === "User is not confirmed.") {
          setRequestingForEmailConfirmation(true);
          Auth.resendSignUp(getValues("userName"))
            .then(() => console.log("Sent verification code"))
            .catch((error) => toast.error(error?.message));
          return;
        }
        toast.error(error?.message);
      });
  };

  if (isRequestingForEmailConfirmation) {
    return <VerifyEmail userName={getValues("userName")} password={getValues("password")} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography fontWeight={500} fontSize="1.5rem">
          Sign in to your account
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight="500">Username*</Typography>
          <TextField
            autoFocus
            {...register("userName", { required: true })}
            placeholder="Enter your username"
            helperText={errors?.userName?.messages}
            error={!!errors?.userName}
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="500">Password*</Typography>
          <TextField
            {...register("password", { required: true })}
            type="password"
            placeholder="Enter your password"
            helperText={errors?.password?.messages}
            error={!!errors?.password}
          />
          <Typography>
            Forgot your password?{" "}
            <Link component={RouterLink} to="/auth/reset-password">
              Reset here
            </Link>
          </Typography>
        </Stack>
        <div>
          <LoadingButton loading={isSubmitting} type="submit">
            Sign In
          </LoadingButton>
        </div>
        <Typography>
          New user?{" "}
          <Link component={RouterLink} to="/auth/sign-up">
            Create account
          </Link>
        </Typography>
      </Stack>
    </form>
  );
}

export default SignIn;
