import { atom, useRecoilState } from "recoil";
import { PayableToken } from "./Payments";
import React, { useEffect } from "react";
import { Autocomplete, Stack, TextField } from "@mui/material";
import { isEmpty } from "lodash";

export const selectedPaymentMethodAtom = atom<PayableToken>({
  key: "SELECTED_PAYMENT_METHOD_ATOM",
  default: {
    token_address: "",
    token_symbol: "",
    chain_id: 0,
    chain_display_name: "",
    chain_name: "",
    rpc_url: "",
    swap_link: "",
    price: 0,
    token_decimals: 0,
  },
});

const useSelectedPaymentMethod = () => {
  const [selectedPaymentMethod, updateSelectedPaymentMethod] =
    useRecoilState(selectedPaymentMethodAtom);

  return {
    selectedPaymentMethod,
    updateSelectedPaymentMethod,
  };
};

export const SelectPaymentMethod = ({ options }: { options: Array<PayableToken> }) => {
  const { selectedPaymentMethod, updateSelectedPaymentMethod } = useSelectedPaymentMethod();
  useEffect(() => {
    if (!isEmpty(options) && selectedPaymentMethod.chain_name === "") {
      updateSelectedPaymentMethod(options[0]);
    }
  }, [options]);
  return (
    <Stack>
      <Autocomplete
        value={selectedPaymentMethod}
        renderInput={(params) => <TextField {...params} label="Select Token" />}
        options={options}
        getOptionLabel={(option) => `${option.token_symbol} - ${option.chain_display_name}`}
        onChange={(e, value) => {
          if (value) {
            updateSelectedPaymentMethod(value);
          }
        }}
      />
    </Stack>
  );
};

export default useSelectedPaymentMethod;
