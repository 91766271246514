import React from "react";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";

const NewPricing = () => {
  return (
    <Container maxWidth={"lg"}>
      <br />
      <Typography align={"center"} color={"primary"} fontSize={"22px"}>
        Important Pricing Update: Effective Sept 1st
      </Typography>
      <br />
      <Typography fontSize={"18px"}>
        Starting from September 1st, there will be changes to our pricing for our services please go
        through the below details Feel free to contact us if you have any questions or need help to
        support@unmarshal.io or join our Discord channel.
      </Typography>
      <br />
      <Typography color={"primary"} fontSize={"22px"}>
        Explore Plan ($20 worth of MARSH):
      </Typography>
      <Typography fontSize={"18px"}>⊛ 1 month free trial</Typography>
      <Typography fontSize={"18px"}>⊛ 50 requests per minute</Typography>
      <Typography fontSize={"18px"}>⊛ 1 Million Credits valid for 1 month.</Typography>
      <Typography fontSize={"18px"}>
        ⊛ After using the service for 1 month, users have to renew / upgrade the plan. This upgrade
        is essential to continue accessing our service. Without the upgrade, API requests will not
        be possible.
      </Typography>
      <br />
      <Typography color={"primary"} fontSize={"22px"}>
        Startup Plan ($99 worth of MARSH):
      </Typography>
      <Typography fontSize={"18px"}>⊛ 500 requests per minute.</Typography>
      <Typography fontSize={"18px"}>⊛ 5 Million Credits valid for 1 month.</Typography>
      <Typography fontSize={"18px"}>
        ⊛ We have removed credit over-usage. Once your credits have been depleted, you will have to
        upgrade your plan / renew it to continue accessing our service.
      </Typography>
    </Container>
  );
};

export default NewPricing;
