import React, { useEffect } from "react";
import { LinearProgress, LinearProgressProps, Tooltip, Typography } from "@mui/material";
import { SyncStatus, useCosmosNetworkByID } from "../IndexerDetailsClient";
import { max, toNumber } from "lodash";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import IndexerOverview from "./IndexerOverview";
import Card from "@mui/material/Card";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import { InfoRounded } from "@mui/icons-material";

export function LinearProgressWithLabel(
  props: LinearProgressProps & { sync_status: SyncStatus; isChild: boolean }
) {
  const syncStatus = props.sync_status;
  const value =
    ((toNumber(syncStatus?.last_indexed_block) - toNumber(syncStatus?.start_block)) /
      (toNumber(syncStatus?.node_top_block) - toNumber(syncStatus?.start_block))) *
    100;
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ width: "100%", mr: 1 }} color={"#197273"}>
        <LinearProgress color={"inherit"} variant="determinate" value={value} />
      </Box>
      {props.isChild && value === 0 && (
        <Tooltip
          sx={{ mr: 1 }}
          placement={"top"}
          color={"#868686"}
          title={"Child parser will start syncing once parent has indexed child's start block"}
        >
          <InfoRounded />
        </Tooltip>
      )}
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {isNaN(value) ? "--" : `${max([value.toFixed(2), 0])}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const IndexerDetails = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById, reset } = useCosmosNetworkByID();

  useEffect(() => {
    getIndexerById(indexerID);
    const interval = setInterval(() => {
      getIndexerById(indexerID);
    }, 5000);
    return () => {
      clearInterval(interval);
      reset();
    };
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <IndexerOverview indexerDetails={indexerDetails} />
    </APIDataLoader>
  );
};

export default IndexerDetails;
