import parse from "date-fns/parse";
import format from "date-fns/format";

export const fromISOToLocalString = (dateString: string) => {
  const date = parse(dateString, "yyyy-MM-dd", new Date());
  return format(date, "dd-MM-yyyy");
};

export const formatDateToString = (dateString: string) => {
  return new Date(dateString).toDateString();
};

export const fromDateToFormat = (dateString: string, changeFormat: string) => {
  const date = new Date(dateString);
  return format(date, changeFormat);
};

export function isValidDate(date: any) {
  return !isNaN(new Date(date).getTime());
}
