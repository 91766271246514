import React, { useEffect } from "react";
import { Grid, IconButton, Menu, MenuItem, Stack, Tooltip } from "@mui/material";
import Spacing from "../../common/Spacing";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import { GraphTypes } from "./Types";
import useUser from "../../user/useUser";
import useUnmarshalApiGetRequest from "../../common/rest-client/unmarshalClient";
import { getUnmarshalChainName } from "../../../config";
import useIndexerDetailsByID, { ExtendedIndexerDetails } from "../IndexerDetailsClient";
import Table from "./Table";
import { IndexerDetails, IndexerType } from "../IndexerClient";
import { useNavigate, useParams } from "react-router-dom";
import StatisticsTabs, { StatisticsTabsType } from "./StatisticsTabs";
import Button from "@mui/material/Button";
import useRestClient from "../../common/rest-client/RestClient";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import { toast } from "react-toastify";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { copyTextToClipboard } from "../../common/clipboard/copy";
import Graph from "./Graph";
import { fromISOToLocalString } from "../../common/date";
import { toParserCredentials } from "../../common/Routes";

interface Statistics {
  name: string;
  path: (chain: string, contractAddress: string) => string;
  graphType: GraphTypes;
  columns?: string[];
  render?: (value: string, indexer?: ExtendedIndexerDetails) => string;
}

const statistics: Array<Statistics> = [
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/tx_count`,
    name: "Transaction count over time",
    graphType: GraphTypes.Chart,
  },
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/tx_success_count`,
    name: "Successful transaction count over time",
    graphType: GraphTypes.Chart,
  },
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/tx_failure_count`,
    name: "Failed transaction count over time",
    graphType: GraphTypes.Chart,
  },
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/top_method`,
    name: "Top methods called",
    graphType: GraphTypes.List,
    columns: ["name", "y"],
    render: (value: string, indexer?: ExtendedIndexerDetails) => {
      const functionDetails = indexer?.allFunctions.get(value);
      return !!functionDetails ? functionDetails.signature : value;
    },
  },
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/top_caller`,
    name: "Top callers",
    graphType: GraphTypes.List,
    columns: ["name", "y"],
  },
  {
    path: (chain: string, contractAddress: string) =>
      `/v1/${chain}/contract-metrics/${contractAddress}/gas_spent_over_time`,
    name: "Gas spent over time",
    graphType: GraphTypes.Chart,
  },
  // {
  //   path: (chain: string, contractAddress: string) =>
  //     `/v1/${chain}/contract-metrics/${contractAddress}/eth_spent_over_time`,
  //   name: "Eth spent over time",
  //   graphType: GraphTypes.Chart,
  // },
];

const statisticsAtom = atom<Array<APIData<any>>>({
  key: "STATISTICS_ATOM",
  default: statistics.map(() => DefaultAPIDataValues),
});

const useStatistics = () => {
  const [statisticsState, updateStatistics] = useRecoilState(statisticsAtom);
  const reset = useResetRecoilState(statisticsAtom);
  const get = useUnmarshalApiGetRequest();
  const getAllStatistics = (apiKey: string, chainName: string, ContractAddress: string) => {
    statistics.forEach((statistic, index) => {
      get(
        apiKey,
        (setterOrUpdater) => {
          if (typeof setterOrUpdater === "function") {
            updateStatistics((valOrUpdater) => {
              const newState = [...valOrUpdater];
              newState[index] = setterOrUpdater(valOrUpdater[index]);
              return newState;
            });
          } else {
            updateStatistics((valOrUpdater) => {
              const newState = [...valOrUpdater];
              newState[index] = setterOrUpdater;
              return newState;
            });
          }
        },
        statistic.path(chainName, ContractAddress)
      );
    });
  };
  return {
    statisticsState,
    getAllStatistics,
    reset,
  };
};

function GraphMenu(props: { statistic: Statistics; indexerDetails: IndexerDetails }) {
  const { getCurl } = useRestClient();
  const { apiKey } = useUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("cURL copied");
  };

  return (
    <>
      <IconButton
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          padding: 2,
          backgroundColor: "transparent",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            copyTextToClipboard(
              getCurl(
                "https://api.unmarshal.com" +
                  props.statistic.path(
                    getUnmarshalChainName(props.indexerDetails.chain),
                    props.indexerDetails.contract_address
                  ),
                apiKey
              ),
              onCopy,
              () => alert("Failed to copy")
            );
            handleClose();
          }}
        >
          <Typography>Copy cURL</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

const ContractDashboard = () => {
  const { statisticsState, getAllStatistics, reset } = useStatistics();
  const { apiKey } = useUser();
  const { indexerDetails, getIndexerById } = useIndexerDetailsByID();
  const { id } = useParams();
  const indexerID = id || "";
  const navigate = useNavigate();

  const { getCurl } = useRestClient();
  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("cURL copied");
  };

  useEffect(() => {
    getIndexerById(indexerID);
  }, [indexerID]);

  if (indexerDetails?.data?.indexer_type === IndexerType.SourceOnly) {
    navigate(toParserCredentials(indexerID));
  }

  useEffect(() => {
    if (!!apiKey && indexerDetails.isSuccess) {
      reset();
      const chainName = getUnmarshalChainName(indexerDetails.data?.chain);
      getAllStatistics(apiKey, chainName, indexerDetails.data?.contract_address);
      const interval = setInterval(() => {
        getAllStatistics(apiKey, chainName, indexerDetails.data?.contract_address);
      }, 20 * 1000);

      return () => clearInterval(interval);
    }
  }, [apiKey, indexerDetails.data?.chain, indexerDetails.data?.contract_address]);

  return (
    <>
      <StatisticsTabs
        value={StatisticsTabsType.CONTRACT}
        indexerId={indexerID}
        indexerDetails={indexerDetails}
      />
      <Spacing spacing={2} />
      <Grid container spacing={4} justifyContent={"space-around"}>
        {statisticsState.map(({ data, isSuccess, isLoading, hasError }, index) => {
          const statistic = statistics[index];
          return (
            <Grid item sm={6} key={statistic.name}>
              <Card variant={"outlined"} sx={{ minHeight: 300 }}>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography variant={"h6"}>{statistic.name}</Typography>
                  {/* <GraphMenu*/}
                  {/*  key={statistic.name}*/}
                  {/*  statistic={statistic}*/}
                  {/*  indexerDetails={indexerDetails.data}*/}
                  {/* />*/}
                  <Tooltip
                    title={"cURL of the request to get data generating this graph"}
                    placement={"top"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        copyTextToClipboard(
                          getCurl(
                            "https://api.unmarshal.com" +
                              statistic.path(
                                getUnmarshalChainName(indexerDetails?.data?.chain),
                                indexerDetails?.data?.contract_address
                              ),
                            apiKey
                          ),
                          onCopy,
                          () => alert("Failed to copy")
                        );
                      }}
                    >
                      Copy cURL
                    </Button>
                  </Tooltip>
                </Stack>
                {(() => {
                  if (statistic.graphType === GraphTypes.List) {
                    return (
                      <Table
                        hasError={hasError}
                        isLoading={isLoading && !isSuccess}
                        data={data?.data}
                        columns={statistic?.columns}
                        render={statistic?.render}
                        indexer={indexerDetails.data}
                      />
                    );
                  }
                  return (
                    <Graph
                      data={data?.data}
                      isLoading={isLoading && !isSuccess}
                      hasError={hasError}
                      xAxisLabelFormatter={fromISOToLocalString}
                    />
                  );
                })()}
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ContractDashboard;
