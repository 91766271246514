import React, { useEffect } from "react";
import Providers from "./components/providers/Providers";
import AppRouter from "./components/AppRouter";
import mixpanel from "mixpanel-browser";
import Mixpanel, {
  MixpanelEvents,
  MixpanelToken,
  MixpanelCustomUserProperties,
  mp_session_config,
} from "./MixpanelConfig";
import { useAuth } from "@aws-amplify/ui-react/internal";
import initHelpHero from "helphero";

export const hlp = initHelpHero("O5RhtGM6sR");

if (MixpanelToken) {
  mixpanel.init(MixpanelToken, {
    loaded: function () {
      mp_session_config.check_Session_id();
      Mixpanel.track(MixpanelEvents.LandingPage);
    },
    api_host: "/mixpanel",
  });
}

function App() {
  const auth = useAuth();
  useEffect(() => {
    if (auth.user?.username) {
      Mixpanel.identify(auth.user.username);
      Mixpanel.people.increment(MixpanelCustomUserProperties.Sessions);
      hlp.identify(auth.user?.username);
      hlp.update(function (user) {
        return {
          checkoutVisits: isFinite(Number(user.checkoutVisits))
            ? Number(user.checkoutVisits) + 1
            : 1,
        };
      });
    }
  }, [auth]);
  return (
    <Providers>
      <AppRouter />
    </Providers>
  );
}

export default App;
