import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, Slider, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Spacing from "../common/Spacing";
import WalletOptionsDialog from "../Wallet/WalletOptionsDialog";
import usePayments, { Tenure } from "./Payments";
import Box from "@mui/material/Box";
import CopyApiKey from "../auth/CopyApiKey";
import Typography from "@mui/material/Typography";
import { capitalize, find, isEmpty, sumBy, upperFirst } from "lodash";
import Card from "@mui/material/Card";
import { PlanDetails } from "./UpgradeOptions";
import { getPlanDetailsByName } from "./Plans";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { upgradePlan } from "../common/Routes";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import PurchasePlanButton from "./PurchasePlanButton";
import PromoCode, { promoCodeAtom } from "./PromoCode";
import { useResetRecoilState } from "recoil";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { styled } from "@mui/material/styles";
import useSelectedPaymentMethod, { SelectPaymentMethod } from "./SelectedPaymentMethod";

const PlanSlider = styled(Slider)({
  color: "#4e1dff",
  width: "50%",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 100,
    height: 32,
    borderRadius: "16px",
    backgroundColor: "#4e1dff",
  },
});

function UpgradePlan() {
  const { planId: planIdString, tenureId: tenureIdString } = useParams();
  const planId = Number(planIdString);
  const tenureId: number = Number(tenureIdString);
  const navigate = useNavigate();
  const { planPriceDetails, getPlanPriceDetails, resetPlanPriceDetails } = usePayments();
  const planDetails = planPriceDetails.data;
  const tenure: Tenure = find(planDetails?.tenures, { id: tenureId }) as Tenure;
  const resetPromoCodeSate = useResetRecoilState(promoCodeAtom);
  const { selectedPaymentMethod } = useSelectedPaymentMethod();

  useEffect(() => resetPromoCodeSate, []);

  useEffect(() => {
    getPlanPriceDetails(planId);

    // const interval = setInterval(() => {
    //   getPlanPriceDetails(planId, planDetails?.promo_code);
    // }, 3 * 60 * 1000);

    return () => {
      // clearInterval(interval);
      resetPlanPriceDetails();
    };
  }, []);

  const marks = [
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 12,
      label: "",
    },
  ];

  const sliderTenureMap = new Map<number, string>([
    [1, "monthly"],
    [2, "monthlyX2"],
    [3, "monthlyX3"],
    [12, "yearly"],
  ]);

  const sliderValueByTenure = new Map<string, number>([
    ["monthly", 1],
    ["monthlyX2", 2],
    ["monthlyX3", 3],
    ["yearly", 12],
  ]);

  function getTenureFromSlider(value: any) {
    return (
      planPriceDetails.data?.tenures?.find(
        (tenure) => tenure?.tenure === sliderTenureMap.get(value)
      )?.id || 0
    );
  }

  function BillingSummary() {
    const tenurePriceByName = (name: string) => {
      const tenure = find(planDetails?.tenures, { tenure: name });
      return tenure?.price || 0;
    };

    const handleChange = (e: Event) => {
      Mixpanel.track(MixpanelEvents.UpgradePlanPage, {
        plan: planDetails?.name,
        tenure: (e?.target as HTMLInputElement)?.value,
      });
      const tenureId = getTenureFromSlider((e?.target as HTMLInputElement)?.value);
      if (tenureId !== 0) {
        navigate(upgradePlan(planId, tenureId));
      }
    };

    return (
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight="bold">
          Upgrade plan
          <Spacing spacing={2} />
        </Typography>
        <Typography variant="h6">
          <span style={{ opacity: 0.75 }}>Upgrade to our</span>‘{capitalize(planDetails?.name)}{" "}
          Plan’
        </Typography>
        <Spacing spacing={2} />
        {planDetails.name.toLowerCase() !== "explore" && (
          <PlanSlider
            aria-label="Restricted values"
            defaultValue={sliderValueByTenure.get(
              planPriceDetails.data?.tenures?.find((tenure) => tenure?.id === tenureId)?.tenure ||
                ""
            )}
            getAriaValueText={() => " "}
            valueLabelFormat={(value) => (value === 1 ? `${value} Month` : `${value} Months`)}
            max={12}
            step={null}
            onChange={handleChange}
            valueLabelDisplay="on"
            marks={marks}
          />
        )}
        <Stack direction="row" alignItems="flex-end">
          <Typography variant="h2" fontWeight="bolder">
            ${tenure?.price}
          </Typography>
          <Typography variant="subtitle1" fontSize="large" sx={{ mb: 1 }}>
            /{upperFirst(tenure?.tenure)}
          </Typography>
        </Stack>
        {planDetails.name.toLowerCase() !== "explore" && (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                color={(theme) => theme.palette.primary.contrastText}
                fontSize={16}
                component="text"
                fontWeight="bold"
                sx={{
                  p: 0.1,
                  pl: 1,
                  pr: 1,
                  background: (theme) => theme.palette.primary.main,
                  borderRadius: 1,
                }}
              >
                SAVE 10%
              </Typography>
              <Typography>by opting for 2 / 3 Months</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography
                color={(theme) => theme.palette.primary.contrastText}
                fontSize={16}
                component="text"
                fontWeight="bold"
                sx={{
                  p: 0.1,
                  pl: 1,
                  pr: 1,
                  background: (theme) => theme.palette.primary.main,
                  borderRadius: 1,
                }}
              >
                SAVE ${tenurePriceByName("monthly") * 12 - tenurePriceByName("yearly")}
              </Typography>
              <Typography>by opting for the annual plan</Typography>
            </Stack>
          </>
        )}
        <PlanDetails plan={getPlanDetailsByName(planDetails?.name)} />
      </Stack>
    );
  }

  function Billing() {
    const tokenPrice = selectedPaymentMethod.price;
    const price = tenure?.price / tokenPrice;
    const excessUsagePrice = sumBy(planDetails?.excess_usage, (usage) => {
      const method = find(
        usage.price_details,
        (priceDetails) =>
          priceDetails.chain_id === selectedPaymentMethod.chain_id &&
          priceDetails.token_address === selectedPaymentMethod.token_address
      );
      return method?.price || 0;
    });
    const netAmount = tenure?.discounted_price / tokenPrice + excessUsagePrice;
    return (
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2} margin={3}>
            <SelectPaymentMethod options={tenure.currency_amounts} />
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
              <Typography variant="h6" fontWeight="bold" fontSize={"20px"}>
                Billing Summary
              </Typography>
            </Stack>
            <Stack spacing={1.5}>
              <Stack direction="row" justifyContent="space-between">
                <Typography>
                  Unmarshal {upperFirst(planDetails?.name)} Plan ({tenure?.tenure} billing)
                </Typography>
                <Stack alignItems={"flex-end"}>
                  <Typography fontWeight="bold" fontSize={"20px"} align={"right"}>
                    {selectedPaymentMethod?.token_symbol} {price.toFixed(2)}
                  </Typography>
                  <Button
                    variant={"text"}
                    sx={{ width: "max-content", fontStyle: "italic" }}
                    onClick={() =>
                      Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                        to: "Buy Marsh from PancakeSwap",
                      })
                    }
                    href={selectedPaymentMethod.swap_link}
                    target={"_blank"}
                  >
                    Buy $MARSH
                  </Button>
                </Stack>
              </Stack>
              <Divider />
              <PromoCode />
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Typography>Discount</Typography>
                <Typography color={"primary"} fontWeight="bold">
                  {!!tenure?.discount && "-"}
                  {tenure?.discount} %
                </Typography>
              </Stack>
              {excessUsagePrice > 0 && (
                <>
                  <Divider>+</Divider>
                  {(planDetails?.excess_usage || []).map((excessUsage, index) => {
                    const method = find(
                      excessUsage.price_details,
                      (priceDetails) =>
                        priceDetails.chain_id === selectedPaymentMethod.chain_id &&
                        priceDetails.token_address === selectedPaymentMethod.token_address
                    );
                    return (
                      <Stack direction="row" justifyContent="space-between" key={index}>
                        <Typography>
                          {`${excessUsage?.description} (${excessUsage?.excess_credits})`}
                        </Typography>
                        <Typography fontWeight="bold" align={"right"}>
                          {`${method?.token_symbol} ${(method?.price || 0).toFixed(2)}`}
                        </Typography>
                      </Stack>
                    );
                  })}
                </>
              )}
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Typography>Order total</Typography>
                <Typography fontWeight="bold">
                  {selectedPaymentMethod?.token_symbol} {netAmount.toFixed(2)}
                </Typography>
              </Stack>
              <Spacing spacing={2} />
              <PurchasePlanButton
                netAmount={netAmount}
                tokenSymbol={selectedPaymentMethod?.token_symbol as string}
                currency={selectedPaymentMethod}
                tenure={tenure}
                promoCode={planDetails?.promo_code}
                plan={planDetails?.name}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Container maxWidth="xl" style={{ position: "relative" }}>
      <APIDataLoader {...planPriceDetails} isLoading={!planPriceDetails.isSuccess}>
        <Grid container spacing={4} justifyContent="space-around">
          <Grid item xs={6}>
            <br />
            <BillingSummary />
          </Grid>
          <Grid item xs={6}>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box>
                <CopyApiKey />
              </Box>
            </Stack>
            <Spacing spacing={2} />
            <Billing />
          </Grid>
        </Grid>
        <WalletOptionsDialog />
      </APIDataLoader>
    </Container>
  );
}

export default UpgradePlan;
