import React, { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@aws-amplify/ui-react/internal";
import useUser from "../user/useUser";

// Refresh session token every 4 minutes
const RefreshToken = ({ children }: { children: JSX.Element }) => {
  const { refreshUserSession, getUserDetails, isAuthLoading, isLoggedIn } = useUser();

  useEffect(() => {
    if (isLoggedIn) {
      if (!isAuthLoading) {
        refreshUserSession();
        const interval = setInterval(() => {
          refreshUserSession();
          getUserDetails();
        }, 4 * 60 * 1000);

        return () => clearInterval(interval);
      }
    }
  }, [isAuthLoading, isLoggedIn]);

  return children;
};

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const { getUserDetails } = useUser();

  useEffect(() => {
    if (!auth.isLoading) {
      getUserDetails();
    }
  }, [auth.user]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }
  if (!auth.user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  return <RefreshToken>{children}</RefreshToken>;
}

export default RequireAuth;
