import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";
import useWalletConnector, { Wallets } from "./Connectors";
import { useWalletOptionsDialog } from "./WalletOptionsDialog";
import useSelectedPaymentMethod from "../payments/SelectedPaymentMethod";

export function useWalletConnect() {
  const { selectedPaymentMethod } = useSelectedPaymentMethod();
  return useWallet(selectedPaymentMethod.chain_id || 0, selectedPaymentMethod.rpc_url || "");
}

function useWallet(chainId: number, jRpcURL: string) {
  const { toggleMenu, closeDialog } = useWalletOptionsDialog();
  const provider = new ethers.providers.JsonRpcProvider(jRpcURL);

  const { setPreferredWallet, getPreferredWalletConnector, disconnectWallet, switchNetwork } =
    useWalletConnector(chainId, jRpcURL);
  const { activate, active, account, deactivate, library } = useWeb3React<Web3Provider>();

  const getSigner = () => library?.getSigner();
  const getProvider = () => provider;

  const handleConnectionReject = (e: UnsupportedChainIdError) => {
    toast.warn(e.message);
  };

  const tryAutoConnect = () => {
    activateWallet();
  };

  function activateWallet() {
    const preferredWalletConnector = getPreferredWalletConnector();
    if (!preferredWalletConnector) {
      return;
    }
    activate(preferredWalletConnector, undefined, true)
      .then(closeDialog)
      .catch(async (e) => {
        console.warn("Failed to activate wallet", e);
        if (e?.message?.includes("Unsupported")) {
          const switched = await switchNetwork();
          if (switched) {
            activateWallet();
            return;
          }
        }
        handleConnectionReject(e);
        disconnect();
      });
  }

  const connectWallet = (wallet: Wallets) => {
    setPreferredWallet(wallet);
    activateWallet();
  };

  const disconnect = () => {
    deactivate();
    disconnectWallet();
  };

  return {
    openWalletConnectOptions: toggleMenu,
    connectWallet,
    isConnected: active,
    userAddress: account,
    disconnect,
    getSigner,
    tryAutoConnect,
    getProvider,
  };
}
