import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import {
  Autocomplete,
  Chip,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { signIn } from "../common/Routes";
import useRestClient from "../common/rest-client/RestClient";
import Mixpanel, { MixpanelCustomUserProperties, MixpanelEvents } from "../../MixpanelConfig";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TransitionBottomUp from "../common/annimations/TransitionBottomUp";
import CloseIcon from "../../assets/icons/Close.svg";
import Button from "@mui/material/Button";
import Spacing from "../common/Spacing";
import { clearLocalStorage } from "../common/clearLocalStorage";
import VerifyEmail from "./VerifyEmail";

function SignUp() {
  const [isRequestingForEmailConfirmation, setRequestingForEmailConfirmation] = useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { get } = useRestClient();
  const [searchParams] = useSearchParams();
  const referralCode = searchParams.get("referralCode");

  const defaultValues: any = {
    referralCode,
  };
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setValue,
    control,
    watch,
  } = useForm({
    defaultValues,
  });

  const [role, setRole] = useState("");
  const [useCases, setUseCases] = useState("");

  const [open, setOpen] = React.useState(false);
  const [referralApplied, setReferralApplied] = useState(referralCode !== null);
  const openDialog = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const useCasesMap = new Map([
    [
      "dev",
      {
        label: "Developer",
        useCases: ["DeFi api", "Nft api", "Notifications", "Smart contract tracking", "Others"],
      },
    ],
    [
      "non-dev",
      {
        label: "Non-Developer",
        useCases: ["Marketing", " Investor ", " Founder", "Others"],
      },
    ],
  ]);

  const onSubmit = ({ userName, password, email, role, others, referralCode }: any) => {
    clearLocalStorage();
    if (useCases.length === 0) {
      toast.error("Please select at least one use case");
      return;
    }
    return new Promise((resolve, reject) => {
      function createUser() {
        Auth.signUp({
          username: userName,
          password,
          attributes: {
            email,
            "custom:role": role,
            "custom:useCase": useCases.includes("Others") ? useCases + ", " + others : useCases,
            "custom:referralCode": referralCode,
          },
        })
          .then((data) => {
            Mixpanel.people.set({
              $name: userName,
              $email: email,
              Role: role,
              UseCase: useCases.includes("Others") ? useCases + ", " + others : useCases,
              Plan: "Free",
            });
            Mixpanel.identify(userName);
            Mixpanel.track(MixpanelEvents.SignUp);
            setRequestingForEmailConfirmation(true);
            setUserName(userName);
            setPassword(password);
            resolve(data);
          })
          .catch((error) => {
            toast.error(error?.message);
            return reject(error);
          });
      }

      if (!referralCode) {
        createUser();
        return;
      }
      get(`/users-service/public/referral-codes/verify?referral_code=${referralCode}`, {
        onSuccess: () => {
          createUser();
        },
        onError: (error) => {
          toast.error("Invalid referral code!");
          return reject(error);
        },
      });
    });
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  if (isRequestingForEmailConfirmation) {
    return <VerifyEmail userName={userName} password={password} />;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography fontWeight={500} fontSize="1.5rem">
          Create new account
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight="500">Username*</Typography>
          <TextField
            {...register("userName", { required: true })}
            error={!!errors?.userName}
            helperText={errors?.userName?.messages}
            placeholder="Please enter your username"
            autoFocus
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="500">E-mail*</Typography>
          <TextField
            {...register("email", {
              required: true,
              pattern: {
                value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/,
                message: "Entered value does not match email format",
              },
            })}
            type={"email"}
            error={!!errors?.email}
            helperText={errors?.email?.messages}
            placeholder="Please enter your email address"
          />
          {errors.email && <span role="alert">{errors.email.message}</span>}
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="500">Role*</Typography>
          <RadioGroup
            row
            onChange={(event, value) => {
              setValue("role", value);
              setRole(value);
              setUseCases("");
            }}
          >
            {Array.from(useCasesMap.keys()).map((role) => (
              <FormControlLabel
                key={role}
                value={role}
                control={<Radio required />}
                label={useCasesMap.get(role)?.label}
              />
            ))}
          </RadioGroup>
        </Stack>
        {role !== "" && (
          <Stack spacing={1}>
            <Typography fontWeight="500">Use cases*</Typography>
            <Controller
              control={control}
              name={"useCase"}
              render={({ field: { onChange, onBlur } }) => (
                <Autocomplete
                  multiple
                  id="tags-filled"
                  value={useCases.length > 0 ? useCases.split(", ") : []}
                  onBlur={onBlur}
                  onChange={(event, value: string[]) => {
                    setUseCases(value.join(", "));
                    onChange(value);
                  }}
                  options={useCasesMap.get(role)?.useCases || []}
                  freeSolo
                  disableCloseOnSelect
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      // eslint-disable-next-line react/jsx-key
                      <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Please select use cases" />
                  )}
                />
              )}
            />
          </Stack>
        )}
        {useCases.includes("Others") && (
          <Stack spacing={1}>
            <Typography fontWeight="500">Please mention other use cases*</Typography>
            <TextField
              {...register("others", { required: useCases.includes("Others") })}
              error={!!errors?.others}
              helperText={errors?.others?.messages}
              placeholder="Please enter your use case"
            />
          </Stack>
        )}
        <Stack spacing={1}>
          <Typography fontWeight="500">Password*</Typography>
          <TextField
            {...register("password", { required: true })}
            error={!!errors?.password}
            helperText={errors?.password?.messages}
            type="password"
            placeholder="Please enter a strong password"
          />
        </Stack>
        <Stack>
          {referralApplied ? (
            <Typography>Applied code: {watch("referralCode")}</Typography>
          ) : (
            <Button variant={"text"} onClick={openDialog} sx={{ width: "max-content" }}>
              <Typography fontWeight="500" width={"max-content"}>
                Apply Referral code
              </Typography>
            </Button>
          )}
        </Stack>
        <Dialog TransitionComponent={TransitionBottomUp} open={open}>
          <DialogContent sx={{ minWidth: 440 }}>
            <Grid container justifyContent="space-between">
              <Grid item xs={11}>
                <Typography fontWeight="500">Enter Referral code</Typography>
                <Spacing spacing={1} />
                <TextField
                  {...register("referralCode", { required: false })}
                  placeholder="Enter referral code"
                  onChange={(e) => setValue("referralCode", e?.target?.value)}
                />
                <Button
                  onClick={() => {
                    handleDialogClose();
                    setReferralApplied(true);
                  }}
                >
                  Apply
                </Button>
              </Grid>
              <Grid item>
                <IconButton onClick={handleDialogClose}>
                  <img src={CloseIcon} alt="close" />
                </IconButton>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <div>
          <LoadingButton loading={isSubmitting} type="submit">
            Create account
          </LoadingButton>
        </div>
        <Typography>
          Have an account already?{" "}
          <Link component={RouterLink} to={signIn()}>
            Sign in
          </Link>
        </Typography>
      </Stack>
    </form>
  );
}

export default SignUp;
