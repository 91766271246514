import React from "react";
import { Container, Grid, ListItem, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CopyApiKey from "../auth/CopyApiKey";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ArrowOutward, DoubleArrow } from "@mui/icons-material";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { topAPI } from "./apiConfig";
import useUser from "../user/useUser";
import Spacing from "../common/Spacing";

const API = () => {
  const { isVideoCallCampaignIsComplete } = useUser();
  const linkStyle = {
    "&:hover": {
      textDecoration: "underline",
      textDecorationThickness: "0.5px",
    },
  };
  const cardStyle = { pt: 2, height: "100%", border: "0.5px solid #0B0F15" };
  return (
    <Container maxWidth={"xl"} component={"main"}>
      <Card
        sx={{
          background: "linear-gradient(140deg, #82EDC6 0%, #2EB885 50%, #2FEBDB 75%)",
          margin: "auto",
          padding: "2px",
        }}
      >
        <CardContent sx={{ background: "#0B0F15", borderRadius: "16px" }}>
          <Stack sx={{ padding: 3 }} direction={"column"}>
            {isVideoCallCampaignIsComplete ? (
              <>
                <Typography fontWeight={600} fontSize={"28px"} align={"left"} color={"#82EDC6"}>
                  Top APIs
                </Typography>
                <Typography fontWeight={500} fontSize={"20px"}>
                  The easiest way to query Blockchain data from 20+ chains including Ethereum, BSC,
                  Polygon
                </Typography>
              </>
            ) : (
              <>
                <Typography fontWeight={500} fontSize={"20px"}>
                  We would like to understand your requirements better and guide you through the
                  process. Click the "Join Call" button below to connect with us.
                </Typography>
                <Spacing spacing={1} />
                <Typography fontWeight={400} fontSize={"18px"}>
                  One of our team member will be available on call to answer your queries
                </Typography>
              </>
            )}
            <br />
            <CopyApiKey />
          </Stack>
        </CardContent>
      </Card>
      <Grid container justifyContent={"center"} sx={{ mt: 5, mb: 5 }} spacing={4}>
        {topAPI.map((card) => (
          <Grid key={card.name} item xs={10} md={6}>
            <Card
              sx={{
                ...cardStyle,
                "&:hover": {
                  border: `0.5px solid ${card.color}`,
                },
              }}
            >
              <Typography align={"center"} variant="h5" component="h2" color={card.color}>
                {card.name}
              </Typography>
              <CardContent
                sx={{
                  height: "95%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <List>
                  {card.apis.map((api) => (
                    <ListItem key={api.name}>
                      <ListItemIcon>
                        <DoubleArrow sx={{ color: card.color }} />
                      </ListItemIcon>
                      <a
                        href={api.link}
                        target={"_blank"}
                        rel="noreferrer"
                        onClick={() => {
                          Mixpanel.track(MixpanelEvents.ExternalNavigation, {
                            to: "API Documentation",
                            source: card.name,
                          });
                        }}
                      >
                        <Typography fontSize={"20px"} sx={linkStyle}>
                          {api.name}
                        </Typography>
                      </a>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default API;
