import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import { Stack, TextField, Typography, Card, CardContent } from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import useRestClient from "../../common/rest-client/RestClient";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";

interface Request {
  address: string;
  amount: number;
}

const FaucetForm = ({ id }: any) => {
  const { handleSubmit, register } = useForm<Request>({
    defaultValues: {
      address: "",
      amount: 0,
    },
  });
  const { post } = useRestClient();

  const onSubmit: SubmitHandler<Request> = (data) => {
    console.log("data:", data);
    post(`/cosmos/network/${id}/faucetSend`, {
      data,
      onSuccess: () => {
        toast.success("Successfully sent request");
      },
      onError: () => toast.error("Failed to sent request"),
    });
  };

  return (
    <Stack justifyContent={"center"} alignItems="center" width="100%" height="600px">
      <Card variant="outlined" sx={{ width: 600 }}>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <TextField {...register("address")} label="Enter address" />
              <TextField {...register("amount")} label="Enter amount" />
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Stack>
  );
};

const Faucet = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useCosmosNetworkByID();

  useEffect(() => {
    getIndexerById(indexerID);
    const interval = setInterval(() => {}, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <Typography variant="h3">{indexerDetails.data?.name} Faucet</Typography>
      <FaucetForm id={id}></FaucetForm>
    </APIDataLoader>
  );
};

export default Faucet;
