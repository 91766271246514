import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";
import Spacing from "../common/Spacing";

function PaymentWaitingDialog({
  open,
  amount,
  symbol,
}: {
  open: boolean;
  amount: number;
  symbol: string;
}) {
  const isCreatingOrder = !amount;
  return (
    <Dialog open={open}>
      <DialogContent sx={{ minWidth: 300 }}>
        {isCreatingOrder ? (
          <Stack alignItems="center" spacing={0.5}>
            <CircularProgress size={75} />
            <Spacing spacing={2} />
            <Typography fontWeight="bold" color="#C2C3C5" variant="h6">
              Creating order
            </Typography>
            <Spacing spacing={1} />
          </Stack>
        ) : (
          <Stack alignItems="center" spacing={0.5}>
            <CircularProgress size={75} />
            <Spacing spacing={2} />
            <Typography fontWeight="bold" color="#C2C3C5" variant="h6">
              Waiting for confirmation
            </Typography>
            <Spacing spacing={1} />
            <Typography fontWeight="bold" variant="subtitle1">
              Verifying payment of {amount} {symbol}
            </Typography>
            <Typography color="#85878A">Confirm this transaction in your wallet</Typography>
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export default PaymentWaitingDialog;
