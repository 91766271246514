import React, { useEffect } from "react";
import Card from "@mui/material/Card";
import { Container, Grid, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { capitalize, isEmpty } from "lodash";
import { format } from "date-fns";
import Box from "@mui/material/Box";
import { Order } from "./OrdersTypes";
import usePayments from "./Payments";
import CopyApiKey from "../auth/CopyApiKey";

function PaymentHistory() {
  const { getOrders, orders } = usePayments();

  useEffect(getOrders, []);

  if (orders.isSuccess) {
    return (
      <Container maxWidth={"xl"}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontWeight="bolder" variant="h4">
            Payment History
          </Typography>
          <CopyApiKey />
        </Stack>
        <Stack spacing={2}>
          {isEmpty(orders.data) && (
            <Typography textAlign={"center"} variant={"h5"}>
              No Payments made. Upgrade to a new plan for to view Payment history.
            </Typography>
          )}
          {(orders.data || []).map((order: Order) => (
            <Card key={order.ID} variant="outlined">
              <CardContent>
                <Grid container spacing={5} justifyContent="space-around" alignItems={"center"}>
                  <Grid item xs={3}>
                    <Typography align="center">
                      {format(new Date(order.CreatedAt), "dd LLL yyyy")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="center">
                      {`${
                        isNaN(Math.round(order?.net_paid_amount * 100) / 100)
                          ? 0
                          : (Math.round(order?.net_paid_amount * 100) / 100).toFixed(2)
                      }
                ${order?.payable_currency?.token_symbol}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" justifyContent="center">
                      <Typography
                        align="center"
                        sx={{
                          background: (theme) =>
                            order.status === "fulfilled"
                              ? theme.palette.primary.main
                              : "rgb(255, 199, 0)",
                          px: 2,
                          py: 1,
                          borderRadius: 8,
                          // maxWidth: 100,
                        }}
                        fontWeight="bold"
                      >
                        {capitalize(order.status)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography align="center">
                      {`${capitalize(order?.tenure?.plan?.name)} plan ${
                        order?.tenure?.tenure
                      } subscription`}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
        </Stack>
      </Container>
    );
  }

  return <div>Loading</div>;
}

export default PaymentHistory;
