import React, { useEffect } from "react";
import { Amplify } from "aws-amplify";
import awsConfig from "./config";

function AuthProvider() {
  useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  return <></>;
}

export default AuthProvider;
