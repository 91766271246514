import React, { useState } from "react";
import Container from "@mui/material/Container";
import Spacing from "../../common/Spacing";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toIndexer, toParserOverview } from "../../common/Routes";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import useRestClient from "../../common/rest-client/RestClient";
import { toast } from "react-toastify";
import useUser from "../../user/useUser";
import { AxiosResponse } from "axios";
import LoadingButton from "@mui/lab/LoadingButton";

export interface NewCosmosChainConfig {
  chainName: string;
  bech32: string;
  denom: string;
  binaryName: string;
  defaultModule: string[];
  githubUser: string;
  validatorsCount: number;
  genesisAccounts: GenesisAccount[];
}

export interface GenesisAccount {
  name: string;
  address: string;
  amount: string;
}

const ConsmostForm = () => {
  const navigate = useNavigate();
  const { userName } = useUser();
  const [isLoading, setLoading] = useState(false);
  const { handleSubmit, register, control } = useForm<NewCosmosChainConfig>({
    defaultValues: {
      chainName: "",
      bech32: "",
      denom: "",
      binaryName: "",
      defaultModule: [],
      githubUser: "",
      genesisAccounts: [],
      validatorsCount: 5,
    },
  });
  const { post } = useRestClient();

  const { fields, append } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "genesisAccounts", // unique name for your Field Array
  });

  const onSubmit: SubmitHandler<NewCosmosChainConfig> = (data) => {
    console.log("data:", data);
    setLoading(true);
    post("/cosmos/create-and-start", {
      data: {
        userName,
        ...data,
      },
      onSuccess: (resp: AxiosResponse) => {
        toast.success("Successfully sent request deploy new chain");
        navigate(toParserOverview(resp.data));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.error || "Failed to sent request deploy new chain");
      },
      finally: () => {
        setLoading(false);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <TextField {...register("chainName")} label="Please enter chain name" />
        <TextField {...register("bech32")} label="Please enter beck32" />
        <TextField {...register("denom")} label="Please enter denom" />
        <TextField {...register("binaryName")} label="Please enter binanry name" />
        <TextField {...register("githubUser")} label="Please enter your github user name" />
        <TextField
          {...register("validatorsCount")}
          label="Please enter validators count"
          type="number"
        />
        <Autocomplete
          multiple
          disableCloseOnSelect
          id="tags-standard"
          options={[
            "proof-of-authority",
            "tokenfactory",
            "globalfee",
            "ibc-packetforward",
            "cosmwasm",
            "wasm-light-client",
            "ignite-cli",
          ]}
          {...register("defaultModule")}
          renderInput={(params) => <TextField {...params} label="Please select modules" />}
        />

        {/* Field Array for Genesis Accounts */}
        <Stack spacing={1}>
          <Stack direction={"row"} justifyContent="right">
            <Button
              variant="text"
              onClick={() =>
                append({
                  name: "",
                  address: "",
                  amount: "",
                })
              }
            >
              Add Genesis Account
            </Button>
          </Stack>
          {fields.map((account, index) => (
            <Card variant="outlined" key={index}>
              <Stack key={index} spacing={2}>
                <Typography>[{index + 1}]. Genesis Account</Typography>
                <TextField
                  {...register(`genesisAccounts.${index}.name`)}
                  label="Genesis Account Name"
                />
                <TextField
                  {...register(`genesisAccounts.${index}.address`)}
                  label=" Genesis Account Address"
                />
                <TextField
                  {...register(`genesisAccounts.${index}.amount`)}
                  label="Genesis Account Amount"
                />
              </Stack>
            </Card>
          ))}
        </Stack>
        <LoadingButton variant="contained" type="submit" loading={isLoading}>
          Bootstrap New Chain
        </LoadingButton>
      </Stack>
    </form>
  );
};

export default function ParserForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "Running";

  return (
    <Container maxWidth={"md"} sx={{ mt: 10 }}>
      <Card>
        <CardContent>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography>Bootstrap New Chain</Typography>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => {
                navigate(toIndexer(status));
              }}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Spacing spacing={3} />
          <Card variant={"outlined"}>
            <CardContent>
              <ConsmostForm />
            </CardContent>
          </Card>
          <Spacing spacing={2} />
        </CardContent>
      </Card>
    </Container>
  );
}
