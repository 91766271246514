import React from "react";
import { Chip, Container, Divider, Link, Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { secondaryColor } from "../theme/Theme";
import Button from "@mui/material/Button";
import { copyTextToClipboard } from "../common/clipboard/copy";
import { toast } from "react-toastify";
import CopyAll from "@mui/icons-material/CopyAll";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import APIs from "./DocumentationConfig";
import { ArrowOutward } from "@mui/icons-material";
import Spacing from "../common/Spacing";
import useUser from "../user/useUser";

const Path = ({ children }: React.PropsWithChildren<any>) => {
  return <span style={{ color: secondaryColor, fontWeight: "bold" }}>{children}</span>;
};

const Documentations = () => {
  const { apiKey } = useUser();
  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("cURL copied");
  };

  return (
    <Container maxWidth={"xl"} component={"main"}>
      <Stack spacing={2}>
        {APIs.map((api) => (
          <>
            <Typography color={"primary"} variant={"h4"}>
              {api.name}
            </Typography>
            <Typography fontSize={"medium"}>{api.description}</Typography>
            <Card color={"primary"} sx={{ width: "100%" }}>
              <CardContent>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Chip
                      color={"primary"}
                      label={
                        <Typography color={"white"} fontWeight={"bold"}>
                          GET
                        </Typography>
                      }
                      size={"small"}
                    />
                    <Typography variant={"h6"}>
                      {"https://api.unmarshal.com"}
                      <Path>{api.path}</Path>
                    </Typography>
                  </Stack>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      copyTextToClipboard(api.curl(apiKey), onCopy, () =>
                        toast.error("Failed to copy")
                      );
                    }}
                    endIcon={<CopyAll />}
                  >
                    Copy cURL
                  </Button>
                </Stack>
              </CardContent>
            </Card>
            <Stack spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant={"h6"} fontWeight={"bold"}>
                  Response:
                </Typography>
                <Button
                  component={Link}
                  href={api.docsLink}
                  variant={"contained"}
                  target={"_blank"}
                >
                  Docs
                </Button>
              </Stack>
              <Card
                variant={"outlined"}
                sx={{ width: "100%", height: api.height, overflow: "scroll" }}
              >
                <pre>
                  <code>{JSON.stringify(api.response, null, 2)}</code>
                </pre>
              </Card>
            </Stack>
            <Divider />
          </>
        ))}
        <a href={"https://docs.unmarshal.io"} target={"_blank"} rel="noreferrer">
          <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <Typography color={"primary"}>View full API suite </Typography>
            <ArrowOutward fontSize={"small"} color={"primary"} />
          </Stack>
        </a>
        <Spacing spacing={5} />
      </Stack>
    </Container>
  );
};

export default Documentations;
