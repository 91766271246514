import React, { useEffect } from "react";
import { useWalletConnect } from "../Wallet/Connections";
import Button from "@mui/material/Button";
import usePayments, { CreatedOrder, PayableToken, Tenure } from "./Payments";
import useERC20Contract from "./abis/TokenContract";
import { toast } from "react-toastify";
import PaymentWaitingDialog from "./PaymentWaitingDialog";
import useUser from "../user/useUser";
import { useNavigate } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import Typography from "@mui/material/Typography";
import { hexaToBigNumber } from "../common/numbers";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { myPlan } from "../common/Routes";

const isWaitingForPaymentConfirmationAtom = atom<boolean>({
  key: "IS_WAITING_FOR_PAYMENT_CONFIRMATION_ATOM",
  default: false,
});

const newOrderAtom = atom<Partial<CreatedOrder>>({
  key: "NEW_ORDER_ATOM",
  default: {},
});

const PurchasePlanButton = ({
  tenure,
  currency,
  tokenSymbol,
  promoCode,
  netAmount,
  plan,
}: {
  tenure: Tenure;
  currency: PayableToken;
  netAmount: number;
  tokenSymbol: string;
  promoCode: string;
  plan: string;
}) => {
  const [isWaitingForPaymentConfirmation, setPaymentConfirmation] = useRecoilState(
    isWaitingForPaymentConfirmationAtom
  );

  const [newOrder, updateOrder] = useRecoilState(newOrderAtom);
  const { openWalletConnectOptions, isConnected, userAddress } = useWalletConnect();
  const { linkWallet, placeOrder, updateTransactionHash } = usePayments();
  const { transfer, getBalance } = useERC20Contract();
  const navigate = useNavigate();
  const { getUserDetails } = useUser();

  const onPaymentComplete = () => {
    getUserDetails();
    setPaymentConfirmation(false);
    Mixpanel.track(MixpanelEvents.UpgradedPlan, {
      plan: plan,
      tenure: tenure.tenure,
      currency: currency,
    });
    Mixpanel.people.set({ Plan: plan });
    navigate("/");
  };

  function handleTransactionError(error: any) {
    setPaymentConfirmation(false);
    const errMsg = error.message;
    if (error.code === 4001) {
      toast.warn("User denied the transaction!");
      Mixpanel.track(MixpanelEvents.UserDeniedTransaction, {
        plan: plan,
        tenure: tenure.tenure,
        currency: currency,
      });
      return;
    }
    console.warn(error);
    Mixpanel.track(MixpanelEvents.TransactionFailed, {
      plan: plan,
      tenure: tenure.tenure,
      currency: currency,
    });
    toast.error(`Transaction Failed! ${errMsg || error?.error || ""}`);
  }

  const initiatePayment = async (order: CreatedOrder) => {
    updateOrder(order);
    getBalance(currency.token_address).then(async (balance: any) => {
      const tokenBalance = hexaToBigNumber(balance, currency.token_decimals as number);
      if (tokenBalance.toNumber() < order.net_paid_amount) {
        setPaymentConfirmation(false);
        toast.warn("Insufficient funds!");
        Mixpanel.track(MixpanelEvents.InsufficientFunds, {
          plan: plan,
          tenure: tenure.tenure,
          currency: currency,
        });
        return;
      }
      try {
        const trx = await transfer(
          order.recipient_address,
          order.net_paid_amount,
          currency.token_address,
          currency.token_decimals
        );
        updateTransactionHash(order.id, trx.hash);
        await trx.wait();
        toast.success(
          <Typography variant={"h6"} color={"primary"}>
            Payment successful!!, We will notify you once it is verified from our end.
          </Typography>
        );
        onPaymentComplete();
      } catch (e) {
        handleTransactionError(e);
      }
    });
  };

  const purchasePlan = () => {
    updateOrder({});
    if (promoCode === "LETSBUIDLIN2023" && netAmount === 0) {
      placeOrder(
        {
          tenure_id: tenure.id,
          wallet_address: userAddress as string,
          currency: currency.token_address,
          chain: currency.chain_name,
          promo_code: promoCode,
        },
        () => {
          toast.success("Upgraded plan successfully");
          getUserDetails();
          navigate(myPlan());
        },
        () => {}
      );
      return;
    }
    setPaymentConfirmation(true);
    placeOrder(
      {
        tenure_id: tenure.id,
        wallet_address: userAddress as string,
        currency: currency.token_address,
        chain: currency.chain_name,
        promo_code: promoCode,
      },
      initiatePayment,
      () => setPaymentConfirmation(false)
    );
  };

  useEffect(() => {
    if (isConnected && userAddress) {
      linkWallet(userAddress);
    }
  }, [isConnected, userAddress]);

  if (isConnected) {
    return (
      <>
        <Button
          variant="contained"
          sx={{ fontWeight: "bold" }}
          onClick={() => {
            purchasePlan();
            Mixpanel.track(MixpanelEvents.PlanPurchaseAttempt, {
              plan: plan,
              tenure: tenure.tenure,
              currency: currency,
            });
          }}
        >
          Purchase Plan
        </Button>
        <PaymentWaitingDialog
          open={isWaitingForPaymentConfirmation}
          amount={newOrder?.net_paid_amount || 0}
          symbol={tokenSymbol}
        />
      </>
    );
  }
  return (
    <Button variant="contained" sx={{ fontWeight: "bold" }} onClick={openWalletConnectOptions}>
      Connect wallet
    </Button>
  );
};

export default PurchasePlanButton;
