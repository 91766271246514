import React, { useState } from "react";
import { Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import LoadingButton from "@mui/lab/LoadingButton";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import ArrowLeftGreen from "../../assets/icons/ArrowLeftGreen.svg";
import { signIn } from "../common/Routes";

function RequestResetPassword({ onSubmit }: { onSubmit: any }) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography fontWeight={500} fontSize="1.5rem">
          Reset your password
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight="500">Username / email id*</Typography>
          <TextField
            {...register("userName", { required: true })}
            error={!!errors?.userName}
            placeholder="Enter your username / email id"
            autoFocus
          />
        </Stack>
        <div>
          <LoadingButton loading={isSubmitting} type="submit">
            Send OTP
          </LoadingButton>
        </div>
        <div>
          <Button fullWidth={false} component={RouterLink} to="/auth/sign-in">
            <img src={ArrowLeftGreen} alt="" />
            <Typography sx={{ ml: 2 }} fontWeight={500}>
              Back to sign in
            </Typography>
          </Button>
        </div>
      </Stack>
    </form>
  );
}

function SubmitResetPassword({ userName }: { userName: string }) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = ({ password, code }: any) =>
    new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(userName, code, password)
        .then((data) => {
          toast.success("Reset password complete!");
          navigate(signIn());
          resolve(data);
        })
        .catch((error) => {
          toast.error(error?.message);
          return reject(error);
        });
    });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography fontWeight={500} fontSize="1.5rem">
          Reset your password
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight="500">Verification code*</Typography>
          <TextField
            {...register("code", { required: true })}
            error={!!errors?.code}
            placeholder="Please enter verification code"
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="500">Password*</Typography>
          <TextField
            {...register("password", { required: true })}
            type="password"
            error={!!errors?.password}
            placeholder="Please enter a strong password"
          />
        </Stack>
        <div>
          <LoadingButton loading={isSubmitting} type="submit">
            Submit
          </LoadingButton>
        </div>
        <div>
          <Button fullWidth={false} component={RouterLink} to="/auth/sign-in">
            <img src={ArrowLeftGreen} alt="" />
            <Typography sx={{ ml: 2 }} fontWeight={500}>
              Back to sign in
            </Typography>
          </Button>
        </div>
      </Stack>
    </form>
  );
}

function ResetPassword() {
  const [isRequested, setRequested] = useState(false);
  const [userName, setUserName] = useState("");
  const onSubmit = ({ userName }: any) =>
    new Promise((resolve, reject) => {
      Auth.forgotPassword(userName)
        .then((data) => {
          setRequested(true);
          setUserName(userName);
          resolve(data);
        })
        .catch((error) => {
          toast.error(error?.message);
          return reject(error);
        });
    });
  if (!isRequested) {
    return <RequestResetPassword onSubmit={onSubmit} />;
  }
  return <SubmitResetPassword userName={userName} />;
}

export default ResetPassword;
