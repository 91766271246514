import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { KeyValue } from "../details/IndexerOverview";
import { Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Spacing from "../../common/Spacing";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import useGetRequest from "../../common/rest-client/get";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";

export interface Root {
  GeneralConfig: GeneralConfig;
  BlockConfig: BlockConfig;
  TransactionConfig: TransactionConfig;
  AdditionalConfig: AdditionalConfig;
}

export interface GeneralConfig {
  chainID: string;
  gasPrices: string;
  blockTime: string;
  consensusAlgorithm: string;
  IBCConfig: Ibcconfig;
}

export interface Ibcconfig {
  enabled: boolean;
  transferTimeout: number;
}

export interface BlockConfig {
  maxBlockSize: string;
  blockGossipingConfig: BlockGossipingConfig;
  timeouts: Timeouts;
  evidenceAge: string;
}

export interface BlockGossipingConfig {
  blockPartSizeBytes: number;
}

export interface Timeouts {
  propose: number;
  proposeDelta: number;
  vote: number;
  voteDelta: number;
  commit: number;
}

export interface TransactionConfig {
  gasLimit: number;
  txTimeoutHeight: number;
  memoSizeLimit: number;
  signaturesPerTransaction: number;
}

export interface AdditionalConfig {
  validatorSet: ValidatorSet;
}

export interface ValidatorSet {
  maxValidators: number;
  joinThreshold: number;
}

const stateAtom = atom<APIData<Root>>({
  key: "nodeConfigs",
  default: DefaultAPIDataValues,
});

export const useNodeConfigsID = () => {
  const [nodeConfigs, updateNodeConfig] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getNodeConfigs = (id: string) => {
    indexerFetcher<Root>(updateNodeConfig, `/cosmos/network/${id}/nodeConfigs`);
  };

  return { nodeConfigs, getNodeConfigs, reset };
};

const BlockChainConfig = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById, reset } = useCosmosNetworkByID();
  const { nodeConfigs, getNodeConfigs } = useNodeConfigsID();

  useEffect(() => {
    getIndexerById(indexerID);
    getNodeConfigs(indexerID);
    const interval = setInterval(() => {
      getNodeConfigs(indexerID);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <APIDataLoader {...nodeConfigs}>
        <Stack spacing={3}>
          <Typography variant="h3">{indexerDetails.data?.name}</Typography>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h4">General Config</Typography>
              <Spacing spacing={2} />
              <Grid container spacing={2}>
                <KeyValue label="Chain ID" value={nodeConfigs.data?.GeneralConfig?.chainID} />
                <KeyValue label="Gas Price" value={nodeConfigs.data?.GeneralConfig?.gasPrices} />
                <KeyValue label="Block Time" value={nodeConfigs.data?.GeneralConfig?.blockTime} />
                <KeyValue
                  label="Consensus Algorithm"
                  value={nodeConfigs.data?.GeneralConfig?.consensusAlgorithm}
                />
                <KeyValue
                  label="IBC Config Enabled?"
                  value={
                    nodeConfigs.data?.GeneralConfig?.IBCConfig?.enabled ? "Enabled" : "Disabled"
                  }
                />
                <KeyValue
                  label="IBC Config Transfer Timeout"
                  value={nodeConfigs.data?.GeneralConfig?.IBCConfig?.transferTimeout}
                />
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h4">Block Config</Typography>
              <Spacing spacing={2} />
              <Grid container spacing={2}>
                <KeyValue label="Block Commit Deadline (ms)" value={indexerDetails.data?.name} />
                <KeyValue
                  label="Mini Block Commit Deadline (ms)"
                  value={indexerDetails.data?.networkType}
                />
                <KeyValue
                  label="Mini Block Seal Queue Capacity"
                  value={indexerDetails.data?.name}
                />
                <KeyValue
                  label="Close Block at Geometry (%)"
                  value={indexerDetails.data?.chainId}
                />
                <KeyValue label="Close Block at ETH Params (%)" value={indexerDetails.data?.name} />
                <KeyValue label="Close Block at Gas (%)" value={indexerDetails.data?.chainId} />
                <KeyValue
                  label="Virtual Blocks Interval"
                  value={indexerDetails.data?.networkType}
                />
                <KeyValue
                  label="Virtual Blocks / Mini Block"
                  value={indexerDetails.data?.networkType}
                />
              </Grid>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="h4">Transaction Config</Typography>
              <Spacing spacing={2} />
              <Grid container spacing={2}>
                <KeyValue label="Gas Limit" value={nodeConfigs.data?.TransactionConfig?.gasLimit} />
                <KeyValue
                  label="Transaction Timeout Height"
                  value={nodeConfigs.data?.TransactionConfig?.memoSizeLimit}
                />
                <KeyValue
                  label="Memo Size Limit"
                  value={nodeConfigs.data?.TransactionConfig?.memoSizeLimit}
                />
                <KeyValue
                  label="Signatures Per Transaction"
                  value={nodeConfigs.data?.TransactionConfig?.signaturesPerTransaction}
                />
              </Grid>
            </CardContent>
          </Card>
        </Stack>
      </APIDataLoader>
    </APIDataLoader>
  );
};

export default BlockChainConfig;
