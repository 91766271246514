import React, { useMemo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { QueryResponse } from "./DataExplorerClient";
import MuiLink from "@mui/material/Link";
import { wrapMiddle } from "../../common/string/format";
import Copy from "../../common/clipboard/copy";

interface DataViewerProps {
  data: QueryResponse;
  loading: boolean;
  pageSize?: number;
}

function metabaseResponseToDataGridData(responseData: QueryResponse) {
  const data = {
    cols: responseData?.data?.cols || [],
    rows: responseData?.data?.rows || [],
  };
  return data.rows.map((row, index) => {
    const rowObject: any = {};
    row.forEach((data, index) => {
      rowObject[String(index)] = data;
    });
    return { ...rowObject, custom_row_id: index };
  });
}

const DataViewer = ({ data: responseData, loading, pageSize = 10 }: DataViewerProps) => {
  const data = {
    cols: responseData?.data?.cols || [],
    rows: responseData?.data?.rows || [],
  };
  const columns = useMemo(() => {
    return [
      ...data.cols.map((column, index) => ({
        field: String(index),
        headerName: column.display_name,
        width: 200,
        renderCell: (params: any) => {
          if (column.display_name === "tx_hash") {
            return (
              <Copy content={params.value} contentType={"transaction hash"} source={"custom query"}>
                <MuiLink
                  href={`https://xscan.io/transactions/${params.value}`}
                  target={"_blank"}
                  style={{ textDecoration: "underline", color: "#ffffff" }}
                >
                  {wrapMiddle(params.value)}
                </MuiLink>
              </Copy>
            );
          }
          return params.value;
        },
      })),
      { field: "custom_row_id", hide: true },
    ];
  }, [responseData]);

  const parsedRows = useMemo(() => {
    return metabaseResponseToDataGridData(responseData);
  }, [responseData]);

  return (
    <div style={{ minHeight: 300, maxHeight: "max-content", width: "100%" }}>
      <DataGrid
        rows={parsedRows}
        columns={columns}
        loading={loading}
        getRowId={(row) => row?.custom_row_id as any}
        isRowSelectable={() => false}
        disableExtendRowFullWidth={false}
        autoHeight
        pageSize={pageSize}
      />
    </div>
  );
};

export default DataViewer;
