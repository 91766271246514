import { atom, useRecoilState, useResetRecoilState } from "recoil";
import useGetRequest from "../../common/rest-client/get";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import { Template } from "./types";
import useRestClient from "../../common/rest-client/RestClient";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

export interface MetabaseDetails {
  password: string;
  url: string;
  email: string;
}

const MetabaseAtom = atom<APIData<MetabaseDetails>>({
  key: "METABASE_DETAILS",
  default: DefaultAPIDataValues,
});

const RefreshMetabaseAtom = atom<APIData<boolean>>({
  key: "REFRESH_METABASE",
  default: DefaultAPIDataValues,
});

const templatesAtom = atom<APIData<Array<Template>>>({
  key: "METABASE_TEMPLATES_ATOM",
  default: DefaultAPIDataValues,
});

const userSchemasAtom = atom<APIData<Array<string>>>({
  key: "USER_SCHEMAS_ATOM",
  default: DefaultAPIDataValues,
});

export interface CloneHistory {
  id: number;
  request_id: string;
  template_id: number;
  template: Template;
  user_name: string;
  collection_name: string;
  schema_name: string;
  status: string;
  error: string;
}

const userCloneHistoryAtom = atom<APIData<Array<CloneHistory>>>({
  key: "USER_CLONE_HISTORY_ATOM",
  default: DefaultAPIDataValues,
});

export const useMetabaseCollections = () => {
  const [templates, updateTemplates] = useRecoilState(templatesAtom);
  const [schemas, updateSchemas] = useRecoilState(userSchemasAtom);
  const [cloneHistory, updateCloneHistory] = useRecoilState(userCloneHistoryAtom);
  const { post } = useRestClient();

  const get = useGetRequest();

  const getTemplates = () => {
    get<Array<Template>>(updateTemplates, `/cis/v1/metabase-template/collections/templates`);
  };

  const getCloneHistory = () => {
    get<Array<CloneHistory>>(
      updateCloneHistory,
      `/cis/v1/metabase-template/collections/clone-history`
    );
  };

  const getSchemas = () => {
    get<Array<string>>(updateSchemas, `/cis/v1/indexer/schemas`);
  };

  const addCollection = (data: any, onComplete: Function, onError: Function) => {
    post("/cis/v1/metabase-template/collections", {
      data: data,
      onSuccess: ({ data }: AxiosResponse<any>) => {
        toast.success(data?.message || "Dashboard creation in progress!");
        onComplete();
      },
      onError: () => {
        toast.error("Failed to add dashboard!");
        onError();
      },
    });
  };

  return {
    getSchemas,
    getTemplates,
    schemas,
    templates,
    addCollection,
    cloneHistory,
    getCloneHistory,
  };
};

const useMetabaseDetails = () => {
  const [metabaseDetails, updateMetabaseDetails] = useRecoilState(MetabaseAtom);
  const resetState = useResetRecoilState(MetabaseAtom);

  const get = useGetRequest();

  const getMetabaseDetails = () => {
    get<MetabaseDetails>(updateMetabaseDetails, `/cis/v1/metabase/details`);
  };

  const [refresh, updateRefreshMetabase] = useRecoilState(RefreshMetabaseAtom);
  const refreshMetabase = () => {
    get<boolean>(updateRefreshMetabase, `/cis/v1/metabase/refresh`, {});
  };
  return {
    metabaseDetails,
    getMetabaseDetails,
    resetState,
    refresh,
    refreshMetabase,
  };
};

export default useMetabaseDetails;
