import React from "react";
import List from "@mui/material/List";
import { first, get, isEmpty, last } from "lodash";
import { Divider, Link, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Spacing from "../../common/Spacing";
import { ExtendedIndexerDetails } from "../IndexerDetailsClient";
import LoadingIndicator, { ErrorIndicator } from "./LoadingIndicator";

interface TableProps {
  isLoading: boolean;
  hasError: boolean;
  data: Array<any>;
  columns?: Array<string>;
  indexer?: ExtendedIndexerDetails;
  render?: (value: string, indexer?: ExtendedIndexerDetails) => string;
}

const Table = ({ data, columns, render, indexer, isLoading, hasError }: TableProps) => {
  if (isLoading) return <LoadingIndicator />;
  if (hasError || isEmpty(data)) return <ErrorIndicator />;
  return (
    <List
      sx={{
        height: "300px",
        overflow: "scroll",
      }}
    >
      {(data || []).map((item: any) => {
        const firstColumn: string = first(columns) || "";
        const secondColumn: string = last(columns) || "";
        const firstColumnValue = get(item, firstColumn);
        const secondColumnValue = get(item, secondColumn);
        const typographyProps = !!render
          ? {}
          : {
              component: Link,
              href: `https://xscan.io/search?q=${firstColumnValue}`,
              target: "_blank",
            };
        return (
          <>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography {...typographyProps} color={"primary"}>
                {!!render ? render(firstColumnValue, indexer) : firstColumnValue}
              </Typography>
              <Typography>{secondColumnValue}</Typography>
            </Stack>
            <Spacing spacing={1} />
            <Divider />
            <Spacing spacing={1} />
          </>
        );
      })}
    </List>
  );
};
export default Table;
