import { InjectedConnector } from "@web3-react/injected-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { ethers } from "ethers";
import { clearLocalStorage } from "../common/clearLocalStorage";

const USER_PREFERRED_WALLET = "USER_PREFERRED_WALLET";
export const POLLING_INTERVAL = 9000;

export enum Wallets {
  Metamask = "METAMASK",
  BscChain = "BSC_CHAIN",
  WalletConnect = "WALLET_CONNECT",
}

declare global {
  interface Window {
    ethereum: any;
    BinanceChain: any;
  }
}
const useWalletConnector = (chainId: number, jRpcURL: string) => {
  const supportedChainIds = [chainId];

  const bscConnector = new BscConnector({ supportedChainIds });

  const metamaskConnector = new InjectedConnector({ supportedChainIds });

  const walletConnector = new WalletConnectConnector({
    rpc: { [chainId]: jRpcURL },
    bridge: "https://bridge.walletconnect.org",
    qrcode: true,
    // pollingInterval: POLLING_INTERVAL,
  });

  const WalletsConnectors: Map<string, any> = new Map<string, any>([
    [Wallets.Metamask, metamaskConnector],
    [Wallets.BscChain, bscConnector],
    [Wallets.WalletConnect, walletConnector],
  ]);

  const WalletsProviders: Map<string, () => any> = new Map<string, () => any>([
    [Wallets.Metamask, () => window?.ethereum],
    [Wallets.BscChain, () => window?.BinanceChain],
    [Wallets.WalletConnect, () => undefined],
  ]);

  const switchProviderNetwork = async (wallet: Wallets) => {
    const getProvider = WalletsProviders.get(wallet);
    const provider = getProvider ? getProvider() : undefined;
    if (provider) {
      try {
        provider.request &&
          (await provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: ethers.utils.hexlify(chainId),
                chainName: "Binance Smart Chain Mainnet",
                nativeCurrency: {
                  name: "BNB",
                  symbol: "bnb",
                  decimals: 18,
                },
                rpcUrls: [jRpcURL],
                blockExplorerUrls: ["https://bscscan.com"],
              },
            ],
          }));
        return true;
      } catch (error) {
        console.error("Failed to setup the network in Metamask:", error);
        return false;
      }
    } else {
      console.error("Can't setup the BSC network on metamask because wallet provider is undefined");
      return false;
    }
  };

  const getPreferredWalletConnector = () => {
    const preferredWallet: any = localStorage.getItem(USER_PREFERRED_WALLET);
    return WalletsConnectors.get(preferredWallet);
  };

  const setPreferredWallet = (wallet: Wallets) => {
    localStorage.setItem(USER_PREFERRED_WALLET, wallet);
  };

  const handleWalletConnectionReject = () => {
    if (localStorage.getItem(USER_PREFERRED_WALLET) === Wallets.WalletConnect) {
      window.location.reload();
    }
  };

  const clearPreferredWallet = () => {
    clearLocalStorage();
  };

  const switchNetwork = () => {
    const wallet: any = localStorage.getItem(USER_PREFERRED_WALLET);
    return switchProviderNetwork(wallet);
  };

  const disconnectWallet = () => {
    const preferredWalletConnector = getPreferredWalletConnector();
    preferredWalletConnector.deactivate();
    getPreferredWalletConnector()?.close && getPreferredWalletConnector()?.close();
    if (preferredWalletConnector instanceof WalletConnectConnector) {
      walletConnector.walletConnectProvider = undefined;
    }
    clearPreferredWallet();
  };

  return {
    setPreferredWallet,
    getPreferredWalletConnector,
    handleWalletConnectionReject,
    clearPreferredWallet,
    switchNetwork,
    disconnectWallet,
  };
};

export default useWalletConnector;
