import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { atom, useRecoilState } from "recoil";
import Typography from "@mui/material/Typography";
import { Divider, Link, List, ListItem, useMediaQuery, useTheme } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import metamaskLogo from "../../assets/logos/Metamask.svg";
import binanceLogo from "../../assets/logos/Binance.svg";
import walletConnectLogo from "../../assets/logos/WalletConnect.svg";
import trustWalletLogo from "../../assets/logos/TrustWallet.svg";
import { useWalletConnect } from "./Connections";
import { Wallets } from "./Connectors";
import CloseButton from "../common/CloseButton";
import TransitionBottomUp from "../common/annimations/TransitionBottomUp";

function WalletsList() {
  const { connectWallet } = useWalletConnect();
  return (
    <List>
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.Metamask)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={metamaskLogo} alt="" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight="bold">
              Metamask
            </Typography>
          }
          secondary={
            <Typography color="text.disabled" fontWeight="bold" sx={{ opacity: "70%" }}>
              Connect using browser wallet
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.BscChain)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={binanceLogo} alt="" />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight="bold">
              Binance Chain
            </Typography>
          }
          secondary={
            <Typography color="text.disabled" fontWeight="bold" sx={{ opacity: "70%" }}>
              Connect using browser wallet
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.Metamask)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={trustWalletLogo} alt="" width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight="bold">
              Trust Wallet
            </Typography>
          }
          secondary={
            <Typography color="text.disabled" fontWeight="bold" sx={{ opacity: "70%" }}>
              Connect using Trust Wallet
            </Typography>
          }
        />
      </ListItem>
      <Divider />
      <ListItem
        component={Button}
        onClick={() => connectWallet(Wallets.WalletConnect)}
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <KeyboardArrowRightIcon />
          </IconButton>
        }
      >
        <ListItemAvatar>
          <img src={walletConnectLogo} alt="" width={40} />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Typography color="text.primary" variant="h6" fontWeight="bold">
              Wallet Connect
            </Typography>
          }
          secondary={
            <Typography color="text.disabled" fontWeight="bold" sx={{ opacity: "70%" }}>
              Connect using Wallet Connect
            </Typography>
          }
        />
      </ListItem>
    </List>
  );
}

const IS_WALLETS_DIALOG_OPEN = "IS_WALLETS_DIALOG_OPEN";

const userMenuOpenState = atom<boolean>({
  key: IS_WALLETS_DIALOG_OPEN,
  default: false,
});

export const useWalletOptionsDialog = () => {
  const [isOpen, updateIsOpen] = useRecoilState(userMenuOpenState);
  return {
    isOpen,
    toggleMenu: () => updateIsOpen(!isOpen),
    closeDialog: () => updateIsOpen(false),
  };
};

export default function WalletOptionsDialog() {
  const { isOpen, closeDialog } = useWalletOptionsDialog();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="xs"
      open={isOpen}
      TransitionComponent={TransitionBottomUp}
      keepMounted
      onClose={closeDialog}
    >
      <DialogTitle>
        <CloseButton onClose={closeDialog} />
        <Typography variant="h5" fontWeight="bolder" textAlign="center">
          Login
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Card variant="outlined">
          <CardContent>
            <WalletsList />
          </CardContent>
        </Card>
        <Divider sx={{ my: 2 }} />
        <Typography textAlign="center">
          Don’t have a wallet?{" "}
          <Link target="_blank" href="https://metamask.io/download.html">
            Download here
          </Link>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
