import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Copy from "../common/clipboard/copy";
import Spacing from "../common/Spacing";
import React from "react";

const tsSteps = [
  {
    label: "Install Unmarshal",
    description: (
      <>
        <Typography>Using npm:</Typography>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>npm install @unmarshal/sdk</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy content={"npm install @unmarshal/sdk"} contentType={"code"} source={"sdk"}></Copy>
          </div>
        </Card>
        <Spacing spacing={1} />
        <Typography>Using yarn:</Typography>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>yarn add @unmarshal/sdk</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy content={"yarn add @unmarshal/sdk"} contentType={"code"} source={"sdk"}></Copy>
          </div>
        </Card>
      </>
    ),
  },
  {
    label: "Initialize Unmarshal",
    description: (
      <>
        <Typography>Import:</Typography>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`import Unmarshal, {Chain} from "@unmarshal/sdk";`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`import Unmarshal, {Chain} from "@unmarshal/sdk";`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
        <Spacing spacing={1} />
        <Typography>Initialize:</Typography>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`const unmarshal = new Unmarshal({
  apiKey: "YOUR_API_KEY_GOES_HERE"
});`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`const unmarshal = new Unmarshal({
  apiKey: "YOUR_API_KEY_GOES_HERE"
});`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
      </>
    ),
  },
  {
    label: "Make an API call",
    description: (
      <>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`unmarshal.WalletApi
  .getTokenBalances(Chain.ethereum, "demo.eth")
  .then(({data}) => console.log(data))`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`unmarshal.WalletApi
  .getTokenBalances(Chain.ethereum, "demo.eth")
  .then(({data}) => console.log(data))`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
      </>
    ),
  },
];

const goSteps = [
  {
    label: "'go get' unmarshal project",
    description: (
      <>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`go get github.com/eucrypt/unmarshal-go-sdk`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`go get github.com/eucrypt/unmarshal-go-sdk`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
      </>
    ),
  },
  {
    label: "Initialize Unmarshal object",
    description: (
      <>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`package main

import(
  unmarshal "github.com/eucrypt/unmarshal-go-sdk/pkg"
  conf "github.com/eucrypt/unmarshal-go-sdk/pkg/config"
  "github.com/eucrypt/unmarshal-go-sdk/pkg/constants"
)

func main() {
  sdk := unmarshal.NewWithConfig(conf.Config{
    AuthKey:     "YOUR_API_KEY_GOES_HERE",
    Environment: constants.Prod,
  })
}`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`package main

import(
  unmarshal "github.com/eucrypt/unmarshal-go-sdk/pkg"
  conf "github.com/eucrypt/unmarshal-go-sdk/pkg/config"
  "github.com/eucrypt/unmarshal-go-sdk/pkg/constants"
)

func main() {
  sdk := unmarshal.NewWithConfig(conf.Config{
    AuthKey:     "YOUR_API_KEY_GOES_HERE",
    Environment: constants.Prod,
  })
}`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
      </>
    ),
  },
  {
    label: "Query the sdk object",
    description: (
      <>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre>{`resp, err := sdk.GetTokenPriceBySymbol("marsh")`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              content={`resp, err := sdk.GetTokenPriceBySymbol("marsh")`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
        <Spacing spacing={1} />
        <Typography>Chain specific function:</Typography>
        <Card sx={{ position: "relative", padding: 1 }}>
          <pre
            style={{
              maxWidth: "95%",
              overflow: "scroll",
            }}
            // eslint-disable-next-line max-len
          >{`resp, err := sdk.GetTokenCurrentPrice(constants.BSC, "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256")`}</pre>
          <div style={{ position: "absolute", top: 20, right: "10px" }}>
            <Copy
              // eslint-disable-next-line max-len
              content={`resp, err := sdk.GetTokenCurrentPrice(constants.BSC, "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256")`}
              contentType={"code"}
              source={"sdk"}
            ></Copy>
          </div>
        </Card>
      </>
    ),
  },
];

export const sdkData = new Map<string, any>([
  [
    "ts",
    {
      steps: tsSteps,
      docs: "https://docs.unmarshal.io/reference/typescript-sdk",
    },
  ],
  [
    "go",
    {
      steps: goSteps,
      docs: "https://docs.unmarshal.io/reference/golang-sdk",
    },
  ],
]);
