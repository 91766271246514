import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Alert, alpha, Link as MUILink, Stack, ThemeProvider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import plans, { Plan } from "./Plans";
import Check from "../../assets/icons/Check.svg";
import Cross from "../../assets/icons/cross.svg";
import { PricingTheme } from "./PricingTheme";
import { signIn, upgradePlan } from "../common/Routes";
import useUser from "../user/useUser";
import { find, toLower, toUpper } from "lodash";
import usePayments from "./Payments";
import { Plan as UserPlan, Tenure } from "../user/types";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { API_WEIGHTAGES_DOCS_LINK, CONTACT_US_MAIL_TO_LINK } from "../../config";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

export type TenureType = "monthly" | "yearly";

function Feature({ feature, included = true }: { feature: string; included?: boolean }) {
  return (
    <Stack direction="row" spacing={2}>
      <img src={included ? Check : Cross} alt={included ? "Check" : "Uncheck"} />
      <Typography sx={{ opacity: 0.75 }}>{feature}</Typography>
    </Stack>
  );
}

export function PlanDetails({ plan }: { plan: Plan }) {
  return (
    <>
      <Stack spacing={1}>
        <Typography fontWeight="bold">Accessible APIs</Typography>
        <Box>
          {plan?.apis.map((api) => (
            <Feature key={api} feature={api} />
          ))}
        </Box>
      </Stack>
      <Stack spacing={1}>
        <Typography fontWeight="bold">Features</Typography>
        <Box>
          {plan?.includedFeatures.map((feature) => (
            <Feature key={feature} feature={feature} />
          ))}
          {plan?.excludedFeatures?.map((feature) => (
            <Feature key={feature} feature={feature} included={false} />
          ))}
        </Box>
      </Stack>
      {(plan?.customizedIntegration?.length || 0) > 0 && (
        <Stack spacing={1}>
          <Typography fontWeight="bold">Customized Integration</Typography>
          <Box>
            {plan?.customizedIntegration.map((feature) => (
              <Feature key={feature} feature={feature} />
            ))}
          </Box>
        </Stack>
      )}
    </>
  );
}

function UpgradeOptions() {
  const [tenureType, setTenureType] = React.useState<TenureType>("monthly");
  const { userDetails, isLoggedIn } = useUser();
  const { planList, getPlanList } = usePayments();
  const navigate = useNavigate();

  useEffect(getPlanList, []);

  function TenureToggleButtons() {
    return (
      <Paper variant="outlined" sx={{ p: 0.7, width: "fit-content" }}>
        <ThemeProvider theme={PricingTheme}>
          <Button
            variant={tenureType === "monthly" ? "contained" : "text"}
            onClick={() => setTenureType("monthly")}
          >
            Monthly billing
          </Button>
          <Button
            variant={tenureType === "yearly" ? "contained" : "text"}
            onClick={() => setTenureType("yearly")}
          >
            <Stack direction="row" spacing={1}>
              <div>Yearly billing</div>
              <Typography
                color={(theme) => theme.palette.primary.main}
                fontSize={10}
                component="text"
                fontWeight="bold"
                sx={{
                  p: 0.5,
                  background: (theme) => theme.palette.secondary.main,
                  borderRadius: 1,
                }}
              >
                SAVE 17%
              </Typography>
            </Stack>
          </Button>
        </ThemeProvider>
      </Paper>
    );
  }

  return (
    <APIDataLoader {...planList}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <TenureToggleButtons />
        <MUILink href={API_WEIGHTAGES_DOCS_LINK} underline={"none"} target={"_blank"}>
          <Alert severity="warning">Please click here to know more about API weightages</Alert>
        </MUILink>
      </Stack>
      <br />
      <Grid container spacing={2} justifyContent="flex-start">
        {(planList.data || []).map((usersPlan: UserPlan) => {
          const plan: Plan = plans.get(toUpper(usersPlan.name)) as Plan;
          const isSelectedPlan =
            toLower(usersPlan?.name) === toLower(userDetails.data?.current_plan?.name);

          const isFreePlan = toLower(usersPlan.name) === "free";
          const isEnterprisePlan = toLower(usersPlan.name) === "enterprise";
          const isBasicPlan = toLower(usersPlan.name) === "explore";

          const getTenureByName = (tenure: string): Tenure =>
            find(usersPlan.tenures, { tenure }) as Tenure;

          return (
            <Grid item key={plan?.name} xs={12} sm={6} md={4} display={"flex"}>
              <Card
                variant="outlined"
                sx={
                  isSelectedPlan
                    ? {
                        background: (theme) => alpha(theme.palette.primary.main, 0.2),
                      }
                    : {}
                }
              >
                <CardContent>
                  <Stack spacing={3}>
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      <Typography variant="h5" fontWeight="bold">
                        {plan?.name}
                      </Typography>
                    </Stack>
                    <Typography variant="body1" minHeight={70}>
                      {plan?.description}
                    </Typography>
                    <Typography variant="h5" fontWeight="bold" textAlign="center">
                      {isEnterprisePlan
                        ? "Let's talk"
                        : isFreePlan
                        ? "Free Trial"
                        : tenureType === "monthly"
                        ? `${getTenureByName(tenureType)?.price}$ worth of MARSH/mo`
                        : `${getTenureByName(tenureType)?.price}$ worth of MARSH/yr`}
                    </Typography>
                    {isEnterprisePlan ? (
                      <Button
                        LinkComponent={MUILink}
                        href={CONTACT_US_MAIL_TO_LINK}
                        target={"_blank"}
                        variant="contained"
                      >
                        Contact Us
                      </Button>
                    ) : isSelectedPlan ? (
                      isFreePlan ? (
                        <Button variant="outlined">Your current plan</Button>
                      ) : (
                        <Button
                          fullWidth
                          component={Link}
                          to={upgradePlan(usersPlan.id, getTenureByName(tenureType)?.id)}
                          variant="outlined"
                          onClick={() =>
                            Mixpanel.track(MixpanelEvents.UpgradePlanPage, {
                              plan: usersPlan.name,
                              tenure: tenureType,
                            })
                          }
                        >
                          Renew plan
                        </Button>
                      )
                    ) : isFreePlan ? (
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (!isLoggedIn) {
                            navigate(signIn());
                          }
                        }}
                      >
                        Try it for free
                      </Button>
                    ) : (
                      <div>
                        <Button
                          fullWidth
                          component={Link}
                          to={upgradePlan(usersPlan.id, getTenureByName(tenureType)?.id)}
                          variant="contained"
                          onClick={() => {
                            Mixpanel.track(MixpanelEvents.UpgradePlanPage, {
                              plan: usersPlan.id,
                              tenure: tenureType,
                            });
                          }}
                        >
                          Upgrade plan
                        </Button>
                      </div>
                    )}
                    <PlanDetails plan={plan} />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </APIDataLoader>
  );
}

export default UpgradeOptions;
