import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import { Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { capitalize, upperCase, upperFirst } from "lodash";
import Spacing from "../common/Spacing";
import { PlanDetails } from "./UpgradeOptions";
import plans, { Plan } from "./Plans";
import CopyApiKey from "../auth/CopyApiKey";
import useUser from "../user/useUser";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import Mixpanel from "../../MixpanelConfig";
import { useAuth } from "@aws-amplify/ui-react/internal";
import UpgradeOptions from "../payments/UpgradeOptions";

function CurrentPlan() {
  const { userDetails: userApiData, isOnFreePlan } = useUser();
  const user = userApiData.data;
  const auth = useAuth();

  useEffect(() => {
    Mixpanel.people.set_once({
      $name: auth?.user?.username,
      $email: user?.email,
      Company: auth?.user?.attributes?.company,
      Plan: user?.current_plan.name,
    });
  }, [user]);

  function BillingSummary({ plan }: { plan: Plan }) {
    const price = user?.tenure?.price;
    const tenure = user?.tenure?.tenure;
    return (
      <Stack spacing={2}>
        <Typography variant="h4" fontWeight="bold" color={(theme) => theme.palette.primary.main}>
          {capitalize(user?.current_plan?.name)} Plan
        </Typography>
        <Stack direction="row" alignItems="flex-end">
          <Typography>{plan?.description}</Typography>
          {isOnFreePlan ? (
            <></>
          ) : (
            <>
              <Typography variant="h2" fontWeight="bolder">
                ${price}
              </Typography>
              <Typography variant="subtitle1" fontSize="large" sx={{ mb: 1 }}>
                /{upperFirst(tenure)}
              </Typography>
            </>
          )}
        </Stack>
        {!isOnFreePlan && (
          <Typography sx={{ opacity: 0.7, color: "#da4848" }}>
            Your plan expires on {user?.expiry_date}
          </Typography>
        )}
      </Stack>
    );
  }

  const plan = plans.get(upperCase(user?.current_plan?.name));
  return (
    <Container maxWidth="xl">
      <APIDataLoader {...userApiData}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography fontWeight="bolder" variant="h4">
            Current plan
          </Typography>
          <CopyApiKey />
        </Stack>
        <Spacing spacing={2} />
        <Grid container spacing={4} justifyContent="space-around">
          <Grid item xs={6}>
            <BillingSummary plan={plan as Plan} />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={3}>
              <PlanDetails plan={plan as Plan} />
            </Stack>
          </Grid>
        </Grid>
        <Spacing spacing={5} />
      </APIDataLoader>
      <UpgradeOptions />
    </Container>
  );
}

export default CurrentPlan;
