export enum ComponentType {
  function = "function",
  event = "event",
}

export interface IndexedEventOrFunction {
  name: string;
  type: ComponentType;
}

export interface CountOverTime {
  y: number;
  x: string;
  date: string;
}
