import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useStatistics, { DailyAPIUsage } from "./statistics";
import { Box, Card, CardContent, Container, Grid, Tooltip, Stack, Typography } from "@mui/material";
import CopyApiKey from "../auth/CopyApiKey";
import Spacing from "../common/Spacing";
import { Line } from "react-chartjs-2";
import { APIData } from "../common/rest-client/types";
import { sum } from "lodash";
import { KeyboardArrowLeftRounded } from "@mui/icons-material";
import { toUsageStatistics } from "../common/Routes";
import { format } from "date-fns";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { formatNumberToShortForm } from "../common/string/format";

function getOptionsAndDataForLineGraph(dailyUsage: APIData<DailyAPIUsage>) {
  const calls = (dailyUsage.data?.task_count || []).map((stats) => stats.count);
  const labels = (dailyUsage.data?.task_count || []).map((stats) => {
    return format(new Date(stats.date || ""), "d/M/yyyy");
  });

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    color: "white",
    plugins: {
      legend: {
        position: "right" as const,
      },
      title: {
        display: false,
        text: "Daily Usage",
      },
    },
    scales: {
      y: {
        ticks: {
          tickColor: "#ffffff",
          color: "white",
        },
      },
      x: {
        ticks: {
          tickColor: "#ffffff",
          color: "white",
        },
      },
    },
  };
  const data = {
    labels,
    datasets: [
      {
        label: "Calls",
        data: calls,
        borderColor: "rgba(13, 182, 120, 1)",
        backgroundColor: "rgba(13, 182, 120, 1)",
      },
    ],
  };
  return { options, data };
}

function DailyStatistics() {
  const { task } = useParams();
  const { dailyUsage, getDailyUsage, resetDailyUsage } = useStatistics();
  const { options, data } = getOptionsAndDataForLineGraph(dailyUsage);
  const calls = (dailyUsage.data?.task_count || []).map((stats) => stats.count);
  const credits = (dailyUsage.data?.task_count || []).map(
    (stats) => stats.count * dailyUsage.data?.weightage
  );

  useEffect(() => {
    getDailyUsage(atob(task || ""));
    return resetDailyUsage;
  }, []);

  return (
    <Container maxWidth={"xl"} component={"main"}>
      <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <Link
          to={toUsageStatistics()}
          style={{ textDecoration: "none" }}
          onClick={() => {
            Mixpanel.track(MixpanelEvents.UsageStatistics, { source: "Daily statistics" });
          }}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            display={"flex"}
            color={"rgba(13, 182, 120, 1)"}
            alignItems={"center"}
          >
            <KeyboardArrowLeftRounded />
            Back
          </Typography>
        </Link>
        <CopyApiKey />
      </Stack>
      <Spacing spacing={2} />
      <Grid
        container
        sx={{
          border: "1px solid rgba(255, 255, 255, 0.3)",
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Grid item xs={12} md={4}>
          <Typography fontWeight={400} sx={{ mb: { xs: 1, lg: 0 } }} align={"center"}>
            &nbsp;Total Calls:{" "}
            <Tooltip placement={"top"} title={sum(calls)?.toLocaleString("en-US")}>
              <span>{formatNumberToShortForm(sum(calls))}</span>
            </Tooltip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            fontWeight={400}
            sx={{ mb: { xs: 1, lg: 0 }, borderLeft: "2px solid rgba(255, 255, 255, 0.3)" }}
            align={"center"}
          >
            &nbsp;Weightage: {dailyUsage?.data ? dailyUsage?.data?.weightage : 0}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            fontWeight={400}
            sx={{ mb: { xs: 1, lg: 0 }, borderLeft: "2px solid rgba(255, 255, 255, 0.3)" }}
            align={"center"}
          >
            &nbsp;Total Credits Used:{" "}
            <Tooltip placement={"top"} title={sum(credits)?.toLocaleString("en-US")}>
              <span>{formatNumberToShortForm(sum(credits))}</span>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
      <Spacing spacing={2} />
      <Grid item xs={12}>
        <Card variant={"outlined"}>
          <CardContent>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant={"h5"} fontWeight={"bold"}>
                Daily Statistics: {atob(task || "")}
              </Typography>
            </Stack>
            <Box width={"100%"} height={"60vh"}>
              <Line data={data} options={options} />
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}

export default DailyStatistics;
