/* eslint max-len: 0 */

const Response = {
  TokenBalance: [
    {
      contract_name: "UnmarshalToken",
      contract_ticker_symbol: "MARSH",
      contract_decimals: 18,
      contract_address: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
      coin: 60,
      type: "ERC20",
      balance: "2660000000000000000000",
      quote: 140.51184,
      quote_rate: 0.052824,
      logo_url:
        "https://assets.unmarshal.io/tokens/ethereum_0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37.png",
      quote_rate_24h: "0.00080339",
      quote_pct_change_24h: 1.54437,
      verified: true,
      coin_gecko_id: "unmarshal",
    },
    {
      contract_name: "Frontier Token",
      contract_ticker_symbol: "FRONT",
      contract_decimals: 18,
      contract_address: "0xf8c3527cc04340b208c854e985240c02f7b7793f",
      coin: 60,
      type: "ERC20",
      balance: "4843997835959514312853",
      quote: 879.2243592093396,
      quote_rate: 0.181508,
      logo_url:
        "https://assets.unmarshal.io/tokens/ethereum_0xf8C3527CC04340b208C854E985240c02F7B7793f.png",
      quote_rate_24h: "0.00142584",
      quote_pct_change_24h: 0.79177,
      verified: true,
      coin_gecko_id: "frontier-token",
    },
  ],
  TransactionHistory: {
    page: 1,
    items_on_page: 25,
    has_next: true,
    transactions: [
      {
        id: "0x018f492ba8a961cdfa760b97a0225909ca2a51122e1626d17ece3757ecd4f09c",
        from: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        to: "0x29667ef881f2449ef36dd0553c402cd50b61f483",
        fee: "105000000000000",
        gas_price: "5000000000",
        date: 1672382341,
        status: "completed",
        type: "send",
        block: 24344661,
        value: "1000000000000000000",
        nonce: 315,
        native_token_decimals: 18,
        description: "Sent 1 BNB",
        sent: [
          {
            name: "Binance Smart Chain",
            symbol: "BNB",
            token_id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
            decimals: 18,
            value: "1000000000000000000",
            logo_url:
              "https://assets.unmarshal.io/tokens/bsc_0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE.png",
            from: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
            to: "0x29667ef881f2449ef36dd0553c402cd50b61f483",
          },
        ],
      },
      {
        id: "0x80b1ea6a77add36330dfbc6b2b51e8df5bb58a31909f3281483240c7b2af7a24",
        from: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        to: "0x29667ef881f2449ef36dd0553c402cd50b61f483",
        fee: "105000000000000",
        gas_price: "5000000000",
        date: 1671357499,
        status: "completed",
        type: "send",
        block: 24007623,
        value: "250000000000000000",
        nonce: 314,
        native_token_decimals: 18,
        description: "Sent 0.25 BNB",
        sent: [
          {
            name: "Binance Smart Chain",
            symbol: "BNB",
            token_id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
            decimals: 18,
            value: "250000000000000000",
            logo_url:
              "https://assets.unmarshal.io/tokens/bsc_0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE.png",
            from: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
            to: "0x29667ef881f2449ef36dd0553c402cd50b61f483",
          },
        ],
      },
    ],
  },
  TransactionDetails: {
    id: "0xe681b566dc616d328fcfd5e27ee94a18ffbd91c4e8f2505b18d74a458ffc8702",
    from: "0xa6dd3ecbb9721837252994df443cc4e6dc424507",
    to: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
    fee: "498361100152448",
    gas_price: "16710069077",
    date: 1672899143,
    status: "completed",
    type: "send",
    block: 16338720,
    value: "0",
    nonce: 1611,
    native_token_decimals: 18,
    description: "Sent 1732.2519 MARSH",
    sent: [
      {
        name: "UnmarshalToken",
        symbol: "MARSH",
        token_id: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
        decimals: 18,
        value: "1732251899999956490932",
        logo_url:
          "https://assets.unmarshal.io/tokens/ethereum_0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37.png",
        from: "0xa6dd3ecbb9721837252994df443cc4e6dc424507",
        to: "0xf16e9b0d03470827a95cdfd0cb8a8a3b46969b91",
        log_index: "161",
      },
    ],
  },
  PriceBySymbol: [
    {
      blockchain: "bsc",
      contract: "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256",
      decimal: 18,
      name: "UnmarshalToken",
      price: "0.057602",
      price_change: "8.47749",
      symbol: "MARSH",
      timestamp: "1672900324",
    },
    {
      blockchain: "ethereum",
      contract: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
      decimal: 18,
      name: "UnmarshalToken",
      price: "0.057602",
      price_change: "8.008912578016208",
      symbol: "MARSH",
      timestamp: "1672900324",
    },
  ],
  PriceByAddress: {
    price: "0.057602",
    timestamp: "1672900324",
    tokenId: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
  },
  TokenDetailsByAddress: {
    blockchain: "ethereum",
    coin_gecko_id: "unmarshal",
    contract: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
    decimal: 18,
    image:
      "https://assets.unmarshal.io/tokens/ethereum_0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37.png",
    is_logo_present: true,
    name: "UnmarshalToken",
    symbol: "MARSH",
    total_supply: "100000000",
    verified: true,
  },
  TokenDetailsBySymbol: [
    {
      blockchain: "ethereum",
      coin_gecko_id: "unmarshal",
      contract: "0x5a666c7d92e5fa7edcb6390e4efd6d0cdd69cf37",
      decimal: 18,
      image:
        "https://assets.unmarshal.io/tokens/ethereum_0x5a666c7d92E5fA7Edcb6390E4efD6d0CDd69cF37.png",
      is_logo_present: true,
      name: "UnmarshalToken",
      symbol: "MARSH",
      total_supply: "100000000",
      verified: true,
    },
    {
      blockchain: "bsc",
      coin_gecko_id: "unmarshal",
      contract: "0x2fa5daf6fe0708fbd63b1a7d1592577284f52256",
      decimal: 18,
      image:
        "https://assets.unmarshal.io/tokens/bsc_0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256.png",
      is_logo_present: true,
      name: "UnmarshalToken",
      symbol: "MARSH",
      total_supply: "15064156",
      verified: true,
    },
  ],
  NftAssets: {
    items_on_page: 25,
    next_offset: 33,
    nft_assets: [
      {
        asset_contract: "0xf2f8e81b32ec5cada38a19494fa8c51d0507b147",
        token_id: "420",
        owner: "0xfC43f5F9dd45258b3AFf31Bdbe6561D97e8B71de",
        external_link: "https://witchernftart.xyz/json/420",
        type: "1155",
        balance: 1,
        issuer_specific_data: {
          entire_response: "hJIxixs/EMW/yqDq/4fF7rdLc3BFQgqTFL7jmJNH1nDSaBmNvN6EfPegXeJwASeFCu3se",
          image_url: "https://witchernftart.xyz/item.png",
          name: "witcher mint pass",
        },
        price: "",
        animation_url: "",
        description:
          "Witcher Mint pass is an collection of nfts to take part in Witcher Free Mint.",
        creator: "0x0000000000000000000000000000000000000000",
      },
      {
        asset_contract: "0xe5cb1e804d32fce360fbf955503e828b93b6b157",
        token_id: "190",
        owner: "0xfC43f5F9dd45258b3AFf31Bdbe6561D97e8B71de",
        external_link:
          "https://unmarshal.mypinata.cloud/ipfs/QmTohrySHMACjukeFxXquCswxb2T7vwmn34J7Xe5kuQRxZ/190",
        type: "1155",
        balance: 1,
        issuer_specific_data: {
          entire_response: "XJFRaxVBDIX/Soiv27v20fVJLIqgUvTik1Cys9ndtLOZIZO1LeJ",
          image_url: "https://nftipfs.link/dragonage/1.jpg",
          name: "mint pass #190",
        },
        price: "",
        animation_url: "",
        description: "This is a collection of Mint Pass for Dragon Age NFT",
        creator: "0x0000000000000000000000000000000000000000",
      },
    ],
  },
  NFTTransactionHistory: {
    page: 1,
    total_pages: 4,
    items_on_page: 25,
    total_txs: 80,
    transactions: [
      {
        contract_address: "0x4d347a98eafe1d66a347cc4fa9acbbacc9d5fe3c",
        token_id: "13",
        transaction_hash: "0x6a8894304997da6c924457e78eb905abe76306787741f5de0fa970e4c3dfc397",
        block_number: 16188994,
        block_hash: "0x962a96e3a5ef252942d39a2f36a9e644ca9b11e7010ea4f10586e42bdda00681",
        transaction_idx: 139,
        log_idx: 2911,
        sender: "0x72fed1883b5a3dd766ed9d2dfe5ecceb91f3edcc",
        to: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        type: "",
        creator: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        timestamp: 1671094019,
      },
      {
        contract_address: "0xe5cb1e804d32fce360fbf955503e828b93b6b157",
        token_id: "190",
        transaction_hash: "0x39d8c783db39ff52c723c32085f505a7d6629d866ad1baf3d74cce78399c6fc4",
        block_number: 15873706,
        block_hash: "0x39ddc5b0790a7457833fa40e197cb85433299b4228a3e72081a20feeebb796d3",
        transaction_idx: 251,
        log_idx: 609,
        sender: "0xcd8740f3a1a740d6c65ca3d257ae845a848a2502",
        to: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        type: "",
        creator: "0xfc43f5f9dd45258b3aff31bdbe6561d97e8b71de",
        timestamp: 1667287811,
      },
    ],
  },
};

const APIs = [
  {
    name: "Token Balances",
    description:
      "This endpoint allows you to retrieve balance tied to a particular address on a specific chain. The response of this API will let you understand in details about all your assets.",
    path: "/v1/:chain/address/:address/assets",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/bsc/address/demo.eth/assets?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/fungibleerc20tokenbalances",
    response: Response.TokenBalance,
    height: 400,
  },
  {
    name: "Transaction History",
    description: "The transactions API fetches the list of all transactions in wallet address",
    path: "/v3/:chain/address/:address/transactions",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v3/:chain/address/:address/transactions?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/get-v3-chain-address-address-transactions",
    response: Response.TransactionHistory,
    height: 400,
  },
  {
    name: "Transaction Details",
    description:
      "The transaction detail End Point which gives as much details possible for a specific transaction on a specific given chain.",
    path: "/v1/:chain/transactions/:transactionHash",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/:chain/transactions/:transactionHash?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/get-v1-chain-transactions-transactionhash",
    response: Response.TransactionDetails,
    height: 400,
  },
  {
    name: "Price by Symbol",
    description:
      "This api provides price of token by taking the symbol of the token(ticker) as a parameter which will give latest price, timestamp can also be provided as a query param to get historical data.",
    path: "/v1/pricestore/:symbol",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/pricestore/:symbol?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/get-v1-pricestore-symbol",
    response: Response.PriceBySymbol,
    height: 400,
  },
  {
    name: "Price by Address",
    description:
      "This api provides price of a token by taking the address of the token(contract of token) in any chain such as Ethereum, Matic and Binance, timestamp can be provided as a query parameter to view historical data.",
    path: "/v1/pricestore/chain/:chain/:address",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/pricestore/chain/:chain/:address?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/get-v1-pricestore",
    response: Response.PriceByAddress,
    height: 180,
  },
  {
    name: "Token Details by Address",
    description:
      "when searched by token contract address, it fetches exhaustive information of the token.",
    path: "/v1/tokenstore/token/address/:address",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/tokenstore/token/address/:address?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/details-for-token-contract",
    response: Response.TokenDetailsByAddress,
    height: 400,
  },
  {
    name: "Token Details by Symbol",
    description:
      "when searched with the ticker name of any token, it returns detailed information of the token within the blockchains it exists in.",
    path: "/v1/tokenstore/token/symbol/:symbol",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v1/tokenstore/token/symbol/:symbol?auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/details-for-token-symbol",
    response: Response.TokenDetailsBySymbol,
    height: 400,
  },
  {
    name: "NFT assets By address",
    description:
      "NFT assets API returns all NFT assets held by a address with pagination (offset based pagination). In this endpoint instead of navigating with page parameter we can navigate using offset, where the next offset is provided in the response.",
    path: "/v3/:chain/address/:address/nft-assets",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v3/:chain/address/:address/nft-assets?auth_key=${apiKey}'`,
    docsLink:
      "https://docs.unmarshal.io/reference/nft-assets-by-address-with-offset-based-pagination",
    response: Response.NftAssets,
    height: 400,
  },
  {
    name: "Nft Transaction History by address",
    description:
      "NFT transactions API gives the list of transaction being made on given wallet address with pagination.",
    path: "/v2/:chain/address/:address/nft-transactions",
    curl: (apiKey: string) => `curl -i -X GET \\
  'https://api.unmarshal.com/v2/:chain/address/:address/nft-transactions?page=1&pageSize=5&auth_key=${apiKey}'`,
    docsLink: "https://docs.unmarshal.io/reference/nft-transactions-by-address-with-pagination",
    response: Response.NFTTransactionHistory,
    height: 400,
  },
];
export default APIs;
