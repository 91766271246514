import React from "react";
import { Stack, Typography } from "@mui/material";
import academyLogo from "../../assets/images/academy-logo.svg";
import Button from "@mui/material/Button";
import MuiLink from "@mui/material/Link";
import { ArrowForward } from "@mui/icons-material";
import Box from "@mui/material/Box";

const Academy = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"}>
      <Stack
        maxWidth={"md"}
        justifyContent={"center"}
        alignItems={"center"}
        spacing={2}
        height={"70vh"}
      >
        <Box width={"70%"}>
          <img width={"100%"} src={academyLogo} alt={"Unmarshal Academy"} />
        </Box>
        <Typography fontSize={"24px"} fontWeight={"600"} align={"center"} color={"#4e1dff"}>
          A comprehensive guide to easily access Web3 data and configure backend infrastructure for
          your dApp.
        </Typography>
        <MuiLink href={"https://unmarshal.io/academy"} target={"_blank"}>
          <Button variant={"contained"} sx={{ height: "44p  x" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography fontSize={"18px"} fontWeight={500}>
                Get Started
              </Typography>
              <ArrowForward />
            </Stack>
          </Button>
        </MuiLink>
      </Stack>
    </Stack>
  );
};

export default Academy;
