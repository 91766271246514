import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/material";

const LoadingIndicator = () => {
  return (
    <Skeleton
      sx={{ borderRadius: "10px" }}
      color={"primary"}
      animation="wave"
      variant="rectangular"
      height={"300px"}
    />
  );
};

export const ErrorIndicator = () => {
  return (
    <Stack sx={{ height: "300px" }} justifyContent={"center"} alignItems={"center"}>
      <Typography variant={"h4"} color={"primary"}>
        No data found
      </Typography>
    </Stack>
  );
};

export default LoadingIndicator;
