import useGetRequest from "../common/rest-client/get";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";

export interface QueryResponse {
  label_value: string;
  metric_value: string;
  time_stamp: string;
}

export interface Statistics {
  user: string;
  query_response: QueryResponse[];
}

export interface MonthlyAPIUsage {
  task: string;
  count: number;
  weightage: number;
  credits_used: number;
}

export interface TaskCount {
  count: number;
  date: string;
}

export interface DailyAPIUsage {
  task: string;
  weightage: number;
  task_count: TaskCount[];
}

export const callPerAPIState = atom<APIData<Statistics>>({
  key: "CALLS_PER_API",
  default: DefaultAPIDataValues,
});

export const statusCodeState = atom<APIData<Statistics>>({
  key: "STATUS_CODE_API",
  default: DefaultAPIDataValues,
});

export const apiUsageState = atom<APIData<Statistics>>({
  key: "API_USAGE_STATE",
  default: DefaultAPIDataValues,
});

const dailyUsageAtom = atom<APIData<DailyAPIUsage>>({
  key: "DAILY_USAGE",
  default: DefaultAPIDataValues,
});

const monthlyUsageAtom = atom<APIData<Array<MonthlyAPIUsage>>>({
  key: "MONTHLY_USAGE",
  default: DefaultAPIDataValues,
});

const useStatistics = () => {
  const get = useGetRequest();
  const [callsPerAPI, updateCallsPerAPI] = useRecoilState(callPerAPIState);
  const [statusCodes, updateStatusCodes] = useRecoilState(statusCodeState);
  const [apiUsage, updateApiUsage] = useRecoilState(apiUsageState);

  const getCallsPerAPI = () => {
    get<Statistics>(updateCallsPerAPI, `/users-service/stats/calls-per-api`);
  };

  const getStatusCodes = () => {
    get<Statistics>(updateStatusCodes, `/users-service/stats/status-codes`);
  };

  const getApiUsage = () => {
    get<Statistics>(updateApiUsage, `/users-service/stats/usage`);
  };

  const [dailyUsage, updateDailyUsage] = useRecoilState(dailyUsageAtom);
  const resetDailyUsage = useResetRecoilState(dailyUsageAtom);
  const getDailyUsage = (task: string) => {
    get<DailyAPIUsage>(updateDailyUsage, `/users-service/stats/daily-usage`, {
      params: { task },
    });
  };

  const [monthlyUsage, updateMonthlyUsage] = useRecoilState(monthlyUsageAtom);
  const resetMonthlyUsage = useResetRecoilState(monthlyUsageAtom);
  const getMonthlyUsage = () => {
    get<Array<MonthlyAPIUsage>>(updateMonthlyUsage, `/users-service/stats/monthly-usage`);
  };
  return {
    getCallsPerAPI,
    callsPerAPI,
    getStatusCodes,
    statusCodes,
    getApiUsage,
    apiUsage,
    dailyUsage,
    getDailyUsage,
    resetDailyUsage,
    monthlyUsage,
    getMonthlyUsage,
    resetMonthlyUsage,
  };
};

export default useStatistics;
