import { createTheme, responsiveFontSizes } from "@mui/material";
import type {} from "@mui/lab/themeAugmentation";

export const borderColor = "rgba(255, 255, 255, 0.22)";
export const secondaryColor = "#2DBFE0";
export const primaryColor = "#4e1dff";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: primaryColor,
      contrastText: "#0B0F15",
    },
    background: {
      default: "#0B0F15",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans",
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          border: "none",
          backgroundColor: "#191D22",
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
      styleOverrides: {
        root: {
          borderRadius: "8px",
          background: "#191D22",
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "contained",
        size: "large",
      },
      styleOverrides: {
        root: {
          borderRadius: 1,
          paddingLeft: "2rem",
          paddingRight: "2rem",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 16,
          background: "#0d172c",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          borderRadius: 16,
          background: "rgba(0, 0, 0, 0)",
          padding: 12,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        placement: "top-start",
        arrow: true,
      },
    },
    MuiDialog: {
      defaultProps: {
        PaperProps: {
          style: {
            border: "1px solid rgba(255, 255, 255, 0.22)",
            borderRadius: 16,
            background: "rgba(0, 0, 0, 0)",
          },
        },
        BackdropProps: {
          style: {
            backdropFilter: "blur(5px)",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
    MuiAppBar: {
      defaultProps: {},
      styleOverrides: {
        root: {
          background: "rgba(0, 0, 0, 0)",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        ChipProps: {
          sx: {
            primaryColor: primaryColor,
          },
        },
      },
    },
  },
});

const Theme = responsiveFontSizes(theme);

export default Theme;
