import React from "react";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { APIRequestStatus } from "../rest-client/types";

interface APIDataLoaderProps extends APIRequestStatus {
  loadingIndicator?: React.ReactElement;
  onError?: any;
  message?: any;
}

export const LoadingIndicator = () => {
  return (
    <Stack direction={"row"} justifyContent={"center"}>
      <CircularProgress />
    </Stack>
  );
};

const APIDataLoader = (props: React.PropsWithChildren<APIDataLoaderProps>) => {
  const {
    children,
    hasError,
    isLoading,
    isSuccess,
    loadingIndicator = <LoadingIndicator />,
  } = props;

  if (!isSuccess && isLoading) {
    return loadingIndicator;
  }

  if (hasError) {
    if (props.onError != null) {
      return props.onError;
    }
    return props.message ? props.message : <Typography align={"center"}>Data not found</Typography>;
  }

  return <>{children}</>;
};

export default APIDataLoader;
