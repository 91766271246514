import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import Spacing from "../common/Spacing";
import { atom, useRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import { isEmpty } from "lodash";

export interface Referral {
  referee_name: string;
  reward: number;
  status: string;
}

export interface ReferralHistory {
  user_id: string;
  referrals: Referral[];
}

export const referralHistoryAtom = atom<APIData<ReferralHistory>>({
  key: "REFERRAL_HISTORY",
  default: DefaultAPIDataValues,
});

const useReferralHistory = () => {
  const get = useGetRequest();
  const [referralHistory, updateReferralHistory] = useRecoilState(referralHistoryAtom);

  const getReferralHistory = () =>
    get<ReferralHistory>(updateReferralHistory, `/users-service/referrals`);

  return { referralHistory, getReferralHistory };
};

const History = () => {
  const { getReferralHistory, referralHistory } = useReferralHistory();

  useEffect(getReferralHistory, []);

  return (
    <Container>
      <Typography variant={"h3"} textAlign={"center"}>
        Your Referral History
      </Typography>
      <Spacing spacing={3} />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Username</TableCell>
              <TableCell align="center">Referral Status</TableCell>
              <TableCell align="center">Referral Rewards</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(referralHistory.data?.referrals || []).map((row) => (
              <TableRow
                key={row.referee_name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">{row.referee_name}</TableCell>
                <TableCell align="center">{row.status}</TableCell>
                <TableCell align="center">
                  {!!row.reward && `${row.reward} USD worth of MARSH`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {isEmpty(referralHistory.data?.referrals) && (
        <>
          <Spacing spacing={3} />
          <Typography variant={"h6"} align={"center"}>
            No Records Found
          </Typography>
        </>
      )}
    </Container>
  );
};

export default History;
