import React from "react";
import useIndexerDetails from "../IndexerClient";
import MetabaseCredentials from "./MetabaseCredentials";
import { useParams } from "react-router-dom";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import Spacing from "../../common/Spacing";
import StatisticsTabs, { StatisticsTabsType } from "./StatisticsTabs";
import ParserTeaser from "../../common/ParserTeaser";

const Credentials = () => {
  const { indexersByUserID } = useIndexerDetails();
  const { indexerDetails } = useIndexerDetailsByID();
  const { id } = useParams();
  const indexerID = id || "";

  if (indexersByUserID.error) {
    return <ParserTeaser />;
  }
  return (
    <React.Fragment>
      <StatisticsTabs
        value={StatisticsTabsType.CUSTOM_ANALYTICS}
        indexerId={indexerID}
        indexerDetails={indexerDetails}
      />
      <Spacing spacing={2} />
      <MetabaseCredentials />
    </React.Fragment>
  );
};

export default Credentials;
