import React, { PropsWithChildren, useEffect } from "react";
import useIndexerDetails from "./IndexerClient";
import useIndexerDetailsByID from "./IndexerDetailsClient";
import { useNavigate, useParams } from "react-router-dom";

interface ParserDetailsProps {
  navigateTo: (id: string) => string;
}
const RefreshParserDetails = ({ navigateTo, children }: PropsWithChildren<ParserDetailsProps>) => {
  const { indexersByUserID } = useIndexerDetails();
  const { getIndexerById } = useIndexerDetailsByID();
  const { id } = useParams();
  const indexerID = id || "";
  const navigate = useNavigate();

  useEffect(() => {
    if (indexersByUserID.data && indexerID === "default") {
      navigate(navigateTo(indexersByUserID?.data?.indexer_list[0].generated_indexer_id));
    }
  }, [indexersByUserID]);

  useEffect(() => {
    if (indexerID && indexerID !== "default") getIndexerById(indexerID);
  }, [indexerID]);

  return <>{children}</>;
};

export default RefreshParserDetails;
