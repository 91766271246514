import { SetterOrUpdater } from "recoil";
import { AxiosRequestConfig } from "axios";
import useRestClient from "./RestClient";
import DefaultAPIDataValues, { APIData } from "./types";

export interface Options extends AxiosRequestConfig {
  parse?: (data: any) => any;
  onSuccess?: (data: any) => void;
}

const useGetRequest = () => {
  const { get } = useRestClient();
  return <DataType>(
    setterOrUpdater: SetterOrUpdater<APIData<DataType>>,
    path: string,
    options: Options = {}
  ) =>
    get(path, {
      ...options,
      beforeFetch: () =>
        setterOrUpdater((currentValue) => ({
          ...currentValue,
          isLoading: true,
        })),
      onSuccess: ({ data }) => {
        const parsedData = options?.parse ? options.parse(data) : data;
        options?.onSuccess && options.onSuccess(parsedData);
        setterOrUpdater({
          ...DefaultAPIDataValues,
          data: parsedData,
          isSuccess: true,
        });
      },
      onError: (error) =>
        setterOrUpdater({
          ...DefaultAPIDataValues,
          hasError: true,
          isSuccess: false,
          error,
        }),
    });
};

export default useGetRequest;
