import useGraphQLDetails from "./GraphQLClient";
import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import { useParams } from "react-router-dom";
import useIndexerDetails from "../IndexerClient";
import { includes, isEmpty } from "lodash";
import { EndStatus } from "../Indexer";
import { Alert, Link, Stack } from "@mui/material";
import Spacing from "../../common/Spacing";
import GraphiQL from "graphiql";
import ParserTeaser from "../../common/ParserTeaser";
import Button from "@mui/material/Button";
import { copyTextToClipboard } from "../../common/clipboard/copy";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import { toast } from "react-toastify";
import useUser from "../../user/useUser";

const NewGraphQL = () => {
  const { graphQLDetails, getGraphQLDetails, addGraphQl, newGraphQL } = useGraphQLDetails();

  useEffect(() => {
    const interval = setInterval(() => {
      getGraphQLDetails();
    }, 15000);
    return () => clearInterval(interval);
  }, [newGraphQL]);

  return (
    <Paper
      sx={{
        mb: 1,
        background: "rgba(255, 255, 255, 0.06)",
        border: "2px dashed rgba(255, 255, 255, 0.22)",
        borderRadius: "12px",
        color: "white",
      }}
    >
      <LoadingButton
        loading={newGraphQL?.isLoading || (newGraphQL?.isSuccess && !graphQLDetails?.data)}
        variant={"text"}
        sx={{
          color: "white",
          height: "100%",
          width: "100%",
          background: "none",
          padding: "16px 32px 16px 40px",
        }}
        onClick={() => addGraphQl()}
      >
        <Typography variant={"h6"} fontWeight={600}>
          GraphQL: Add New +{" "}
        </Typography>
      </LoadingButton>
    </Paper>
  );
};

export const GraphqlPlayground = () => {
  const { graphQLDetails, getGraphQLDetails } = useGraphQLDetails();
  const { indexerDetails, getIndexerById } = useIndexerDetailsByID();
  const { id } = useParams();
  const { indexersByUserID, indexersByStatus, getIndexersByStatus } = useIndexerDetails();
  const indexerID = id || "";
  const [graphQLError, setGraphQLError] = useState(false);
  const { isOnFreePlan } = useUser();

  const onCopy = (contentType: string) => {
    Mixpanel.track(MixpanelEvents.CopyContent, {
      contentType: contentType,
      source: "GraphQL Playground",
    });
    toast.success(`${contentType} copied`);
  };

  function isAnyDeployAndMaintainIndexerPresent() {
    for (let i = 0; i < indexersByStatus?.data?.indexer_list?.length; i++) {
      if (
        !indexersByStatus?.data?.indexer_list[i].download_link &&
        includes(EndStatus, indexersByStatus?.data?.indexer_list[i].deploy_status)
      )
        return true;
    }
    return false;
  }

  useEffect(() => {
    getIndexerById(indexerID);
  }, [graphQLDetails.data?.uid]);

  useEffect(() => {
    getGraphQLDetails();
    getIndexersByStatus("Running");
    const interval = setInterval(() => {
      if (graphQLDetails?.hasError) getGraphQLDetails();
    }, 15000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (indexersByUserID.error) {
    return <ParserTeaser />;
  }

  if (indexerDetails.data?.isSubgraph) {
    return (
      <Typography textAlign="center">
        <Link
          target="_blank"
          href={indexerDetails?.data?.additional_details?.graphiql_details?.graphiql_url}
        >
          Click here to open GraphQL playground
        </Link>
      </Typography>
    );
  }

  if (isOnFreePlan) {
    return (
      <Alert
        severity={"warning"}
        sx={{ justifyContent: "center", fontSize: "18px", alignItems: "center" }}
      >
        Please upgrade your plan to access GraphQL.
      </Alert>
    );
  }

  if (
    !isEmpty(indexersByUserID?.data?.indexer_list) &&
    isAnyDeployAndMaintainIndexerPresent() &&
    graphQLDetails?.hasError
  ) {
    return <NewGraphQL />;
  }

  if (graphQLError) {
    return (
      <Alert
        severity={"error"}
        sx={{ justifyContent: "center", fontSize: "18px", alignItems: "center" }}
      >
        GraphQL is unavailable at the moment. Please try later
      </Alert>
    );
  }

  if (graphQLDetails?.data?.deploy_status === "Deploying") {
    return (
      <Alert
        severity={"info"}
        sx={{ justifyContent: "center", fontSize: "18px", alignItems: "center" }}
      >
        Deploying...
      </Alert>
    );
  }

  if (indexerDetails?.data?.additional_details?.graphiql_details?.graphql_url != "")
    return (
      <>
        <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
          <Button
            variant={"outlined"}
            onClick={() => {
              copyTextToClipboard(
                indexerDetails?.data?.additional_details?.graphiql_details?.graphql_url,
                () => onCopy("GraphQL Link"),
                () => alert("Failed to copy")
              );
            }}
          >
            Copy GraphQL Link
          </Button>
          <Button
            variant={"outlined"}
            onClick={() => {
              copyTextToClipboard(
                indexerDetails?.data?.additional_details?.graphiql_details?.graphiql_url,
                () => onCopy("GraphQL Playground Link"),
                () => alert("Failed to copy")
              );
            }}
          >
            Copy Playground Link
          </Button>
        </Stack>
        <Spacing spacing={1} />
        <Stack height={"700px"}>
          <GraphiQL
            fetcher={async (graphQLParams: any) => {
              return await fetch(
                indexerDetails?.data?.additional_details?.graphiql_details?.graphql_url,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(graphQLParams),
                  credentials: "same-origin",
                }
              )
                .then((data) => {
                  setGraphQLError(false);
                  return data.json().catch(() => data.text());
                })
                .catch(() => setGraphQLError(true));
            }}
          />
        </Stack>
      </>
    );

  return (
    <Alert
      severity={"warning"}
      sx={{ justifyContent: "center", fontSize: "18px", alignItems: "center" }}
    >
      No GraphQL present for this parser.
    </Alert>
  );
};

export default GraphqlPlayground;
