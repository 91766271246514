import React, { useEffect, useState } from "react";
import useMetabaseDetails from "../metabase/MetabaseClient";
import { Stack, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import Copy from "../../common/clipboard/copy";
import IconButton from "@mui/material/IconButton";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import Paper from "@mui/material/Paper";

const MetabaseCredentials = () => {
  const { metabaseDetails, getMetabaseDetails, refresh, refreshMetabase } = useMetabaseDetails();
  const [hidePassword, updateHidePassword] = useState(true);
  const MixpanelSource = "Metabase Details";

  useEffect(() => {
    getMetabaseDetails();
  }, []);

  return metabaseDetails?.data?.url ? (
    <Paper
      sx={{
        padding: { xs: "8px 16px 8px 20px", md: "16px 32px 16px 40px" },
        mb: 1,
        border: "1px solid rgba(255, 255, 255, 0.22)",
        background: "rgba(255, 255, 255, 0.06)",
        borderRadius: "12px",
        width: "48%",
        maxHeight: "170px",
        overflow: "scroll",
        transition: "transform 0.3s, border 0.3s",
        "&:hover": {
          borderColor: "#34D399",
          transform: "translateY(-2px)",
        },
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ mb: 1 }}
      >
        <Typography fontWeight={600} fontSize={"20px"}>
          Metabase Credentials
        </Typography>
        <Tooltip title={"Refreshes Metabase to include any missing objects"} placement={"top"}>
          <LoadingButton
            sx={{ borderRadius: "8px" }}
            variant={"outlined"}
            loading={refresh?.isLoading}
            onClick={() => {
              Mixpanel.track(MixpanelEvents.ReSyncMetabaseTables);
              refreshMetabase();
            }}
          >
            <Typography fontWeight={600}>Re-sync Tables</Typography>
          </LoadingButton>
        </Tooltip>
      </Stack>
      <Copy
        content={metabaseDetails?.data?.url}
        contentType={"Metabase URL"}
        source={MixpanelSource}
      >
        URL:{" "}
        <a
          style={{ color: "#ffffff" }}
          onClick={() => Mixpanel.track(MixpanelEvents.ExternalNavigation, { to: "Metabase" })}
          href={metabaseDetails?.data?.url}
          target={"_blank"}
          rel="noreferrer"
        >{`${metabaseDetails?.data?.url}`}</a>
      </Copy>
      <br />
      <Copy
        content={metabaseDetails?.data?.email}
        contentType={"email"}
        source={MixpanelSource}
      >{`Email: ${metabaseDetails?.data?.email}`}</Copy>
      <br />
      {hidePassword ? (
        <Copy
          content={metabaseDetails?.data?.password}
          contentType={"password"}
          source={MixpanelSource}
        >
          <Typography display={"inline"}>{`Password: ${"*".repeat(20)}`}</Typography>
          <Tooltip title={"Show password"} arrow placement="top">
            <IconButton
              size="small"
              sx={{ width: "max-content" }}
              onClick={() => updateHidePassword(false)}
            >
              <VisibilityOutlined />
            </IconButton>
          </Tooltip>
        </Copy>
      ) : (
        <Copy
          content={metabaseDetails?.data?.password}
          contentType={"password"}
          source={MixpanelSource}
        >
          {`Password: ${metabaseDetails?.data?.password}`}
          <Tooltip title={"Hide password"} arrow placement="top">
            <IconButton
              size="small"
              sx={{ width: "max-content" }}
              onClick={() => updateHidePassword(true)}
            >
              <VisibilityOffOutlined />
            </IconButton>
          </Tooltip>
        </Copy>
      )}
      <br />
    </Paper>
  ) : (
    <Paper
      sx={{
        padding: "16px 32px 16px 40px",
        mb: 1,
        background: "rgba(255, 255, 255, 0.06)",
        border: "2px dashed rgba(255, 255, 255, 0.22)",
        borderRadius: "12px",
        color: "white",
        width: "48%",
      }}
    >
      <Typography variant={"h6"} fontWeight={600}>
        Metabase Details
      </Typography>
    </Paper>
  );
};

export default MetabaseCredentials;
