import React from "react";
import {
  Button,
  Container,
  Stack,
  StepContent,
  StepLabel,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import CopyApiKey from "../auth/CopyApiKey";
import Card from "@mui/material/Card";
import Spacing from "../common/Spacing";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import { sdkData } from "./SDKConfig";
import MuiLink from "@mui/material/Link";
import { useNavigate, useParams } from "react-router-dom";
import { toSDK } from "../common/Routes";

const SDK = () => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { language } = useParams();
  const navigate = useNavigate();

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    navigate(toSDK(newAlignment));
    handleReset();
  };

  return (
    <Container maxWidth={"xl"} component={"main"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontWeight={600} fontSize={"28px"} align={"left"} color={"#82EDC6"}>
          Unmarshal SDKs
        </Typography>
        <CopyApiKey />
      </Stack>
      <Typography fontWeight={500} fontSize={"20px"}>
        Libraries that give you easy access to the powerful Unmarshal APIs
      </Typography>
      <Spacing spacing={2} />
      <>
        <ToggleButtonGroup
          color="primary"
          value={language}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
        >
          <ToggleButton value="ts">TypeScript</ToggleButton>
          <ToggleButton value="go">Golang</ToggleButton>
        </ToggleButtonGroup>
        <Spacing spacing={2} />
        <Box
          sx={{ p: 2, mb: 5, border: "1px solid rgba(255, 255, 255, 0.3)", borderRadius: "16px" }}
          maxWidth={"lg"}
        >
          <Typography fontWeight={500} fontSize={"24px"}>
            Steps to integrate TypeScript SDK
          </Typography>
          <Spacing spacing={1} />
          <Stepper activeStep={activeStep} orientation="vertical">
            {sdkData.get(language || "").steps?.map((step: any, index: number) => (
              <Step key={step.label}>
                <StepLabel
                  icon={
                    <span
                      style={{
                        color: "#4e1dff",
                        border: "0.5px solid #4e1dff",
                        borderRadius: "12px",
                        fontSize: "10px",
                        padding: "2px 6px",
                        cursor: "pointer",
                      }}
                    >{`Step ${index + 1}`}</span>
                  }
                  onClick={() => setActiveStep(index)}
                >
                  <Typography fontWeight={500} sx={{ cursor: "pointer" }}>
                    {step.label}
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Stack>{step.description}</Stack>
                  <Box sx={{ mb: 2 }}>
                    <div>
                      <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }}>
                        {index === sdkData.get(language || "").steps.length - 1
                          ? "Finish"
                          : "Continue"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        variant={"outlined"}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </div>
                  </Box>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {activeStep === sdkData.get(language || "").steps.length && (
            <Card sx={{ p: 3 }}>
              <Typography>
                You&apos;re all set!! For more details, visit&nbsp;
                <MuiLink
                  href={sdkData.get(language || "").docs}
                  target={"_blank"}
                  color={"#4e1dff"}
                  sx={{
                    textDecoration: "underline",
                    textDecorationThickness: "1px",
                    textDecorationColor: "#4e1dff",
                  }}
                >
                  Docs
                </MuiLink>
              </Typography>
              <Button onClick={handleReset}>Reset</Button>
            </Card>
          )}
        </Box>
      </>
    </Container>
  );
};

export default SDK;
