import { Stack } from "@mui/material";
import instagramLogo from "../../assets/images/socials/instagram.svg";
import linkedinLogo from "../../assets/images/socials/linkedin.svg";
import redditLogo from "../../assets/images/socials/reddit.svg";
import twitterLogo from "../../assets/images/socials/twitter.svg";
import React from "react";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

export const Socials = () => {
  function mixpanelTracking(account: string) {
    Mixpanel.track(MixpanelEvents.ExternalNavigation, {
      to: account,
      source: "Socials",
    });
  }

  return (
    <Stack flexDirection={"row"} justifyContent={"space-evenly"}>
      <a
        href={"https://instagram.com/unmarshal_io"}
        target={"_blank"}
        rel="noreferrer"
        onClick={() => mixpanelTracking("Instagram")}
      >
        <img src={instagramLogo} alt="" height={25} />
      </a>
      <a
        href={"https://twitter.com/unmarshal"}
        target={"_blank"}
        rel="noreferrer"
        onClick={() => mixpanelTracking("Twitter")}
      >
        <img src={twitterLogo} alt="" height={25} />
      </a>
      <a
        href={"https://www.linkedin.com/company/unmarshal-io/"}
        target={"_blank"}
        rel="noreferrer"
        onClick={() => mixpanelTracking("LinkedIn")}
      >
        <img src={linkedinLogo} alt="" height={25} />
      </a>
      <a
        href={"https://www.reddit.com/r/unmarshal_io/"}
        target={"_blank"}
        rel="noreferrer"
        onClick={() => mixpanelTracking("Reddit")}
      >
        <img src={redditLogo} alt="" height={25} />
      </a>
    </Stack>
  );
};
