import { atom, useRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";

interface SakhiWhitelistUsers {
  constant_type: string;
  value: {
    users: string[];
    enable_whitelist: boolean;
  };
}

const allowedUsersAtom = atom<APIData<SakhiWhitelistUsers>>({
  key: "WHITELIST_USERS",
  default: DefaultAPIDataValues,
});

const useWhitelistUsers = () => {
  const [allowedUsers, updateAllowedUsers] = useRecoilState(allowedUsersAtom);

  const get = useGetRequest();
  const getWhitelistedUsers = () => {
    get(updateAllowedUsers, `/cis/v1/constants/allowed_users`);
  };

  return {
    allowedUsers,
    getWhitelistedUsers,
  };
};

export default useWhitelistUsers;
