import { atom, useRecoilState, useResetRecoilState, selector, useRecoilValue } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import useRestClient from "../common/rest-client/RestClient";
import { toast } from "react-toastify";
import useIndexerDetails, {
  abiItem,
  CosmosNetwork,
  IndexerDetails,
  IndexerType,
} from "./IndexerClient";
import { ComponentType, IndexedEventOrFunction } from "./details/types";
import { concat, map } from "lodash";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

export interface SyncStatus {
  start_block: string;
  last_indexed_block: string;
  node_top_block: string;
}

export interface ExtendedIndexerDetails extends IndexerDetails {
  indexedEventsAndFunctions: Array<IndexedEventOrFunction>;
  allFunctions: Map<string, abiItem>;
  isDeployedAndMaintained: boolean;
  isSubgraph: boolean;
}

const IndexerDetailsClientAtom = atom<APIData<CosmosNetwork>>({
  key: "Indexer_details",
  default: DefaultAPIDataValues,
});

export const useUpdateIndexer = () => {
  const { deleteRequest, post } = useRestClient();
  const { getAllIndexersByUserID } = useIndexerDetails();
  const terminateIndexer = (indexerId: string, onComplete: () => void) => {
    deleteRequest(`/cis/v1/indexer/${indexerId}`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.TerminateParser, { indexer_id: indexerId });
        toast.success("Successfully terminated the parser!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedParserTermination, { indexer_id: indexerId });
        toast.error("Failed to terminate parser!");
      },
    });
  };

  const pauseIndexer = (indexerId: string, onComplete: () => void) => {
    post(`/cis/v1/indexers/${indexerId}/pause`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.PauseParser, { indexer_id: indexerId });
        toast.success("Successfully paused the parser!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToPauseParser, { indexer_id: indexerId });
        toast.error("Failed to pause the parser!");
      },
    });
  };

  const resumeIndexer = (indexerId: string, onComplete: () => void) => {
    post(`/cis/v1/indexers/${indexerId}/resume`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.ResumeParser, { indexer_id: indexerId });
        toast.success("Your parser will resume soon");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToResumeParser, { indexer_id: indexerId });
        toast.error("Failed to resume parser!");
      },
    });
  };

  const clearIndexerData = (indexerId: string, onComplete: () => void) => {
    deleteRequest(`/cis/v1/indexers/${indexerId}/clear-data`, {
      onSuccess() {
        getAllIndexersByUserID();
        Mixpanel.track(MixpanelEvents.ClearParserData, { indexer_id: indexerId });
        toast.success("Successfully cleared parser data!");
        onComplete();
      },
      onError() {
        Mixpanel.track(MixpanelEvents.FailedToPauseParser, { indexer_id: indexerId });
        toast.error("Failed to clear the parser data!");
      },
    });
  };

  return { terminateIndexer, pauseIndexer, resumeIndexer, clearIndexerData };
};

const useIndexerDetailsByID = () => {
  return { indexerDetails: {}, getIndexerById: () => {}, reset: () => {} } as any;
};

export const useCosmosNetworkByID = () => {
  const [indexerDetails, updateIndexerByID] = useRecoilState(IndexerDetailsClientAtom);
  const reset = useResetRecoilState(IndexerDetailsClientAtom);

  const indexerFetcher = useGetRequest();

  const getIndexerById = (id: string) => {
    indexerFetcher<CosmosNetwork>(updateIndexerByID, `/cosmos/network/${id}`);
  };

  return { indexerDetails, getIndexerById, reset };
};

export default useIndexerDetailsByID;
