import { atom, useRecoilState, useResetRecoilState } from "recoil";
import useRestClient from "../common/rest-client/RestClient";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import { toast } from "react-toastify";
import { Plan } from "../user/types";
import { Order } from "./OrdersTypes";

export interface PayableToken {
  token_address: string;
  token_symbol: string;
  token_decimals: number;
  chain_id: number;
  chain_display_name: string;
  chain_name: string;
  rpc_url: string;
  swap_link: string;
  price: number;
}

export interface ExcessUsage {
  excess_credits: number;
  description: string;
  price_details: Array<PayableToken>;
}

export interface Tenure {
  id: number;
  tenure: string;
  price: number;
  discount: number;
  discounted_price: number;
  currency_amounts: PayableToken[];
}

export interface PlanPriceDetails {
  id: number;
  name: string;
  gateway_plan_id: string;
  tenures: Tenure[];
  promo_code: string;
  promo_code_status: string;
  excess_usage: ExcessUsage[];
}

export interface CreatedOrder {
  id: number;
  tenure_id: number;
  wallet_id: number;
  payable_currency: number;
  net_paid_amount: number;
  price_expiry: Date;
  status: string;
  recipient_address: string;
}

export interface PlaceOrder {
  tenure_id: number;
  wallet_address: string;
  currency: string;
  chain: string;
  promo_code: string;
}

export const planPriceDetailsState = atom<APIData<PlanPriceDetails>>({
  key: "PRICE_DETAILS",
  default: DefaultAPIDataValues,
});

export const ordersListState = atom<APIData<Array<Order>>>({
  key: "ORDERS_LIST_STATE",
  default: DefaultAPIDataValues,
});

export const planListState = atom<APIData<Array<Plan>>>({
  key: "PLAN_LIST_STATE",
  default: DefaultAPIDataValues,
});

const usePayments = () => {
  const { post, get: getRequest, put } = useRestClient();
  const get = useGetRequest();
  const [planPriceDetails, updatePlanPriceDetails] = useRecoilState(planPriceDetailsState);
  const [planList, updatePlanList] = useRecoilState(planListState);
  const [orders, updateOrders] = useRecoilState(ordersListState);
  const resetPlanPriceDetails = useResetRecoilState(planPriceDetailsState);

  const getPlanPriceDetails = (planId: number, promoCode: string = "") =>
    get<PlanPriceDetails>(
      updatePlanPriceDetails,
      `/users-service/plans/${planId}/price?promoCode=${promoCode}`
    );

  const getOrders = () => get<Array<Order>>(updateOrders, "/users-service/orders");
  const getPlanList = () => get<Array<Plan>>(updatePlanList, "/users-service/public/plans");

  const placeOrder = (
    order: PlaceOrder,
    onComplete: (order: CreatedOrder) => void,
    onError: () => void
  ) => {
    post("/users-service/orders", {
      data: order,
      onSuccess: ({ data }) => onComplete(data),
      onError: (error) => {
        toast(error.response?.data?.message || "Failed to place an order");
        onError();
      },
    });
  };

  const linkWallet = (address: string) => {
    post("/users-service/wallet", { data: { wallet_address: address } });
  };

  const getOrderConfirmation = (orderID: number, onConfirmation: () => void) => {
    getRequest(`/users-service/orders/${orderID}`, {
      onSuccess: ({ data: order }: { data: Order }) => {
        if (order?.status === "fulfilled") {
          onConfirmation();
        }
      },
    });
  };

  const updateTransactionHash = (orderId: number, hash: string) => {
    put(`/users-service/orders/${orderId}?transactionId=${hash}`);
  };

  return {
    linkWallet,
    planPriceDetails,
    resetPlanPriceDetails,
    getPlanPriceDetails,
    placeOrder,
    updateTransactionHash,
    getOrderConfirmation,
    getOrders,
    orders,
    planList,
    getPlanList,
  };
};

export default usePayments;
