import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import Spacing from "../../common/Spacing";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useGetRequest from "../../common/rest-client/get";
import { wrapMiddle } from "../../common/string/format";
export interface Root {
  result: Result;
}

export interface Result {
  block_id: BlockId;
  block: Block;
}

export interface BlockId {
  hash: string;
}

export interface Block {
  header: Header;
  data: Data;
}

export interface Header {
  height: string;
  time: string;
}

export interface Data {
  txs: string[];
}

const stateAtom = atom<APIData<Root>>({
  key: "transactions",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string) => {
    indexerFetcher<Root>(updatePageData, `/cosmos/network/${id}/latestBlocks`);
  };

  return { pageData, getPageData, reset };
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 0.2 },
  {
    field: "tx",
    headerName: "Transaction Hash",
    flex: 1,
  },
];

function Data({ rows }: any) {
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        sx={{
          backgroundColor: "#0d172c",
        }}
        rows={rows}
        columns={columns}
        autoHeight={true}
      />
    </Box>
  );
}

const Transactions = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useCosmosNetworkByID();
  const { pageData, getPageData } = usePageData();

  useEffect(() => {
    getIndexerById(indexerID);
    getPageData(indexerID);
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <APIDataLoader {...pageData}>
        <Typography variant="h3">{indexerDetails.data?.name} </Typography>
        <Spacing spacing={3} />
        <Data
          rows={(pageData.data?.result?.block?.data?.txs || []).map((tx, i) => {
            return {
              id: i,
              tx: wrapMiddle(tx, 64, 0),
            };
          })}
        />
      </APIDataLoader>
    </APIDataLoader>
  );
};

export default Transactions;
