import React, { useEffect } from "react";
import { Container, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Spacing from "../common/Spacing";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toNewIndexer, toParserOverview } from "../common/Routes";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import ParserForm from "./create/NewParserForm";
import UnmarshalLogo from "../../assets/logos/UnmarshalLogo.svg";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import useGetRequest from "../common/rest-client/get";
import useUser from "../user/useUser";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { isEmpty } from "lodash";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { getElapsedTimeMini } from "./details/IndexerOverview";

export const StatusClasses: Map<string, string> = new Map<string, string>([
  ["Requested", "Requested"],
  ["Completed", "Completed"],
  ["UploadedSource", "Completed"],
  ["TriggeredDeploy", "InProgress"],
  ["Pending", "InProgress"],
  ["TriggeredMiddleware", "InProgress"],
  ["BuildRunning", "InProgress"],
  ["Deployed", "Deployed"],
  ["Deploying", "InProgress"],
  ["Failed", "Failed"],
  ["Paused", "Paused"],
  ["ProcessingFailed", "Failed"],
  ["BuildFailed", "Failed"],
  ["Terminated", "Terminated"],
  ["CrashLooping", "Deployed"],
]);

export const EndStatus = [
  "Deployed",
  "UploadedSource",
  "Failed",
  "BuildFailed",
  "ProcessingFailed",
  "Completed",
  "Terminated",
  "CrashLooping",
  "Paused",
];

export type Root = Root2[];

export interface Root2 {
  name: string;
  chainId: string;
  networkType: string;
  status: string;
  topBlock: number;
  numberOfValidators: number;
  createdOn: string;
  deployerBalance: string;
  explorerURL: string;
  nodeUrl: string;
  validatorBalance: string;
  numberOfNodes: number;
}

const stateAtom = atom<APIData<Root>>({
  key: "cosmos-node-list",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string) => {
    indexerFetcher<Root>(updatePageData, `/cosmos/username/${id}/networks`);
  };

  return { pageData, getPageData, reset };
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 2 },
  {
    field: "name",
    headerName: "Node Name",
    flex: 1,
  },
  {
    field: "chainId",
    headerName: "Chain ID",
    flex: 1,
  },
  {
    field: "networkType",
    headerName: "Network Type",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
  },
  {
    field: "topBlock",
    headerName: "Top Block",
    flex: 1,
  },
  {
    field: "createdOn",
    headerName: "Timestamp",
    flex: 1,
    valueGetter: ({ row }: any) => {
      return getElapsedTimeMini(new Date(row.createdOn));
    },
  },
];

function Data({ rows }: any) {
  const navigate = useNavigate();

  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        sx={{
          backgroundColor: "#0d172c",
        }}
        rows={rows || []}
        columns={columns}
        autoHeight={true}
        onRowClick={(data) => navigate(toParserOverview(data.id as any))}
      />
    </Box>
  );
}

const IndexerList = () => {
  const navigate = useNavigate();
  const { userName } = useUser();
  const { pageData, getPageData, reset } = usePageData();

  useEffect(() => {
    if (!userName) {
      return;
    }
    getPageData(userName);
    const interval = setInterval(() => {
      getPageData(userName);
    }, 5000);
    return () => {
      clearInterval(interval);
      reset();
    };
  }, [userName]);

  return (
    <React.Fragment>
      {/* TODO: add background filter*/}
      <Container maxWidth={"xl"}>
        <Stack
          direction={"column"}
          justifyContent={"flex-start"}
          alignItems={"center"}
          maxWidth={"lg"}
          sx={{ mx: "auto" }}
        >
          <Stack
            direction={"row"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Stack direction={"column"} justifyContent={"flex-start"} sx={{ my: 2 }}>
              <Typography variant={"h3"} fontWeight={600}>
                Cosmos Chain
              </Typography>
            </Stack>
            <Stack direction={"row"}>
              <Button
                variant="contained"
                sx={{
                  mr: 1,
                  height: "50px",
                  borderRadius: "4px",
                  "&:hover": { transform: "scale(1.1)" },
                }}
                onClick={() => {
                  Mixpanel.track(MixpanelEvents.NewParser, { source: "Header" });
                  navigate(toNewIndexer());
                }}
              >
                <Typography variant={"h6"} fontWeight={600}>
                  Bootstrap New Chain
                </Typography>
              </Button>
            </Stack>
          </Stack>
          <APIDataLoader {...pageData}>
            {isEmpty(pageData.data) ? (
              <Stack direction="column" justifyContent={"center"} paddingTop="200px">
                <img src={UnmarshalLogo} alt={"Not Found"} />
                <Typography alignItems={"center"} variant={"h6"} fontWeight={600}>
                  You haven’t deployed any Cosmos Chain yet :(
                </Typography>
              </Stack>
            ) : (
              <Data rows={pageData.data} />
            )}
          </APIDataLoader>
          <Spacing spacing={2} />
        </Stack>
      </Container>
    </React.Fragment>
  );
};

const Indexer = () => {
  return (
    <Routes>
      <Route path="/" element={<IndexerList />} />
      <Route path="/new" element={<ParserForm />} />
    </Routes>
  );
};

export default Indexer;
