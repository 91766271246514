import useGetRequest from "../common/rest-client/get";
import DefaultAPIDataValues, { APIData } from "../common/rest-client/types";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import { sakhiConfig } from "../../config";

export interface Dashboard {
  dashboard_id: number;
  created_at: string;
  name: string;
}

export interface DashboardQuestion {
  question: string;
  sql_query: string;
  graph_type: string;
  metadata: string;
}

const DashboardListAtom = atom<APIData<Array<Dashboard>>>({
  key: "DASHBOARD_LIST",
  default: DefaultAPIDataValues,
});

const DashboardQuestionsAtom = atom<APIData<Array<DashboardQuestion>>>({
  key: "DASHBOARD_QUESTIONS",
  default: DefaultAPIDataValues,
});

const useDashboardDetails = () => {
  const [dashboardsList, updateDashboardsList] = useRecoilState(DashboardListAtom);
  const [dashboardQuestions, updateDashboardQuestions] = useRecoilState(DashboardQuestionsAtom);
  const fetcher = useGetRequest();

  const reset = useResetRecoilState(DashboardListAtom);
  const getAllDashboardsByUserId = () => {
    fetcher<Array<Dashboard>>(updateDashboardsList, sakhiConfig.dashboardListEndpoint);
  };

  const resetDashboardQuestions = useResetRecoilState(DashboardQuestionsAtom);
  const getDashboardQuestionsById = (dashboardID: string) => {
    fetcher<Array<DashboardQuestion>>(
      updateDashboardQuestions,
      `/cis/v1/sakhi/dashboard/${dashboardID}/questions`
    );
  };

  return {
    dashboardsList,
    getAllDashboardsByUserId,
    reset,
    dashboardQuestions,
    getDashboardQuestionsById,
    resetDashboardQuestions,
  };
};

export default useDashboardDetails;
