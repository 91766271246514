import React from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import useUser from "../user/useUser";
import Copy from "../common/clipboard/copy";
import { wrapMiddle } from "../common/string/format";
import { Stack, Tooltip } from "@mui/material";

const CopyApiKey = () => {
  const { apiKey } = useUser();

  return (
    <Card sx={{ width: "max-content", border: "1px solid #82EDC6" }}>
      <Stack direction={"row"} padding={2}>
        <Copy content={apiKey} contentType={"API key"} source={"API Key"}>
          <Tooltip title={apiKey} placement={"top-start"}>
            <Typography>API key: {wrapMiddle(apiKey, 5, 5)}</Typography>
          </Tooltip>
        </Copy>
      </Stack>
    </Card>
  );
};

export default CopyApiKey;
