import { atom, useRecoilState, useResetRecoilState } from "recoil";
import useGetRequest from "../../common/rest-client/get";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import usePostRequest from "../../common/rest-client/post";

export interface GraphQLURL {
  db_name: string;
  graphiql_url: string;
  graphql_url: string;
}

export interface GraphQLDetails {
  deploy_status: string;
  uid: string;
  url_arr: GraphQLURL[];
}

const GraphQLAtom = atom<APIData<GraphQLDetails>>({
  key: "GRAPHQL_DETAILS",
  default: DefaultAPIDataValues,
});

interface NewGraphQL {
  instance_uuid: string;
  instance_status: string;
}

const NewGraphQLAtom = atom<APIData<NewGraphQL>>({
  key: "GENERATE_GRAPHQL",
  default: DefaultAPIDataValues,
});

const useGraphQLDetails = () => {
  const [graphQLDetails, updateGraphQLDetails] = useRecoilState(GraphQLAtom);
  const resetState = useResetRecoilState(GraphQLAtom);

  const graphQLDetailsFetcher = useGetRequest();

  const getGraphQLDetails = () => {
    graphQLDetailsFetcher<GraphQLDetails>(updateGraphQLDetails, `/cis/v1/graphql/details`);
  };

  const [newGraphQL, updateNewGraphQL] = useRecoilState(NewGraphQLAtom);

  const newGraphQLGenerator = usePostRequest();

  const addGraphQl = () => {
    newGraphQLGenerator<NewGraphQL>(updateNewGraphQL, "/cis/v1/graphql/generate");
  };
  return { graphQLDetails, getGraphQLDetails, newGraphQL, addGraphQl, resetState };
};

export default useGraphQLDetails;
