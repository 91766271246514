import React, { useMemo } from "react";
import Dashboard, { DashboardProps } from "../dashboard/Dashboard";
import { Container } from "@mui/material";
import RefreshParserDetails from "./RefreshParserDetails";
import { getNavbarItemLink } from "../dashboard/NavBarItems";

interface ContentProps {
  link: (data: any) => string;
}

const Content = (props: React.PropsWithChildren<ContentProps>) => {
  return <Container maxWidth={"xl"}>{props.children}</Container>;
};

const ParserDashboard = (props: React.PropsWithChildren<DashboardProps>) => {
  const link = useMemo(() => {
    return getNavbarItemLink(props.selectedTab, props?.subTab);
  }, [props]);

  return (
    <Dashboard {...props}>
      <RefreshParserDetails navigateTo={link}>
        <Content link={link}>{props.children}</Content>
      </RefreshParserDetails>
    </Dashboard>
  );
};

export default ParserDashboard;
