import { SetterOrUpdater } from "recoil";
import { AxiosRequestConfig } from "axios";
import useRestClient from "./RestClient";
import DefaultAPIDataValues, { APIData } from "./types";

export interface Options extends AxiosRequestConfig {
  parse?: (data: any) => any;
}

const useUnmarshalApiGetRequest = () => {
  const { getFromUnmarshalApi } = useRestClient();
  return <DataType>(
    apiKey: string,
    setterOrUpdater: SetterOrUpdater<APIData<DataType>>,
    path: string,
    options: Options = {}
  ) =>
    getFromUnmarshalApi(apiKey, path, {
      ...options,
      beforeFetch: () =>
        setterOrUpdater((currentValue) => ({
          ...currentValue,
          isLoading: true,
        })),
      onSuccess: ({ data }) =>
        setterOrUpdater({
          ...DefaultAPIDataValues,
          data: options?.parse ? options.parse(data) : data,
          isSuccess: true,
        }),
      onError: (error) =>
        setterOrUpdater({
          ...DefaultAPIDataValues,
          hasError: true,
          isSuccess: false,
          error,
        }),
    });
};

export const useUnmarshalApiPostRequest = () => {
  const { postFromUnmarshalApi } = useRestClient();
  return <DataType>(
    apiKey: string,
    setterOrUpdater: SetterOrUpdater<APIData<DataType>>,
    path: string,
    data: any,
    options: Options = {}
  ) =>
    postFromUnmarshalApi(apiKey, path, {
      data,
      ...options,
      beforeFetch: () =>
        setterOrUpdater((currentValue) => ({
          ...currentValue,
          isLoading: true,
        })),
      onSuccess: ({ data }) =>
        setterOrUpdater({
          ...DefaultAPIDataValues,
          data: options?.parse ? options.parse(data) : data,
          isSuccess: true,
        }),
      onError: (error) =>
        setterOrUpdater({
          ...DefaultAPIDataValues,
          hasError: true,
          isSuccess: false,
          error,
        }),
    });
};

export default useUnmarshalApiGetRequest;
