import React, { Component, ErrorInfo, ReactNode } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

interface Props {
  children: ReactNode;
  ErrorMessage?: ReactNode;
}

interface State {
  hasError: boolean;
}

const DefaultErrorMessage = () => (
  <Box
    width="100vw"
    height="60vh"
    display="flex"
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <Typography color="textSecondary">Something went wrong!!!</Typography>
    <br />
    <Button variant="text" color="primary" onClick={() => window.location.reload()}>
      Click here to reset
    </Button>
  </Box>
);

class ErrorBoundary extends Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { hasError } = this.state;
    const { children, ErrorMessage = <DefaultErrorMessage /> } = this.props;
    if (hasError) {
      return ErrorMessage;
    }

    return children;
  }
}

export default ErrorBoundary;
