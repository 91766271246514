import useGetRequest from "../../common/rest-client/get";
import { APIData, defaultAPIData } from "../../common/rest-client/types";
import { atom, atomFamily, useRecoilState, useResetRecoilState } from "recoil";
import usePostRequest from "../../common/rest-client/post";
import { useParams } from "react-router-dom";
import { doNothing } from "../../common/types";

const tableNamesAtom = atomFamily<APIData<Array<string>>, string>({
  key: "INDEXER_TABLE_NAMES",
  default: () => defaultAPIData([]),
});

export const useTableNames = () => {
  const { id } = useParams();
  const indexerId = id || "";
  const [tableNames, tableNameUpdater] = useRecoilState(tableNamesAtom(indexerId));
  const get = useGetRequest();

  const getTableNames = (onSuccess: (data: Array<string>) => void = doNothing) => {
    if (!tableNames.isSuccess)
      get(tableNameUpdater, `/cis/v1/indexers/${indexerId}/tables`, {
        onSuccess,
      });
  };

  return { tableNames, getTableNames };
};

interface Column {
  column_name: string;
  data_type: string;
}

const tableDataTypesAtom = atomFamily<APIData<Array<Column>>, string>({
  key: "INDEXER_TABLE_DATA_TYPES_ATOM",
  default: () => defaultAPIData([]),
});

export const useTableDataTypes = (indexerId: string, tableName: string) => {
  const key = indexerId + tableName;
  const reset = useResetRecoilState(tableDataTypesAtom(key));
  const [dataTypes, dataTypesUpdater] = useRecoilState(tableDataTypesAtom(key));
  const get = useGetRequest();

  const getDataTypes = () => {
    if (!dataTypes.isSuccess)
      get(dataTypesUpdater, `/cis/v1/indexers/${indexerId}/tables/${tableName}/data-types`);
  };

  return { getDataTypes, dataTypes, reset };
};

export interface Col {
  base_type: string;
  display_name: string;
  effective_type: string;
  field_ref: any[];
  name: string;
  source: string;
}

export interface Data {
  cols: Col[];
  rows: any[][];
}

export interface QueryResponse {
  data: Data;
  error_type?: string;
  error?: string;
}

const customQueryResultsAtom = atom<APIData<QueryResponse>>({
  key: "CUSTOM_QUERY_RESULTS_ATOM",
  default: defaultAPIData({
    data: {
      cols: [],
      rows: [],
    },
  }),
});

export const useCustomQuery = () => {
  const reset = useResetRecoilState(customQueryResultsAtom);
  const [results, resultsStateUpdater] = useRecoilState(customQueryResultsAtom);
  const post = usePostRequest();

  const executeCustomQuery = (indexerId: string, query: string) =>
    post<QueryResponse>(resultsStateUpdater, `/cis/v1/indexers/${indexerId}/execute`, {
      data: {
        query,
      },
    });

  return { results, executeCustomQuery, reset };
};
