import React, { useMemo } from "react";
import Spacing from "../../common/Spacing";
import Button from "@mui/material/Button";
import DataViewer from "./DataViewer";
import { useCustomQuery } from "./DataExplorerClient";
import { useParams } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CopyAll from "@mui/icons-material/CopyAll";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-pgsql";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/ext-language_tools";
import { Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { atom, useRecoilState } from "recoil";
import { copyTextToClipboard } from "../../common/clipboard/copy";
import useUser from "../../user/useUser";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import { toast } from "react-toastify";

export const getCurl = (parserId: string, query: string, apiKey: string): string => {
  const encodedQuery = query.replace(/\n/g, "\\n");
  return `curl --location --request \
  POST 'https://api.unmarshal.com/v1/parser/${parserId}/execute?auth_key=${apiKey}' \
    --header 'Content-Type: application/json' \
    --data-raw '{
    "query": "${encodedQuery}"
}'`;
};

export function Error({ children, ...error }: any) {
  return (
    <Card variant={"outlined"} sx={{ maxWidth: "80vw", overflow: "scroll" }}>
      {children}
      <pre style={{ color: "brown" }}>{JSON.stringify(error, null, 2)}</pre>
    </Card>
  );
}

const Results = () => {
  const { results } = useCustomQuery();

  return useMemo(() => {
    if (results.hasError) {
      return <Error error={results.error?.response?.data} />;
    }
    if (results.isLoading || results.isSuccess) {
      if (results.data.error || results.data.error_type) {
        return <Error error={results.data.error} errorType={results.data.error_type} />;
      }
      return (
        <>
          <DataViewer data={results.data} loading={results.isLoading} />
          <Spacing spacing={12} />
        </>
      );
    }
    return <></>;
  }, [results]);
};

const parserCustomQueryState = atom({
  key: "PARSER_CUSTOM_QUERY_STATE",
  default: "SELECT * FROM YOUR_SCHEMA_NAME.YOUR_TABLE_NAME LIMIT 10",
});

const CustomQuery = () => {
  const [query, queryUpdater] = useRecoilState(parserCustomQueryState);
  const { executeCustomQuery } = useCustomQuery();
  const { id } = useParams();
  const { apiKey } = useUser();
  const indexerID = id || "";

  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("Generated and copied API!");
  };

  return (
    <>
      <Typography fontSize={"20px"}>
        Create custom APIs by simply writing SQL queries and leaving the tricky part to us!
      </Typography>
      <Spacing spacing={1} />
      <AceEditor
        placeholder="Type your query"
        mode="pgsql"
        theme="solarized_dark"
        name="parser_custom_query"
        width="100%"
        value={query}
        onChange={queryUpdater}
        fontSize={16}
        minLines={5}
        maxLines={5}
        showPrintMargin={true}
        showGutter={false}
        highlightActiveLine={true}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <Spacing spacing={1} />
      <Stack direction={"row"} justifyContent={"end"} spacing={1}>
        <Button
          variant={"outlined"}
          disabled={!query}
          onClick={() => executeCustomQuery(indexerID, query)}
          startIcon={<PlayCircleIcon />}
        >
          Execute
        </Button>
        <Button
          variant={"outlined"}
          disabled={!query}
          onClick={() => {
            copyTextToClipboard(getCurl(indexerID, query, apiKey), onCopy, () =>
              toast.error("Failed to copy")
            );
          }}
          endIcon={<CopyAll />}
        >
          Generate API
        </Button>
      </Stack>
      <Spacing spacing={1} />
      <Results />
    </>
  );
};

export default CustomQuery;
