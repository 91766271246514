import React from "react";
import Background from "../../assets/images/background.png";
import { Chip, Grid, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Spacing from "../common/Spacing";
import peopleGroup from "../../assets/images/PeopleGroup.svg";
import useUser from "../user/useUser";
import History from "./History";
import { copyTextToClipboard } from "../common/clipboard/copy";
import { toast } from "react-toastify";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

const ReferralCode = () => {
  const { userDetails } = useUser();
  const referralLink = `${window.location.origin}/auth/sign-up?referralCode=`;
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={3}
      sx={{
        backgroundImage: `url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: `cover`,
        width: "100%",
      }}
    >
      <Spacing spacing={8} />
      <div>
        <Typography textAlign={"center"} variant={"h3"} fontWeight={"bold"}>
          Refer a friend to Unmarshal
        </Typography>
        <Typography textAlign={"center"} variant={"h3"} fontWeight={"bold"}>
          and earn rewards!
        </Typography>
      </div>
      <Paper variant={"outlined"} sx={{ width: "max-content" }}>
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
          <Typography>
            {referralLink}
            <b>{userDetails?.data?.referral_code}</b>
          </Typography>
          <Button
            onClick={() => {
              Mixpanel.track(MixpanelEvents.CopyReferralCode);
              copyTextToClipboard(
                referralLink + userDetails?.data?.referral_code,
                () => toast.success("Copied successfully!!"),
                () => toast.error("Failed to copy referral code!")
              );
            }}
            variant={"contained"}
            size={"small"}
          >
            Copy Link
          </Button>
        </Stack>
      </Paper>
      <Spacing spacing={5} />
    </Stack>
  );
};

const Landing = () => {
  return (
    <main>
      <ReferralCode />
      <Spacing spacing={3} />
      <Typography align={"center"} variant={"h5"} fontWeight={"bold"}>
        Rewards for you and your friends
      </Typography>
      <Typography align={"center"} sx={{ opacity: 0.4 }}>
        Refer unlimited friends to Unmarshal’s console and get rewarded.
      </Typography>
      <Spacing spacing={5} />
      <Grid container justifyContent={"space-around"} spacing={5} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <Stack justifyContent={"center"} alignItems={"center"}>
            <img src={peopleGroup} alt="Group" width={256} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3}>
            <Paper variant={"outlined"} sx={{ maxWidth: 470 }}>
              <Stack
                direction={"row"}
                spacing={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Chip
                  sx={{ background: "#0B0F15", px: 2 }}
                  label={<Typography fontWeight={"bold"}>STEP 1</Typography>}
                  size={"small"}
                  color="primary"
                  variant="outlined"
                />
                <Typography>Refer a friend by sharing the referral link with them.</Typography>
              </Stack>
            </Paper>
            <Paper variant={"outlined"} sx={{ maxWidth: 470 }}>
              <Stack
                direction={"row"}
                spacing={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Chip
                  sx={{ background: "#0B0F15", px: 2 }}
                  label={<Typography fontWeight={"bold"}>STEP 2</Typography>}
                  size={"small"}
                  color="primary"
                  variant="outlined"
                />
                <Typography>
                  Friend uses your code to subscribe to Unmarshal’s console at FLAT 25% off.
                </Typography>
              </Stack>
            </Paper>
            <Paper variant={"outlined"} sx={{ maxWidth: 470 }}>
              <Stack
                direction={"row"}
                spacing={3}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Chip
                  sx={{ background: "#0B0F15", px: 2 }}
                  label={<Typography fontWeight={"bold"}>STEP 3</Typography>}
                  size={"small"}
                  color="primary"
                  variant="outlined"
                />
                <Typography>{"You will earn 25% of your friend's first payment."}</Typography>
              </Stack>
            </Paper>
          </Stack>
        </Grid>
      </Grid>
      <Spacing spacing={10} />
      <History />
    </main>
  );
};

export default Landing;
