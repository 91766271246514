import React from "react";
import TableNames from "./TableNames";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Route, Routes } from "react-router-dom";
import TableDataTypes from "./TableDataTypes";
import { borderColor } from "../../theme/Theme";
import { Alert } from "@mui/material";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import { EndStatus } from "../Indexer";

interface SchemaDetailsProps {
  hideDataTypes?: boolean;
}

const SchemaDetails = ({ hideDataTypes }: SchemaDetailsProps) => {
  return (
    <Grid container sx={{ border: "solid", borderRadius: 1, borderColor: borderColor }}>
      <Grid item xs={4} sx={{ borderRight: "solid", borderColor: borderColor }}>
        <TableNames />
      </Grid>
      <Grid item xs={8} sx={{ borderLeft: "solid", borderColor: borderColor }}>
        {!hideDataTypes ? (
          <TableDataTypes />
        ) : (
          <Alert severity={"info"} sx={{ justifyContent: "center" }}>
            Please select a table
          </Alert>
        )}
      </Grid>
    </Grid>
  );
};

const DataExplorer = () => {
  const { indexerDetails } = useIndexerDetailsByID();

  if (indexerDetails.isSuccess && EndStatus.indexOf(indexerDetails?.data?.deploy_status) < 0) {
    return (
      <Alert severity={"info"} sx={{ justifyContent: "center" }}>
        Loading...
      </Alert>
    );
  }
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <Routes>
        <Route path="/" element={<SchemaDetails hideDataTypes={true} />} />
        <Route path="/table/:tableName/data-types" element={<SchemaDetails />} />
      </Routes>
    </Box>
  );
};

export default DataExplorer;
