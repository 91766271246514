import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useDashboardDetails, { DashboardQuestion } from "./SakhiDashboardClient";
import { Card, CardContent, Container, Grid, Stack, Tooltip } from "@mui/material";
import { APIData, defaultAPIData } from "../common/rest-client/types";
import Spacing from "../common/Spacing";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import { copyTextToClipboard } from "../common/clipboard/copy";
import { toast } from "react-toastify";
import { getCurl } from "../indexer/dataExplorer/CustomQuery";
import { atomFamily, useRecoilState } from "recoil";
import usePostRequest from "../common/rest-client/post";
import useUser from "../user/useUser";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import DataViewer from "../indexer/dataExplorer/DataViewer";
import LineGraph from "./LineGraph";
import { fromDateToFormat } from "../common/date";

const questionsResponseAtom = atomFamily<APIData<any>, string>({
  key: "QUESTIONS_RESPONSE",
  default: () => defaultAPIData([]),
});

export const useQuestionExecutor = (id: number, dashboardID: string) => {
  const [response, responseSetter] = useRecoilState(questionsResponseAtom(`${dashboardID}/${id}`));
  const post = usePostRequest();

  const executeQuery = (query: string) => {
    post(responseSetter, `/cis/v1/indexers/${id}/execute`, { data: { query } });
  };

  return { executeQuery, response };
};

const QuestionOutput = ({
  question,
  id,
  dashboardID,
}: {
  question: DashboardQuestion;
  id: number;
  dashboardID: string;
}) => {
  const { apiKey } = useUser();
  const { executeQuery, response } = useQuestionExecutor(id, dashboardID);
  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: "cURL", source: "cURL" });
    toast.success("cURL copied");
  };

  useEffect(() => {
    executeQuery(question.sql_query);
  }, []);

  return (
    <Grid item sm={6} key={question.question}>
      <Card variant={"outlined"} sx={{ height: "100%" }}>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Typography variant={"h6"} maxWidth={"60%"}>
            {question.question}
          </Typography>
          <Tooltip
            title={"cURL of the request to get data generating this graph"}
            placement={"top"}
          >
            <Button
              variant={"outlined"}
              sx={{ height: "40px" }}
              onClick={() => {
                copyTextToClipboard(getCurl(dashboardID, question.sql_query, apiKey), onCopy, () =>
                  toast.error("Failed to copy")
                );
              }}
            >
              Copy cURL
            </Button>
          </Tooltip>
        </Stack>
        <CardContent
          sx={{
            overflow: "auto",
          }}
        >
          {question.graph_type === "table" ? (
            <DataViewer data={response.data} loading={response.isLoading} pageSize={4} />
          ) : (
            <LineGraph
              isLoading={response.isLoading}
              hasError={response.hasError}
              data={response.data}
              xAxisLabelFormatter={fromDateToFormat}
            />
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

const DashboardCards = ({
  dashboardQuestions,
  dashboardID,
}: {
  dashboardQuestions: APIData<Array<DashboardQuestion>>;
  dashboardID: string;
}) => {
  return (
    <>
      <Spacing spacing={2} />
      <Grid container spacing={4} justifyContent={"space-around"}>
        {dashboardQuestions?.data?.map((question, index) => {
          return (
            <QuestionOutput key={index} question={question} id={index} dashboardID={dashboardID} />
          );
        })}
      </Grid>
      <Spacing spacing={10} />
    </>
  );
};

const DashboardOverview = () => {
  const { dashboardID } = useParams();
  const dashboardId = dashboardID || "";
  const { dashboardQuestions, getDashboardQuestionsById, resetDashboardQuestions } =
    useDashboardDetails();
  useEffect(() => {
    resetDashboardQuestions();
    getDashboardQuestionsById(dashboardId);
  }, []);
  return (
    <Container maxWidth={"xl"}>
      <APIDataLoader {...dashboardQuestions}>
        <DashboardCards
          key={dashboardId}
          dashboardQuestions={dashboardQuestions}
          dashboardID={dashboardId}
        />
      </APIDataLoader>
    </Container>
  );
};

export default DashboardOverview;
