import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { toNewIndexer } from "./Routes";
import { Stack } from "@mui/material";
import parserTeaser from "../../assets/images/parserTeaser.svg";

const ParserTeaser = () => {
  return (
    <Stack justifyContent={"center"} alignItems={"center"} spacing={1}>
      <img src={parserTeaser} alt={"Parser"} />
      <Typography textAlign={"center"} fontSize={"20px"}>
        Deploy a Cosmos Chain to access this feature.
      </Typography>
      <Button
        variant={"contained"}
        sx={{ width: "fit-content" }}
        component={Link}
        to={toNewIndexer()}
      >
        <Typography fontWeight={600}>Deploy Testnet</Typography>
      </Button>
    </Stack>
  );
};

export default ParserTeaser;
