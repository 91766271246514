import React, { useCallback, useState } from "react";
import copyIcon from "../../../assets/icons/copy.svg";
import copied from "../../../assets/icons/done.svg";
import { debounce, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand("copy");
    const msg = successful ? "successful" : "unsuccessful";
    console.log(`Fallback: Copying text command was ${msg}`);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

export function copyTextToClipboard(
  text: string,
  onFulfilled: () => void,
  onRejected: (err: any) => void
) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(onFulfilled, onRejected);
}

interface CopyProps {
  content: any;
  contentType: string;
  source: string;
}

const Copy = ({ children, content, contentType, source }: React.PropsWithChildren<CopyProps>) => {
  const [isCopied, updateCopied] = useState(false);
  const refreshCopied = useCallback(
    debounce(() => updateCopied(false), 2000),
    []
  );
  const onCopy = () => {
    Mixpanel.track(MixpanelEvents.CopyContent, { contentType: contentType, source: source });
    updateCopied(true);
    refreshCopied();
  };
  return (
    <>
      {children}
      <Tooltip title="Copy to clipboard" arrow placement="top">
        <IconButton
          size="small"
          sx={{ width: "max-content" }}
          onClick={() => copyTextToClipboard(content, onCopy, () => alert("Failed to copy"))}
        >
          {isCopied ? (
            <img src={copied} alt="copied" />
          ) : (
            <img src={copyIcon} alt="click to copy" />
          )}
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Copy;
