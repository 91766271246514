export const wrapMiddle = (str: string, left = 6, right = 6): string => {
  if (!str) return "";
  if (str.length <= left + right) return str;
  return `${str.slice(0, left)}...${str.slice(str.length - right)}`;
};

export const shift = (value: number, noOfDecimalPlacesToShift: number) =>
  (value || 0) / 10 ** noOfDecimalPlacesToShift;

export const formatNumberFractionDigits = (
  value: number,
  fractionDigits: number,
  noOfDecimalPlacesToShift = 18
) => {
  if (typeof value === "number" || typeof value === "string") {
    const shiftedValue = shift(value, noOfDecimalPlacesToShift);
    return shiftedValue.toFixed(fractionDigits);
  }
  return "";
};

export function formatNumberToShortForm(num: number) {
  if (num === undefined) {
    return 0;
  }
  if (Math.abs(num) > 999999999999999.99) {
    return `${(num / 1000000000000000.0).toFixed(2)}Q`;
  }
  if (Math.abs(num) > 999999999999.99) {
    return `${(num / 1000000000000.0).toFixed(2)}T`;
  }
  if (Math.abs(num) > 999999999.99) {
    return `${(num / 1000000000.0).toFixed(2)}B`;
  }
  if (Math.abs(num) > 999999.99) {
    return `${(num / 1000000.0).toFixed(2)}M`;
  }
  return Math.abs(num) > 999.99 ? `${(num / 1000.0).toFixed(2)}k` : num.toFixed(2);
}
