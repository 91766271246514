import mixpanel, { Dict, Query } from "mixpanel-browser";

export const MixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN;

export enum MixpanelEvents {
  LandingPage = "Landing Page",
  Home = "Home",
  API = "API",
  SignIn = "Sign In",
  SignUp = "Sign Up",
  VerifyUser = "User Verification",
  LogOut = "Log out",
  NewParser = "Open parser form",
  ViewDetails = "View Details",
  DownloadSourceCode = "Download parser source code",
  CloseParserForm = "Close parser form",
  CloseChildParserForm = "Close child parser form",
  SelectParserType = "Select Parser type",
  ExternalNavigation = "Navigate to external link",
  ParserCreation = "Parser created successfully",
  ParentChildParserCreation = "Parent child parser created successfully",
  FailedParser = "Parser creation failed",
  TerminateParser = "Parser terminated",
  PauseParser = "Parser Paused",
  ClearParserData = "Clear Parser Data",
  ResumeParser = "Parser Resumed",
  FailedParserTermination = "Parser termination failed",
  FailedToPauseParser = "Failed to pause parser",
  FailedToResumeParser = "Failed to resume parser",
  OpenCustomDashboardForm = "Open metabase custom dashboard form",
  CustomDashboardAdded = "Custom metabase dashboard added",
  CloseCustomDashboardForm = "Close metabase custom dashboard form",
  ReSyncMetabaseTables = "Re-sync metabase tables",
  ChoosePlan = "Choose a Plan",
  UpgradedPlan = "Upgraded plan successfully",
  UserDeniedTransaction = "User Denied Transaction",
  TransactionFailed = "Plan payment failed",
  InsufficientFunds = "User had insufficient funds",
  UpgradePlanPage = "To Upgrade Plan page",
  PlanPurchaseAttempt = "Attempt to purchase plan",
  CopyReferralCode = "Copy Referral code",
  UsageStatistics = "Usage Statistics",
  SDK = "SDK Setup",
  DailyStatictics = "Daily statistics",
  MyPlan = "My Plan",
  PaymentHistory = "Payment history",
  FeatureRequest = "Feature Request",
  APIDocumentation = "API Documentation",
  ParserDocumentation = "Parser Documentation",
  CustomerSupport = "Customer Support",
  Parser = "Parser",
  ParserTransactions = "Parser Transactions",
  DataExplorer = "Data Explorer",
  DynamicAPI = "Dynamic API",
  GraphQLPlayground = "GraphQL Playground",
  Query = "Query",
  Analytics = "Analytics",
  CustomAnalytics = "Custom Analytics",
  EventFunctionAnalytics = "EventFunctionAnalytics",
  Notifications = "Notifications",
  Monitor = "Monitor",
  Dashboards = "Dashboards",
  ReferAndEarn = "ReferAndEarn",
  FAQ = "FAQ",
  Academy = "Academy",
  Account = "Account",
  Xscan = "Xscan",
  CopyContent = "Copy Content",
  FilterParser = "Filter Parser",
  NavigateToSocials = "Navigate to socials",
  ApplyPromoCode = "Apply Promo-code",
  Sakhi = "Sakhi",
  SakhiQuestions = "Sakhi Questions",
  SakhiDashboards = "Sakhi Dashboards",
  SakhiDashboardOverview = "Sakhi Dashboard Overview",
  Feedback = "Feedback",
  CloseFeedbackForm = "Close Feedback Form",
  UnlockAPIKey = "Unlock API Key",
  FailedUnlockAPIKey = "Failed to Unlock API Key",
}

export enum MixpanelCustomUserProperties {
  Company = "Company",
  Plan = "Plan",
  Sessions = "Sessions",
}

export const mp_session_config = {
  timeout: 1800000,

  get_Session_id: function () {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
  },

  set_Session_id: function () {
    mixpanel.register({
      "session ID": mp_session_config.get_Session_id(),
    });
  },

  check_Session_id: function () {
    if (!mixpanel.get_property("last event time")) {
      mp_session_config.set_Session_id();
    }

    if (!mixpanel.get_property("session ID")) {
      mp_session_config.set_Session_id();
    }

    if (Date.now() - mixpanel.get_property("last event time") > mp_session_config.timeout) {
      mp_session_config.set_Session_id();
    }
  },
};

const Mixpanel = {
  identify: (id: string) => {
    if (MixpanelToken) mixpanel.identify(id);
  },
  alias: (id: string, original?: string) => {
    if (MixpanelToken) mixpanel.alias(id, original);
  },
  track: (name: string, props?: Dict) => {
    if (MixpanelToken) mixpanel.track(name, props);
  },
  reset: () => {
    if (MixpanelToken) mixpanel.reset();
  },
  track_forms(query: Query, event_name: string, properties?: Dict | (() => void)) {
    if (MixpanelToken) mixpanel.track_forms(query, event_name, properties);
  },
  people: {
    set: (props: Dict) => {
      if (MixpanelToken) mixpanel.people.set(props);
    },
    append: (props: Dict) => {
      if (MixpanelToken) mixpanel.people.append(props);
    },
    set_once: (props: Dict) => {
      if (MixpanelToken) mixpanel.people.set_once(props);
    },
    increment: (props: string | Dict) => {
      if (MixpanelToken) mixpanel.people.increment(props);
    },
  },
};

export default Mixpanel;
