import React, { useEffect } from "react";
import { TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import usePayments from "./Payments";
import { useParams } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";

export const promoCodeAtom = atom<string>({
  key: "PROMO_CODE_INPUT_FILED",
  default: "",
});

const isDefaultPromoCodeIsSetAtom = atom<boolean>({
  key: "IS_DEFAULT_PROMO_CODE_IS_SET",
  default: false,
});

const PromoCode = () => {
  const { planPriceDetails, getPlanPriceDetails } = usePayments();
  const { planId: planIdString } = useParams();
  const [promoCode, setPromoCode] = useRecoilState(promoCodeAtom);
  const [isDefaultPromoCodeIsSet, updateDefaultPromoCodeFlagStatus] = useRecoilState(
    isDefaultPromoCodeIsSetAtom
  );
  const planId = Number(planIdString);
  const defaultPromoCode = planPriceDetails.data?.promo_code;
  const isInvalid = planPriceDetails.data?.promo_code_status === "invalid";

  useEffect(() => {
    if (!isInvalid && !isDefaultPromoCodeIsSet) {
      setPromoCode(defaultPromoCode);
      updateDefaultPromoCodeFlagStatus(true);
    }
  }, [defaultPromoCode]);

  const onSubmit = () => {
    Mixpanel.track(MixpanelEvents.ApplyPromoCode, {
      code: promoCode,
    });
    getPlanPriceDetails(planId, promoCode);
  };

  return (
    <>
      <TextField
        sx={{ maxWidth: 350 }}
        fullWidth
        placeholder={"Have a promotional code?"}
        helperText={isInvalid && !planPriceDetails.isLoading && "Invalid promo code."}
        error={isInvalid}
        value={promoCode}
        onChange={(event) => setPromoCode(event.target.value)}
        InputProps={{
          endAdornment: (
            <LoadingButton
              onClick={onSubmit}
              loading={planPriceDetails.isLoading}
              size={"small"}
              variant={"contained"}
              disabled={promoCode === (defaultPromoCode || "")}
            >
              Apply
            </LoadingButton>
          ),
        }}
      />
    </>
  );
};

export default PromoCode;
