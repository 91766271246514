import React from "react";
import { Route, Routes } from "react-router-dom";
import IndexerDetails from "./details/IndexerDetails";
import Dashboard from "../dashboard/Dashboard";
import { SideNavItems } from "../dashboard/NavBarItems";
import Credentials from "./analytics/Credentials";
import Statistics from "./analytics/Statistics";
import Indexer from "./Indexer";
import GraphqlPlayground from "./graphQL/GraphQLPlayground";
import ContractTransactions from "./transactions/ContractTransactions";
import DataExplorer from "./dataExplorer/DataExplorer";
import ParserDashboard from "./Dashboard";
import Spacing from "../common/Spacing";
import { Stack, Typography } from "@mui/material";
import comingSoon from "../../assets/images/COMING SOON.png";
import CustomQuery from "./dataExplorer/CustomQuery";
import BlockChainConfig from "./cosmos/BlockChainConfig";
import MyNodes from "./cosmos/MyNodes";
import MyWallets from "./cosmos/MyWallets";
import Faucet from "./cosmos/Faucet";
import Transactions from "./cosmos/Transactions";
import NodeLogs from "./cosmos/NodeLogs";

const NotificationsComingSoon = () => {
  return (
    <>
      <Spacing spacing={2} />
      <Stack height={"70vh"} justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontWeight: 700, fontSize: "44px", color: "#4e1dff" }}>
          Notifications
        </Typography>
        <Typography
          sx={{ fontWeight: 600, fontSize: "24px", color: "rgba(244, 194, 122, 1)" }}
        ></Typography>
        <Spacing spacing={1} />
        <img width={"18%"} src={comingSoon} alt={"Coming soon ..."} />
      </Stack>
    </>
  );
};

const MonitorComingSoon = () => {
  return (
    <>
      <Spacing spacing={2} />
      <Stack height={"70vh"} justifyContent={"center"} alignItems={"center"}>
        <Typography sx={{ fontWeight: 700, fontSize: "44px", color: "#4e1dff" }}>
          Monitor
        </Typography>
        <Typography
          sx={{ fontWeight: 600, fontSize: "24px", color: "rgba(244, 194, 122, 1)" }}
        ></Typography>
        <Spacing spacing={1} />
        <img width={"18%"} src={comingSoon} alt={"Coming soon ..."} />
      </Stack>
    </>
  );
};

const ParserRouter = () => {
  return (
    <Routes>
      <Route
        path="/*"
        element={
          <Dashboard selectedTab={SideNavItems.Parser} topSpacing={0}>
            <Indexer />
          </Dashboard>
        }
      />
      <Route
        path="/:id/credentials"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Analytics}>
            <Credentials />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/block-chain-config"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.BlockChainConfig}>
            <BlockChainConfig />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/my-nodes"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.MyNodes}>
            <MyNodes />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/my-wallets"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.MyWallet}>
            <MyWallets />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/faucet"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Faucet}>
            <Faucet />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/details"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Details}>
            <IndexerDetails />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/my-nodes/:nodeId/logs"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.MyNodes}>
            <NodeLogs />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/transactions"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Transactions}>
            <Transactions />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/data-explorer/*"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.DataExplorer}>
            <DataExplorer />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/execute"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.DynamicAPI}>
            <CustomQuery />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/statistics/*"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Analytics}>
            <Statistics />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/graphql"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.GraphQL}>
            <GraphqlPlayground />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/notifications"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Notifications}>
            <NotificationsComingSoon />
          </ParserDashboard>
        }
      />
      <Route
        path="/:id/monitor"
        element={
          <ParserDashboard selectedTab={SideNavItems.Parser} subTab={SideNavItems.Monitor}>
            <MonitorComingSoon />
          </ParserDashboard>
        }
      />
    </Routes>
  );
};

export default ParserRouter;
