import React, { useEffect } from "react";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Tooltip as MuiToolTip,
  Typography,
} from "@mui/material";
import useStatistics, { MonthlyAPIUsage } from "./statistics";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
  PointElement,
  LineElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { sumBy } from "lodash";
import CopyApiKey from "../auth/CopyApiKey";
import Spacing from "../common/Spacing";
import { Link } from "react-router-dom";
import { toDailyUsage } from "../common/Routes";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import useUser from "../user/useUser";
import APIDataLoader from "../common/apiDataLoader/APIDataLoader";
import PropTypes from "prop-types";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import notFound from "../../assets/images/NotFound.png";
import { formatNumberToShortForm } from "../common/string/format";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

function getOptionsAndDataForCallsPerAPI(response: MonthlyAPIUsage[]) {
  const calls = (response || []).map((task) => task?.count);
  const credits = (response || [])?.map((task) => task?.credits_used);
  const labels = (response || [])?.map((task) => task?.task);

  const optionsForCallsPerAPI = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    color: "#ffffff",
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "right" as const,
      },
      title: {
        display: false,
        text: "Calls per API",
      },
    },
    scales: {
      y: {
        ticks: {
          tickColor: "#ffffff",
          color: "white",
        },
      },
      x: {
        ticks: {
          tickColor: "#ffffff",
          color: "white",
        },
      },
    },
  };
  const dataForCallsPerAPI = {
    labels,
    datasets: [
      {
        label: "Calls",
        data: calls,
        borderColor: "rgba(13, 182, 120, 1)",
        backgroundColor: "rgba(13, 182, 120, 0.5)",
      },
      {
        label: "Credits",
        data: credits,
        borderColor: "rgba(244, 194, 122, 1)",
        backgroundColor: "rgba(244, 194, 122, 0.5)",
      },
    ],
  };
  return { optionsForCallsPerAPI, dataForCallsPerAPI };
}

function CustomFooterTotalComponent(props: any) {
  return (
    <Stack
      direction={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ height: "52px", borderTop: "1px solid #ffffff" }}
    >
      <Typography sx={{ padding: "0px 10px", fontWeight: 500, fontSize: "18px" }}>Total</Typography>
      <Typography width={"25%"}>&nbsp;</Typography>
      <Typography
        textAlign={"right"}
        sx={{
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        {props.totalCalls}
      </Typography>
      <Typography
        textAlign={"right"}
        sx={{
          padding: "0px 10px 0px 0px",
          fontWeight: 500,
          fontSize: "18px",
        }}
      >
        {props.totalCredits}
      </Typography>
    </Stack>
  );
}

CustomFooterTotalComponent.propTypes = {
  totalCalls: PropTypes.number,
  totalCredits: PropTypes.number,
};

function UsageStatistics() {
  const { monthlyUsage, getMonthlyUsage, resetMonthlyUsage } = useStatistics();
  const { userDetails } = useUser();

  const totalCalls = sumBy(monthlyUsage.data, (usage) => usage.count);
  const totalCreditsUsed = sumBy(monthlyUsage.data, (usage) => usage.credits_used);
  const credits = userDetails?.data?.tenure?.credits;

  useEffect(() => {
    getMonthlyUsage();
    return resetMonthlyUsage;
  }, []);

  const { optionsForCallsPerAPI, dataForCallsPerAPI } = getOptionsAndDataForCallsPerAPI(
    monthlyUsage?.data
  );
  const columns: GridColDef[] = [
    {
      field: "task",
      headerName: "Task",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            to={toDailyUsage(btoa(params.value))}
            style={{ textDecoration: "none", color: "#ffffff" }}
            onClick={() => {
              Mixpanel.track(MixpanelEvents.DailyStatictics, { task: params.value });
            }}
          >
            {params.value}
          </Link>
        );
      },
    },
    {
      field: "weightage",
      headerName: "Weightage",
      type: "number",
      flex: 1,
    },
    {
      field: "count",
      headerName: "Count",
      type: "number",
      flex: 1,
    },
    {
      field: "credits_used",
      headerName: "Credits Used",
      type: "number",
      flex: 1,
    },
  ];

  return (
    <Container maxWidth={"xl"} component={"main"}>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography fontWeight="bolder" variant="h4">
          Usage Statistics
        </Typography>
        <CopyApiKey />
      </Stack>
      {totalCreditsUsed >= credits && (
        <>
          <Spacing spacing={1} />
          <Alert style={{ width: "max-content" }} severity={"error"}>
            {userDetails?.data?.expiry_date &&
              `Your credits will refresh on ${userDetails?.data?.expiry_date}.`}{" "}
            Upgrade your plan to continue using our services instantly.
          </Alert>
        </>
      )}
      <Spacing spacing={2} />
      <Grid
        container
        sx={{
          border: "1px solid rgba(255, 255, 255, 0.3)",
          p: 2,
          borderRadius: "8px",
        }}
      >
        <Grid item xs={12} md={6} lg={3}>
          <Typography fontWeight={400} sx={{ mb: { xs: 1, lg: 0 } }} align={"center"}>
            &nbsp;Calls:{" "}
            <MuiToolTip placement={"top"} title={totalCalls?.toLocaleString("en-US")}>
              <span>{formatNumberToShortForm(totalCalls)}</span>
            </MuiToolTip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Typography
            fontWeight={400}
            sx={{ mb: { xs: 1, lg: 0 }, borderLeft: "2px solid rgba(255, 255, 255, 0.3)" }}
            align={"center"}
          >
            &nbsp;Credits allotted:{" "}
            <MuiToolTip placement={"top"} title={credits?.toLocaleString("en-US")}>
              <span>{formatNumberToShortForm(credits)}</span>
            </MuiToolTip>
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Typography
            fontWeight={400}
            sx={{ mb: { xs: 1, lg: 0 }, borderLeft: "2px solid rgba(255, 255, 255, 0.3)" }}
            align={"center"}
          >
            &nbsp;Credits used:{" "}
            <MuiToolTip placement={"top"} title={totalCreditsUsed?.toLocaleString("en-US")}>
              <span>{formatNumberToShortForm(totalCreditsUsed)}</span>
            </MuiToolTip>
            &nbsp;
          </Typography>
          <Typography align={"center"}>
            {totalCreditsUsed >= credits && (
              <span
                style={{
                  width: "max-content",
                  background: "#571500",
                  borderRadius: "8px",
                  padding: "2px 12px",
                }}
              >
                Limit Exceeded
              </span>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Typography
            fontWeight={400}
            sx={{ borderLeft: "2px solid rgba(255, 255, 255, 0.3)" }}
            align={"center"}
          >
            &nbsp;Remaining Credits:{" "}
            <MuiToolTip
              placement={"top"}
              title={
                credits - totalCreditsUsed > 0
                  ? (credits - totalCreditsUsed)?.toLocaleString("en-US")
                  : 0
              }
            >
              <span
                style={{
                  color: totalCreditsUsed >= credits - 100 ? "#ce3606" : "#ffffff",
                }}
              >
                {formatNumberToShortForm(
                  credits - totalCreditsUsed > 0 ? credits - totalCreditsUsed : 0
                )}{" "}
              </span>
            </MuiToolTip>
            {credits - totalCreditsUsed > 0 && credits - totalCreditsUsed <= credits * 0.1 && (
              <span
                style={{
                  width: "max-content",
                  background: "#571500",
                  borderRadius: "8px",
                  padding: "2px 12px",
                }}
              >
                Low
              </span>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Spacing spacing={2} />
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12}>
          <Card variant={"outlined"}>
            <CardContent>
              <Typography variant={"h5"} fontWeight={"bold"}>
                Total calls per API
              </Typography>
              <APIDataLoader {...monthlyUsage}>
                {monthlyUsage.data?.length === 0 ? (
                  <Stack alignItems={"center"}>
                    <img src={notFound} alt={"Not found"} height={400} />
                    <Typography align={"center"}>You have not made any API calls :(</Typography>
                    <Spacing spacing={1} />
                    <MuiLink href={"https://docs.unmarshal.io"} target={"_blank"}>
                      <Button variant={"outlined"}>Read Docs</Button>
                    </MuiLink>
                  </Stack>
                ) : (
                  <Box width={"100%"} height={`${monthlyUsage.data?.length * 40 + 40}px`}>
                    <Bar options={optionsForCallsPerAPI} data={dataForCallsPerAPI} />
                  </Box>
                )}
              </APIDataLoader>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Spacing spacing={5} />
      {monthlyUsage?.data?.length > 0 && (
        <DataGrid
          sx={{ fontSize: "16px" }}
          rows={monthlyUsage.data}
          columns={columns}
          components={{ Footer: CustomFooterTotalComponent }}
          componentsProps={{ footer: { totalCalls, totalCredits: totalCreditsUsed } }}
          getRowId={(row) => row.task}
          autoHeight
        />
      )}
      <Spacing spacing={5} />
    </Container>
  );
}

export default UsageStatistics;
