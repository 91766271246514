import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link as RouterLink } from "react-router-dom";
import {
  toParserCredentials,
  toParserEventOrFunctionDashboard,
  toParserStatistics,
} from "../../common/Routes";
import Card from "@mui/material/Card";
import { StatisticsProps } from "./Statistics";
import Mixpanel, { MixpanelEvents } from "../../../MixpanelConfig";
import useIndexerDetailsByID from "../IndexerDetailsClient";
import { IndexerType } from "../IndexerClient";

export enum StatisticsTabsType {
  CONTRACT = "CONTRACT",
  EVENT_OR_FUNCTION = "EVENT_OR_FUNCTION",
  CUSTOM_ANALYTICS = "CUSTOM_ANALYTICS",
}

interface StatisticsTabsProps extends StatisticsProps {
  value: StatisticsTabsType;
}

const StatisticsTabs = ({ value, indexerId, indexerDetails }: StatisticsTabsProps) => {
  return (
    <Card sx={{ width: "fit-content" }}>
      <Tabs value={value}>
        {indexerDetails?.data?.indexer_type !== IndexerType.SourceOnly && (
          <Tab
            label="Contract Analytics"
            value={StatisticsTabsType.CONTRACT}
            component={RouterLink}
            to={toParserStatistics(indexerId)}
            onClick={() => Mixpanel.track(MixpanelEvents.Analytics)}
          />
        )}
        {indexerDetails?.data?.indexer_type !== IndexerType.SourceOnly && (
          <Tab
            label="Event / Function Analytics"
            value={StatisticsTabsType.EVENT_OR_FUNCTION}
            component={RouterLink}
            to={toParserEventOrFunctionDashboard(indexerId)}
            onClick={() => Mixpanel.track(MixpanelEvents.EventFunctionAnalytics)}
          />
        )}
        <Tab
          label="Custom Analytics"
          value={StatisticsTabsType.CUSTOM_ANALYTICS}
          component={RouterLink}
          to={toParserCredentials(indexerId)}
          onClick={() => Mixpanel.track(MixpanelEvents.CustomAnalytics)}
        />
      </Tabs>
    </Card>
  );
};

export default StatisticsTabs;
