import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Stack, Typography } from "@mui/material";
import Spacing from "../../common/Spacing";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useGetRequest from "../../common/rest-client/get";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const stateAtom = atom<APIData<Array<String>>>({
  key: "my-nodes-logs",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string, nodeId: string) => {
    indexerFetcher<Array<String>>(updatePageData, `/cosmos/network/${id}/nodes/${nodeId}/logs`);
  };

  return { pageData, getPageData, reset };
};

const NodeLogs = () => {
  const { id, nodeId } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useCosmosNetworkByID();
  const { pageData, getPageData } = usePageData();

  useEffect(() => {
    getIndexerById(indexerID);
    getPageData(indexerID, nodeId || "");
    const interval = setInterval(() => {
      getPageData(indexerID, nodeId || "");
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <APIDataLoader {...pageData}>
        <Typography variant="h3">
          {indexerDetails.data?.name} - {nodeId}
        </Typography>
        <Spacing spacing={3} />
        <Card sx={{ width: "100%" }}>
          <CardContent>
            <Stack spacing={1} direction="column">
              {(pageData.data || []).map((log, i) => {
                return (
                  <Typography key={i} overflow={"scroll"}>
                    {log}
                  </Typography>
                );
              })}
            </Stack>
          </CardContent>
        </Card>
      </APIDataLoader>
    </APIDataLoader>
  );
};

export default NodeLogs;
