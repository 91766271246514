import React from "react";
import { Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "@aws-amplify/ui-react/internal";
import TransitionBottomUp from "../common/annimations/TransitionBottomUp";
import SignIn from "./SignIn";
import CloseIcon from "../../assets/icons/Close.svg";
import SignUp from "./SignUp";
import ResetPassword from "./ResetPassword";

function AuthRouter() {
  const navigate = useNavigate();
  const auth = useAuth();

  const onClose = () => {
    navigate("/");
  };

  if (auth.user) {
    return <Navigate to="/" />;
  }

  return (
    <Dialog TransitionComponent={TransitionBottomUp} open>
      <DialogContent sx={{ minWidth: 440 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={11}>
            <Routes>
              <Route path="/" element={<Navigate to="sign-in" replace />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/sign-up" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <img src={CloseIcon} alt="close" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default AuthRouter;
