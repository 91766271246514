import React from "react";
import PersonIcon from "@mui/icons-material/Person";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import useUser from "../user/useUser";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Mixpanel, { MixpanelEvents } from "../../MixpanelConfig";
import { appBarItems, LinkType } from "./NavBarItems";
import { useNavigate } from "react-router-dom";

const ProfileMenu = () => {
  const { logout, userName } = useUser();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button color={"primary"} onClick={handleClick}>
        <PersonIcon /> <Typography sx={{ ml: 1 }}>{userName}</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {appBarItems.map((item) => (
          <MenuItem
            onClick={() => {
              Mixpanel.track(item.mixpanelEvent);
              if (item.linkType === LinkType.EXTERNAL) {
                window.open(item.link(), "_blank");
                return;
              }
              navigate(item.link());
            }}
            key={item.label}
          >
            <ListItemIcon>
              <img src={item.icon} alt={""} width={"20px"} />
            </ListItemIcon>
            <Typography>{item.label}</Typography>
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => {
            Mixpanel.track(MixpanelEvents.LogOut);
            logout();
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
