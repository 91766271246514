import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCosmosNetworkByID } from "../IndexerDetailsClient";
import APIDataLoader from "../../common/apiDataLoader/APIDataLoader";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import Spacing from "../../common/Spacing";
import { atom, useRecoilState, useResetRecoilState } from "recoil";
import DefaultAPIDataValues, { APIData } from "../../common/rest-client/types";
import useGetRequest from "../../common/rest-client/get";
import { toNodeLogs } from "../../common/Routes";

export interface Node {
  id: string;
  image: string;
  command: string;
  uptime: string;
  portMappings: string[];
  nodeName: string;
  networkChainID: string;
}

const stateAtom = atom<APIData<Array<Node>>>({
  key: "my-nodes",
  default: DefaultAPIDataValues,
});

export const usePageData = () => {
  const [pageData, updatePageData] = useRecoilState(stateAtom);
  const reset = useResetRecoilState(stateAtom);

  const indexerFetcher = useGetRequest();

  const getPageData = (id: string) => {
    indexerFetcher<Array<Node>>(updatePageData, `/cosmos/network/${id}/nodes`);
  };

  return { pageData, getPageData, reset };
};

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 0.7 },
  {
    field: "nodeName",
    headerName: "Node Name",
    flex: 1,
  },
  {
    field: "nodeType",
    headerName: "Node Type",
    flex: 1,
  },
  {
    field: "networkChainID",
    headerName: "Chain ID",
    flex: 0.5,
  },
  {
    field: "uptime",
    headerName: "Uptime",
    flex: 0.5,
  },
  {
    field: "portMappings",
    headerName: "Port Mappings",
    flex: 2,
  },
];

function Data({ rows }: any) {
  const { id } = useParams();
  const indexerID = id || "";
  const navigate = useNavigate();
  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        sx={{
          backgroundColor: "#0d172c",
        }}
        rows={rows}
        columns={columns}
        autoHeight={true}
        onRowClick={(data) => navigate(toNodeLogs(indexerID, data.id as string))}
      />
    </Box>
  );
}

const MyNodes = () => {
  const { id } = useParams();
  const indexerID = id || "";
  const { indexerDetails, getIndexerById } = useCosmosNetworkByID();
  const { pageData, getPageData, reset } = usePageData();

  console.log("pageData", pageData);

  useEffect(() => {
    getIndexerById(indexerID);
    getPageData(indexerID);
    const interval = setInterval(() => {
      getPageData(indexerID);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [indexerID]);

  return (
    <APIDataLoader {...indexerDetails}>
      <APIDataLoader {...pageData}>
        <Typography variant="h3">{indexerDetails.data?.name}</Typography>
        <Spacing spacing={3} />
        <Data rows={pageData.data || []} />
      </APIDataLoader>
    </APIDataLoader>
  );
};

export default MyNodes;
