import React from "react";
import { Stack, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Auth } from "aws-amplify";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import Mixpanel, { MixpanelEvents, MixpanelCustomUserProperties } from "../../MixpanelConfig";

function VerifyEmail({ userName, password }: { userName: string; password: string }) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const onSubmit = ({ code }: any) =>
    new Promise((resolve, reject) =>
      Auth.confirmSignUp(userName, code)
        .then(() => {
          Mixpanel.track(MixpanelEvents.VerifyUser);
          Auth.signIn(userName, password).then((res) => {
            Mixpanel.identify(res.username);
            Mixpanel.track(MixpanelEvents.SignIn);
            Mixpanel.people.increment(MixpanelCustomUserProperties.Sessions);
            alert("You have unlocked 1 month free trial of the Explore plan!");
          });
        })
        .then(resolve)
        .catch((error) => {
          toast.error(error?.message);
          reject(error);
        })
    );

  function resendOtp() {
    Auth.resendSignUp(userName)
      .then(() => console.log("Send code"))
      .catch((error) => toast.error(error?.message));
    return;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        <Typography fontWeight={500} fontSize="1.5rem">
          Email verification
        </Typography>
        <Stack spacing={1}>
          <Typography fontWeight="500">Username*</Typography>
          <TextField
            {...register("userName")}
            value={userName}
            placeholder="Enter your username"
            disabled
          />
        </Stack>
        <Stack spacing={1}>
          <Typography fontWeight="500">Enter OTP*</Typography>
          <TextField
            {...register("code")}
            type="password"
            placeholder="Please enter OTP sent to your email"
            autoFocus
          />
        </Stack>
        <div>
          <LoadingButton loading={isSubmitting} type="submit">
            Verify email
          </LoadingButton>
        </div>
        <Typography>
          {"Didn’t receive an OTP? "}
          <Button onClick={resendOtp}>Resend OTP</Button>
        </Typography>
      </Stack>
    </form>
  );
}

export default VerifyEmail;
